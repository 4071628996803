/*********************************************************
        Block Ctas Alpha
**********************************************************/
.block-ctas-alpha{
  @include grid-column-row();
  position: relative;
  padding: 0 15px;
  &__wrapper{
    position: relative;
    display: table;
    top: -67.5px;
  }
  .link__regular:hover{
    text-decoration: none;
  }
  &__bg-image{
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  &__item{
    @include inline-block(top);
    @include grid-col(6);
    margin-bottom: 30px;
    text-align: center;
    &:nth-of-type(odd) {
      padding-left: 0;
    }
    &:nth-of-type(even) {
      padding-right: 0;
    }
  }
  &__infos {
    display: inline-block;
  }
  &__image{
    width: 135px;
    margin: 0 auto;
    border-radius: 50%;
  }
  &__title{
    @include font-size(14px);
    font-weight: 900;
    line-height: 18px;
    margin-bottom: 10px;
  }
  &__subtitle{
    display: block;
    @include font-size(28px);
    line-height: 30px;
  }
  &__separator {
    position: relative;
    @include inline-block();
    width: 100%;
    padding: 0 20%;
    margin: 20px 0 25px;
    @include font-size(25px);
    text-align: center;
    &:before, &:after {
      content: '';
      display: inline-block;
      @include vertical-center();
      width: 20%;
      height: 2px;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
}

/***********************************
  "Block Ctas - Alpha"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/
@include breakpoint(medium) {
  .block-ctas-alpha{
    height: 480px;
    text-align: center;
    &__item {
      @include grid-column(3);
      float: none !important;
      margin-bottom: 40px;
    }
    &__separator {
      padding: 0 30%;
      &:before, &:after {
        width: 30%;
      }
    }
  }
}

@include breakpoint(large) {
  .block-ctas-alpha {
    height: 600px;
    &__wrapper{
      top: -90px;
    }
    &__item {
      margin-bottom: 60px;
    }
    &__image {
      width: 180px;
    }
    &__title {
      @include font-size(18px);
      line-height: 22px;
    }
    &__separator {
      @include font-size(35px);
      margin-bottom: 40px;
    }
  }
}

/********************************************************************
        Block Ctas - Beta
*********************************************************************/
.block-ctas-beta{
  &__item{
    display: block;
    margin: rem-calc(0 20px 20px);
    position: relative;
  }
  &__image{
    width: 100%;
  }
  &__infos{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: rem-calc(16px);
    text-align: center;
  }
  &__title{
    @include font-size(28px);
    @include line-height(30px);
    padding-bottom: rem-calc(8px);
    margin: rem-calc(0 0 6px);
    position: relative;
    &:after{
      content: "";
      display: block;
      width: 130px;
      height: 1px;
      position: absolute;
      left: 50%;
      bottom: 0;
      @include transform(translate(-50%, 0));
    }
  }
  &__cta{
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }
}

/***********************************
  "Block Ctas - Beta"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/

@include breakpoint(medium){
  .block-ctas-beta{
    @include grid-row();
    margin: 0 10px;
    &__item{
      @include grid-column(6);
      margin: rem-calc(0 0 20px);
    }
  }
}

@include breakpoint(large){
  .block-ctas-beta{
    @include grid-column(5);
    margin: 0;
    &__item{
      display: block;
      width: 100%;
      float: none;
    }
  }
}

/********************************************************************
        Block Ctas - Charlie
*********************************************************************/
.block-ctas-charlie{
  clear: both;
  overflow: hidden;
  &__wrapper{
    @include clearfix();
  }
  &__image{
    position: absolute;
    left: 20px;
    top: 50%;
    @include transform(translate(0, -50%));
    max-width: 65px;
  }
  &__infos{
    padding-left: rem-calc(110px);
    position: relative;
  }
  &__title{
    @include font-size(32px);
    @include line-height(34px);
  }
  &__subtitle{
    display: block;
  }
  &__cta{
    @include font-size(14px);
    @include line-height(16px);
  }
  &__item{
    position: relative;
    text-align: left;
    width: 100%;
    @include padding(15px 20px);
    overflow: hidden;
    &:nth-child(even){
      border-top: 3px solid $light-gray;
      text-align: right;
      &:before {
        content: '';
        position: absolute;
        top: -75px;
        right: -410px;
        transition: right 0.3s;
        border: 275px solid;
        transition-timing-function: ease-out;
        transform: rotate(30deg);
      }
      &:hover{
        &:before{
          right: -410px;
          transition:right 0.3s;
        }
      }
      .block-ctas-charlie__image{
        left: auto;
        right: 20px;
      }
      .block-ctas-charlie__infos{
        padding-left: 0;
        padding-right: rem-calc(110px);
      }
    }
    &:nth-child(odd){
      clear: both;
      &:before {
        content: '';
        position: absolute;
        top: -185px;
        left: -510px;
        transition: left 0.3s;
        border: 275px solid;
        transition-timing-function: ease-out;
        transform: rotate(30deg);
      }
      &:hover{
        &:before{
          left: -510px;
          transition:left 0.3s;
        }
      }
    }
  }
}

/***********************************
  "Block Ctas - Charlie"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/
@include breakpoint(medium){
  .block-ctas-charlie{
    @include padding(15px 20px 0);
  }
  .block-ctas-charlie__image{
    left: 0;
    max-width: 75px;
  }
  .block-ctas-charlie__infos{
    padding-left: rem-calc(120px);
  }
  .block-ctas-charlie__item:nth-child(even) .block-ctas-charlie__infos{
    padding-left: 0;
    padding-right: rem-calc(120px);
  }
  .block-ctas-charlie__item{
    float: left;
    width: 50%;
    margin: rem-calc(0 0 15px);
    padding: 0;
    overflow: visible;
    &:nth-child(even){
      border: 0;
      float: right;
      &:before {
        top: -75px;
        right: -415px;
      }
      &:hover{
        &:before{
          right: -120px;
        }
      }
      .block-ctas-charlie__image{
        left: auto;
        right: 5px;
      }
    }
    &:nth-child(odd){
      border-right: 1px solid $light-gray;
      &:before {
        top: -75px;
        left: -415px;
        transform: rotate(-30deg);
      }
      &:hover{
        &:before{
          left: -120px;
        }
      }
    }
  }
}

@include breakpoint(large){
  .block-ctas-charlie__infos{
    padding-left: rem-calc(150px);
  }
  .block-ctas-charlie__image{
    max-width: none;
  }
  .block-ctas-charlie__item:nth-child(even) .block-ctas-charlie__infos{
    padding-left: 0;
    padding-right: rem-calc(150px);
  }
  .block-ctas-charlie__item{
    &:nth-child(even){
      &:before {
        right: -390px;
      }
    }
    &:nth-child(odd){
      &:before {
        left: -390px;
      }
    }
  }
}

/*********************************************************
        Block Ctas Delta
**********************************************************/
.block-ctas-delta{
  @include grid-column-row();
  @include clearfix();
  position: relative;
  padding-top: rem-calc(10px);
  padding-bottom: rem-calc(10px);
  max-width: 270px;
  &__wrapper{
    @include inline-block();
    position: relative;
  }
  &__item{
    @include grid-column(12);
    margin-bottom: 15px;
  }
  &__cta{
    display: block;
    position: relative;
    &:hover .block-ctas-delta__image-hover{
      opacity: 1;
    }
    &:after{
      content: "";
      display: block;
      width: 0;
      border-left: 5px solid;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      position: absolute;
      right: 10px;
      top: 50%;
      @include transform(translate(0, -50%));
      @include transition(all 0.3s ease);
    }
  }
  &__image{
    width: 100%;
  }
  &__image-hover{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    opacity: 0;
    @include transition(all 0.3s ease);
  }
  &__infos{
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    padding-left: rem-calc(55px);
    @include transform(translate(0, -50%));
  }
  &__title{
    @include font-size(13px);
    line-height: 15px;
  }
  &__subtitle{
    display: block;
    @include font-size(18px);
    line-height: 20px;
  }
}

/***********************************
  "Block Ctas - Delta"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/
@include breakpoint(medium){
  .block-ctas-delta{
    max-width: none;
    &__item{
      @include grid-column(3);
      margin-bottom: 0;
    }
    &__infos{
      padding-left: rem-calc(40px);
    }
  }
}

@include breakpoint(large){
  .block-ctas-delta{
    &__infos{
      padding-left: rem-calc(65px);
    }
    &__title{
      @include font-size(15px);
      line-height: 17px;
    }
    &__subtitle{
      @include font-size(24px);
      line-height: 27px;
    }
  }
}
