.about{
  &__content-wrapper{
    @include grid-row();
  }
  &__content-text{
    @include grid-col(12,60);
    padding-top: 50px;
    padding-bottom: 70px;
  }
  &__articles-wrapper{
    min-height: 370px;
    @include grid-row();
  }
  &__articles-items{
    @include grid-col(12,60);
  }
  &__articles-item{
    float: left;
    margin-right: 20px;
    &:last-of-type{
      margin-right: 0px;
    }
    :hover{
      .about__article-description{
        opacity: 1;
      }
    }
  }
  &__article-description{
    position: relative;
    text-align: center;
    font-family: $title-font-family;
    font-weight: 700;
    @include transform(translateY(-220%));
    opacity: 0;
  }
  &__article-title{
    text-transform: uppercase;
  }
  &__video{
    position:relative;
    @include inline-block;
    margin-bottom:25px;
  }
  &__video-wrapper{
    position:relative;
  }

  &__video-full-container{
    @include grid-layout(2,'.about__video', 20px);
  }
}
