/**************************************************
        Structure General
**************************************************/

//[data-slider-content]{
//  width:100%;
//  overflow: hidden;
//
//  [data-slider-mask]{
//    position:relative;
//    display: table;
//    border-collapse: collapse;
//  }
//  [data-slider-content]{
//    @include padding(20px 0);
//  }
//  [data-slider-item]{
//    position: relative;
//    display: table-cell;
//    height:100%;
//    vertical-align: top;
//    @include box-sizing();
//    float: left;
//  }
//}

[data-slider-content]{
  width: 100%;

  .bx-viewport{
    height: auto!important;
  }

  [data-slider-mask]{
    position:relative;
    display: table;
  }

  [data-slider-item]{
    position: relative;
    display: table-cell;
    vertical-align: top;
    height: 100%;
    min-width: 250px!important;
    @include box-sizing(border-box);
  }
}

/**************************************************
      Compare Vehicles Block
**************************************************/
.compare-vehicles {

  .inventory-list-layout-wrapper{
    @include grid-column-row(0);
  }
  &__close {
    @include inline-block;
    float: right;
    width: 24px;
    height: 24px;
    line-height: 24px;
    margin: rem-calc(0 10px 10px);
    background: map_get($foundation-palette,gray);
    color: map_get($foundation-palette,white);
    @include font-size(13px);
    font-family: $title-font-family;
    font-weight: 700;
    text-align: center;
    @include border-radius(100%);
    &:hover{
      cursor: pointer;
      opacity: 0.5;
    }
  }
  &__tabs{
    padding-top: 0;
    margin: 0 0 20px;
  }
  &__infos-wrapper{
    padding: rem-calc(0 10px);
    li{
      @include font-size(14px);
      list-style-type: disc;
    }
  }
  &__slider-controls{
    margin: rem-calc(20px 0);
    text-align: left;
  }
  .widget-accordion__title{
    text-transform: uppercase;
  }
  .widget-accordion__content{
    padding-bottom: rem-calc(10px);
  }
  .catalog-block__image .vehicle__rebate{
    position: absolute;
    left: 0;
    top: 0;
    img{
      display: inline-block;
      float: left;
      max-width: 30px;
    }
    .showroom-rebate__content{
      display: inline-block;
      float: left;
      margin-left: 5px;
    }
  }
}

/**************************************************
      Add Vehicles to compare - (last Item)
**************************************************/
.compare-vehicles{
  &__add-more{
    @include padding(40px 0);
    margin-top: 20px;
  }
  &__add-more-picto{
    display: block;
    margin-bottom: rem-calc(20px);
    @include font-size(45px);
  }
}