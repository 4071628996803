/*********************
    Page header
*********************/
.page-static-header {
  .page-wrapper {
    padding: 50px 10px;
    .content-block {
      &__text {
        p {
          line-height: 18px;
        }
      }
    }
  }
  .title__primary {
    margin-bottom: 25px;
    @include font-size(35px);
    @include line-height(37px);
    text-transform: uppercase;
  }

  .title__secondary {
    display: block;
  }

  /*********************
  Header Content blocks
  *********************/
  .content-block{
    @include grid-column(12, 0);
    float: none;
    padding-bottom:rem-calc(0);
    position:relative;
    &--no-padding{
      padding-bottom: 0;
    }
    &__text-bottom {
      font-weight: 700;
      margin-bottom: 10px !important;
    }
    &__side-img{
      @include grid-column(4);
      position: absolute;
      right:0;
      bottom:0;
      float: none !important;
      &--align-top{
        top: 0;
        bottom: auto;
      }
      &--align-bottom{
        bottom: 0;
      }
      &--align-center{
        top: 50%;
        bottom: auto;
        @include transform(translate(0, -50%));
      }
    }
  }
}

.page-static-header--why-choose {
  background-position-x: right;
  .page-wrapper {
    padding-bottom: 35px;
  }

  @keyframes slideInFromRight {
    from {
      margin-left: 50px;
      opacity: 0;
    }
    to {
      margin-left: 0;
      opacity: 1;
    }
  }

  .content-block {
    &__image {
      position: absolute;
      right: 0;
      left: auto;
      height: 75px;
      width: 300px;
    }
    &__image-item {
      position: absolute;
      max-height: 75px;
      opacity: 0;
      animation-fill-mode: forwards;
      &:nth-child(1) {
        bottom: -25px;
        width: 100%;
        animation-name: fadeIn;
        animation-duration: 1s;
        animation-delay: 0.8s;
      }
      &:nth-child(2) {
        bottom: 5px;
        left: 0;
        transform: scale(0.6);
        transform-origin: left;
        animation-name: slideInFromRight;
        animation-duration: 0.8s;
        animation-delay: 1s;
      }
      &:nth-child(3) {
        bottom: 2.5px;
        left: 45%;
        transform: scale(0.8) translateX(-50%);
        transform-origin: left;
        animation-name: slideInFromRight;
        animation-duration: 0.8s;
        animation-delay: 0.5s;
      }
      &:nth-child(4) {
        left: 100%;
        transform: scale(1) translateX(-100%);
        animation-name: slideInFromRight;
        animation-duration: 0.8s;
      }
    }
  }
}

.page-static-main-content{
  background-size: cover;
  @include gradient-over-bodyshop-instances(11, #f3f3f3, block__instances-unit--bodyshop);
  .block {
    &__instances-line {
      position: absolute;
      left: 52.5px;
      height: calc(100% - 265px);
      margin-top: 90px;
      border-width: 2.5px;
      border-style: solid;
      z-index: 2;
      &:before, &:after {
        content: "";
        position: absolute;
        display: block;
        border-width: 12px;
        border-style: solid;
        border-color: inherit;
        border-radius: 50%;
      }
      &:before {
        top: -24px;
        left: -12px;
      }
      &:after {
        bottom: -24px;
        left: -12px;
      }
    }
    &__instances-top {
      margin-bottom: 30px;
      &-image, &-title {
        display: inline-block;
        vertical-align: middle;
      }
      &-image {
        width: 95px;
      }
      &-title {
        width: calc(100% - 95px);
        padding-left: 10px;
        margin-bottom: 0;
        font-family: $title-font-family;
        font-weight: 700;
      }
    }
    &__instances-unit {
      padding-top: 50px;
      padding-bottom: 50px;
      &-wrapper {
        margin: 0 auto;
      }
      &-image {
        position: relative;
        display: table-cell;
        vertical-align: top;
        width: 20%;
        border-radius: 50%;
      }
      &-separator {
        position: relative;
        width: 25%;
        height: 2px;
        margin-bottom: 15px;
      }
      &-content {
        display: table-cell;
        vertical-align: top;
        padding-left: 30px;
        @include font-size(12px);
        line-height: 14px;
        .title__subtitle {
          margin: 0;
          @include font-size(14px);
          line-height: 16px;
          font-weight: 900;
          text-transform: uppercase;
          margin-bottom: 5px;
        }
      }
    }
    &__instances-unit:nth-child(even) {
      .block__instances-unit-image {
        float: right;
      }
      .block__instances-unit-content {
        text-align: right;
        padding-left: 0;
        padding-right: 30px;
      }
      .block__instances-unit-separator {
        left: 75%;
      }
    }
    &__instances-unit--left:nth-child(even) {
      .block__instances-unit-image {
        float: none;
      }
      .block__instances-unit-content {
        text-align: left;
        padding-left: 30px;
        padding-right: 0;
      }
      .block__instances-unit-separator {
        left: 0;
      }
    }
    &__instances-unit--bodyshop{
      position: relative;
      width: calc(100% - 20px);
      padding: 10px;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      &:not(:last-child) {
        margin-bottom: 12px;
      }
      &:before, &:after {
        content:' ';
        position: absolute;
        display: block;
        height: 50%;
        width: 20px;
        right: -20px;
      }
      &:before {
        top: 0;
      }
      &:after {
        bottom: 0;
      }
      .block__instances-unit-image {
        display: inline-block;
        width: 70px;
        height: 70px;
        border-width: 5px;
        border-style: solid;
        border-radius: 50%;
        z-index: 3;
        img {
          @include absolute-center;
          height: auto;
          width: 30px;
          padding: 15px;
          box-sizing: content-box;
        }
      }
      .block__instances-unit-content {
        display: inline-block;
        width: calc(100% - 75px);
      }
      .block__instances-unit-counter {
        position: absolute;
        top: -5px;
        right: -20px;
        width: 40px;
        height: 40px;
        border-width: 5px;
        border-style: solid;
        border-radius: 50%;
        @include font-size(18px);
        font-weight: 700;
        text-align: center;
        line-height: 30px;
        z-index: 4;
      }
    }
    &__instances-bottom {
      margin-top: 40px;
      &-image {
        width: 95px;
      }
    }

    &__instances-secondary {
      padding: 50px 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      .title__secondary {
        max-width: $global-width;
        margin: 0 auto 50px;
        padding: 0 20px;
        text-transform: uppercase;
      }
    }

    &__cta {
      text-align: center;
      .title__secondary {
        margin-bottom: 20px;
        @include font-size(18px);
        text-transform: uppercase;
      }
    }
  }
}

.page-static-main-content--why-choose {
  padding-top: 50px;
  .block__instances-secondary {
    background-position: left;
  }
}

/*********************
   Block form hours
*********************/
.block-form-hours{
  &__form-form{
    @include grid-column(12);
    float: none;
    .form-bottom{
      @include box-shadow(none);
      &__title{
        text-align: center;
      }
      &__content{
        padding: 20px 0;
      }
    }
    .submit-wrapper{
      text-align: center;
      div{
        float: none;
        @include padding(15px 80px);
      }
    }
    .form__textarea-container{
      display: inherit;
    }
    .form-field__label{
      @include grid-column();

      &-subscription{
        @include grid-column(12);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin-bottom: 12px;
        .checkbox-label{
          color: $white;
        }
      }
    }
  }

  .form-container__link-policy{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    &-text{
      padding-left:5px;
      @include font-size(10px);
    }
  }

  .form-footer-2columns .form-container__fieldset-required-content{
    @include grid-column(12);
    &:first-child{
      @include display(none);
    }
  }
  .form-footer-2columns .form-field__label-textarea-field{
    height: 115px;
  }
}

/**********************
  Anchor bottom form
**********************/
#bottom-page-form{
  position: absolute;
  margin-top: -150px;
}

.wysiwyg-rates{
  table {}

  td {
    @include grid-column(3);
    padding: rem-calc(10px);
    &:last-child{
      @include grid-col-end();
    }
  }
}

.page-car-rental{
  .bottom-page-form{
    .form-container__content{
      @include columns-divider(2, 0px, transparent, solid, 0px);
      .form-container__fieldset-required{
        margin-top: 0px !important;
        .form-container__fieldset-required-content{
          width: 100% !important;
        }
      }
    }
  }
}

.page-maintenance{
  .page-static-header{
    .page-wrapper,
    .content-block{
      padding-bottom: 0;
    }
  }
  .page-maintenance-iframe .page-wrapper{
    padding-top: 0;
    iframe{
      height: 550px;
    }
  }
}
/***********************************************************
           Breakpoints :
            - small: 0,               //Mobile
            - medium: 768px,          //Tablet
            - large: 1024px,          //Desktop
            - xlarge: 1200px,
            - xxlarge: 1920px,
************************************************************/

@include breakpoint(medium) {
  .page-static-header {
    .page-wrapper {
      .content-block {
        &__text {
          p {
            line-height: 20px;
          }
        }
      }
    }
    .title__primary {
      @include font-size(45px);
      @include line-height(47px);
    }
  }

  .page-static-header--why-choose {
    .content-block {
      &__text {
        margin-bottom: 70px;
      }
      &__image {
        bottom: -110px;
        height: 160px;
        width: 560px;
      }
      &__image-item {
        max-height: 160px;
        &:nth-child(1) {
          bottom: -35px;
        }
        &:nth-child(2) {
          bottom: 40px;
        }
        &:nth-child(3) {
          left: 35%;
          bottom: 20px;
        }
        &:nth-child(4) {

        }
      }
    }
  }

  .page-static-main-content {
    .block {
      &__instances-line {
        left: 72.5px;
        height: calc(100% - 315px);
        margin-top: 115px;
      }
      &__instances-top {
        &-image {
          width: 135px;
          margin-right: 15px;
        }
        &-title {
          width: calc(100% - 150px);
          padding-left: 0;
        }
      }
      &__instances-unit {
        &-wrapper {
          max-width: calc(#{$global-width} / 12*10);
        }
        &-content {
          @include font-size(14px);
          line-height: 16px;
          .title__subtitle {
            @include font-size(18px);
          }
        }
      }
      &__instances-unit--bodyshop {
        padding: 20px;
        .block__instances-unit-image {
          width: 90px;
          height: 90px;
          img {
            width: 40px;
            padding: 20px;
          }
        }
        .block__instances-unit-content {
          width: calc(100% - 95px);
        }
      }
      &__instances-bottom-image {
        width: 135px;
      }
    }

    &__instances-secondary {
      padding: 70px 0;
    }

    &__cta {
      .title__secondary {
        @include font-size(24px);
        text-transform: none;
      }
    }
  }

  .page-static-main-content--why-choose {
    padding-top: 120px;
    .block__instances-secondary {
      background-position: center;
    }
  }

  .block-form-hours__form-form{
    .form-field__label {
      @include grid-column(6);
    }
  }
}

@include breakpoint(large) {
  .page-static-main-content {
    .block {
      &__instances-top__title {
        max-width: 350px;
      }
      &__instances-unit {
        &-image {
          width: 15%;
        }
      }
      &__instances-unit--bodyshop {
        .block__instances-unit-content {
          padding-left: 50px;
        }
      }
      &__instances-unit--bodyshop:nth-child(even) {
        .block__instances-unit-content {
          padding-left: 50px;
        }
      }
    }
  }

  .block-form-hours{
    &__form-form{
      @include grid-column(12);
      float:none;
      .submit-wrapper{
        div{
          float:none;
        }
      }
    }
  }
}

@include breakpoint(xlarge) {
  .page-static-main-content {
    .block__instances-secondary {
      .title__secondary {
        padding: 0;
      }
    }
  }
}










