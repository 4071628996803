/***********************************************
      Footer Top Version Mobile
************************************************/
.footer{
  @include grid-column(12,0);
  .page-wrapper{
    @include padding(30px 10px 0);
    min-width: 0;
  }
  &__row{
    @include grid-row();
  }
  &__column{
    @include grid-column(12);
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
  &__columns {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    &:last-child {
      .footer__column:last-child {
        border: 0;
      }
    }
  }
  &__column-items{
    margin-bottom: rem-calc(25px);
  }
  &__list{
    line-height: 0;
    list-style: none;
    margin: 0;
  }
  &__list-element{

  }
  &__list-element-anchor {
    &:hover{
      text-decoration: underline;
    }
  }
  &__title {
    display: block;
    margin-bottom: 20px;
    @include font-size(16px);
    line-height: 18px;
  }
  &__about-text {
    @include font-size(14px);
    display: inline;
  }
  &__about-link {
    @include font-size(14px);
    font-style: italic;
  }
  &__address {
    @include font-size(14px);
    &-title {
      @include font-size(14px);
      font-weight: 700;
    }
  }
  &__phone {
    @include font-size(14px);
  }
  &__email {
    @include font-size(14px);
    font-weight: 700;
    margin-bottom: 20px;
  }
  &__social{
    padding: 40px 0;
  }
  .snippet-address{
    &__address, &__postal-code {
      display: block;
    }
    &__link{
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}

.snippet-phone__department-wrapper--footer {
  margin-bottom: 20px;
}

.snippet-phone__department-name--footer {
  display: block;
  font-weight: 700;
}

/***********************************************
      Footer Bottom Version Mobile
************************************************/
.footer-bottom{
  .page-wrapper{
    @include padding(20px 0);
  }
  &__content{
    @include grid-column-row();
    @include font-size(10px);
    line-height: 12px;
  }
  &__copyright, &__anchor{
    display: inline-block;
    padding: 0 10px;
  }
  &__anchor:hover{
    text-decoration: underline;
  }
}

/***********************************************************
           Breakpoints :
            - small: 0,               //Mobile
            - medium: 640px,
            - tablet: 768px           //Tablet
            - large: 1024px,          //Desktop
            - xlarge: 1200px,
            - xxlarge: 1920px,
************************************************************/

@include breakpoint(medium) {
  .footer {
    &__column {
      @include grid-column(6);
      margin-bottom: 0;
      &:not(last-child) {
        border: 0;
      }
    }
  }
}

@include breakpoint(large) {
  .footer__columns {
    @include grid-column(6,0);
  }
}
