.position-relative{
  position:relative;
}

.text-uppercase{
  text-transform: uppercase;
}

.text-lowercase{
  text-transform: lowercase;
}

.text-capitalize{
  text-transform: capitalize;
}

.text-bold{
  font-family: $title-font-family;
  font-weight: 700;
}

.text-align-right{
  text-align: right;
}

.text-align-left{
  text-align: left;
}

.text-align-center{
  text-align: center;
}

.inline-block{
  display:inline-block;
}

.display-none{
  display: none;
}

.float-right{
  float: right;
}

.float-left{
  float: left;
}

.css-triangle-left{
  position:absolute;
  top:22px;
  left:-16px;
  @include inline-block;
  @include css-triangle(16px,$medium-gray,left);
  &--inset{
    @include css-triangle(15px,$white,left);
    position:absolute;
    top:-15px;
    left:1px;
  }
}
.clearfix{
  @include clearfix;
}


.visibility-hidden{
  visibility: hidden;
}

.link {
  &__simple-text {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.margin-0 {
  margin-top: 0rem !important;
  margin-right: 0rem !important;
  margin-bottom: 0rem !important;
  margin-left: 0rem !important;
}

.padding-0 {
  padding-top: 0rem !important;
  padding-right: 0rem !important;
  padding-bottom: 0rem !important;
  padding-left: 0rem !important;
}


/***********************************
        Avoid Clicks
***********************************/
.avoid-clicks {
  pointer-events: none;
  cursor: not-allowed;
}

.cta-disabled{
  opacity:0.3;
  cursor: not-allowed;
  &:hover,&:focus,&:active{
    opacity:0.3;

  }
}

* {
  outline: none;
}