.js-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: $reveal-overlay-background;
  width: 100%!important;
  height: 100%;
  z-index: 9999;
}

.js-overlay-content {
  position: fixed;
  top: 0;
  right: -320px;
  max-width: 320px;
  width: 100%;
  height: 100%;
  z-index: 10;
  overflow-y: scroll;
  transition: 0.5s ease-in-out;

  &.is-active {
    @include transform(translateX(-320px));
  }
}

.reveal {
  overflow: visible;
  background-color: rgba($black,.8)!important;
  &.listing-employee {
    &__reveal {
      .close-button{
        right: 3px;
        top: 3px;
      }
    }
  }
  &.listing-promotion {
    &__reveal {
      .widget-ninjabox__sidebar-right {
        padding: 20px;
      }
    }
  }
  &.nopadding {
    padding: 0;
    border: 0;
  }
  &.full {
    .reveal-body {
      position: relative;
      top: 50%;
      left: 50%;
      @include transform(translate(-50% , -50%));
    }
  }
  &.full.generic__reveal .reveal-body {
    height: 100%;
    iframe{
      position: relative;
      top: 50%;
      left: 50%;
      @include transform(translate(-50% , -50%));
    }
  }
  .close-button {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;
    &:hover {
      color: $white;
    }
    span {
      @include absolute-center();
      display: block;
      font-family: Arial, monospace, -webkit-pictograph, sans-serif;
      font-size: 30px;
      line-height: 30px;
    }
  }
}

.widget-ninjabox {
  color: $white;
  &__filter {
    margin: 10px;
    &-wrapper{
      display: flex;
      justify-content: center;
    }
    &-check-element{
      &:hover {
        cursor: pointer;
        color: $dark-gray;
      }
      &--selected{
        color: $dark-gray;
      }
    }
  }
  .slick-slide {
    img {
      max-height: none;
      width: auto;
      height: auto;
      left: 50%;
      @include transform(translateX(-50%));
    }
  }
  &__bxslider-gallery{
    position: relative;
  }
  &__container {
    position: relative;
    text-align: center;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
  }
  &__bxslider-count {
    @include horizontal-center;
    top: 0;
  }
  &__bxslider-count-box {
    font-family: $title-font-family;
    display: block;
    width: 60px;

  }
  &__bxslider-controls{
    &--prev, &--next{
      @include vertical-center;
    }
    &--prev{left: 20px;}
    &--next{right: 20px;}
  }
  &__bottom{
    @include box-sizing();
    @include padding(10px 15px);
    position: relative;
    display: table;
    width: 100%;
    max-height: 200px;
    .form-container {
      display: table-cell;
      vertical-align: top;
      position: relative;
      height: 65px;
      width: 100%;
      .form-message-wrapper{
        position: absolute;
        left: 10px;
        top: -20px;
      }
    }
  }
}

/**************************************************************************************
        breakpoint(medium)
***************************************************************************************/
@include breakpoint(medium) {
  .reveal {
    .close-button {
      right: 20px;
      top: 20px;
    }
  }
}

@include breakpoint(large) {
  .js-overlay-content {
    position: relative;
    top: auto;
    right: auto;
    max-width: none;
    width: 100%;
    z-index: 10;
    overflow-y: auto;
    transition: none;
  }
}