@font-face {
  font-family: 'icomoon';
  src:  url('/css/fonts/icomoon/icomoon.eot?ia02ud');
  src:  url('/css/fonts/icomoon/icomoon.eot?ia02ud#iefix') format('embedded-opentype'),
  url('/css/fonts/icomoon/icomoon.woff2?ia02ud') format('woff2'),
  url('/css/fonts/icomoon/icomoon.ttf?ia02ud') format('truetype'),
  url('/css/fonts/icomoon/icomoon.woff?ia02ud') format('woff'),
  url('/css/fonts/icomoon/icomoon.svg?ia02ud#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[data-theme-sprite] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*********************
      Arrows
**********************/
[data-theme-sprite~="big-arrow"]:before {
  content: "\e921";
}

[data-theme-sprite~="picto-share"]:before {
  content: "\e91f";
}

[data-theme-sprite~="arrow-right"]:before {
  content: "\e900";
}

[data-theme-sprite~="double-arrow-right"]:before {
  content: "\e907";
}

[data-theme-sprite~="double-arrow-left"]:before {
  content: "\e908";
}

[data-theme-sprite~="simple-arrow-left"]:before {
  content: "\e913";
}

[data-theme-sprite~="simple-arrow-right"]:before {
  content: "\e914";
}

[data-theme-sprite~="arrow-down"] {
  @include vertical-center;
  right: 15px;
  @include css-triangle(6px,#333,down);
}

/*********************
      Social
**********************/
[data-theme-sprite~="instagram"]:before {
  content: "\e925";
}

[data-theme-sprite~="facebook"]:before {
  content: "\e91a";
}

[data-theme-sprite~="google-plus"]:before {
  content: "\e91b";
}

[data-theme-sprite~="twitter"]:before {
  content: "\e91c";
}

[data-theme-sprite~="youtube"]:before {
  content: "\e91d";
}

/*********************
      Others
**********************/
[data-theme-sprite~="croix"]:before {
  content: "\e902";
}

[data-theme-sprite~="add-car"]:before {
  content: "\e92f";
}

[data-theme-sprite~="delete"]:before {
  content: "\e92e";
}

[data-theme-sprite~="upload"]:before {
  content: "\e92d";
}

[data-theme-sprite~="transmission"]:before {
  content: "\e928";
}

[data-theme-sprite~="exterior-view"]:before {
  content: "\e923";
}

[data-theme-sprite~="interior-view"]:before {
  content: "\e924";
}

[data-theme-sprite~="broken-link"]:before {
  content: "\e922";
}

[data-theme-sprite~="note"]:before {
  content: "\e920";
}

[data-theme-sprite~="picto-print"]:before {
  content: "\e91e";
}

[data-theme-sprite~="email"]:before {
  content: "\e919";
}

[data-theme-sprite~="calendar"]:before {
  content: "\e901";
}

[data-theme-sprite~="car-availablility"]:before {
  content: "\e903";
}

[data-theme-sprite~="car-compare"]:before {
  content: "\e904";
}

[data-theme-sprite~="car-side"]:before {
  content: "\e905";
}

[data-theme-sprite~="checklist"]:before {
  content: "\e906";
}

[data-theme-sprite~="exchange"]:before {
  content: "\e909";
}

[data-theme-sprite~="gaz"]:before {
  content: "\e90b";
}

[data-theme-sprite~="km"]:before {
  content: "\e90c";
}
[data-theme-sprite~="locker"]:before {
  content: "\e90d";
}
[data-theme-sprite~="phone"]:before {
  content: "\e90e";
}
[data-theme-sprite~="phone-alt"]:before {
  content: "\e932";
}
[data-theme-sprite~="phone-header"]:before {
  content: "\e93a";
}
[data-theme-sprite~="photo"]:before {
  content: "\e90f";
}
[data-theme-sprite~="gallery"]:before {
  content: "\e930";
}
[data-theme-sprite~="pin"]:before {
  content: "\e910";
}

[data-theme-sprite~="rebate"]:before {
  content: "\e911";
}

[data-theme-sprite~="service"]:before {
  content: "\e912";
}
[data-theme-sprite~="star"]:before {
  content: "\e915";
}
[data-theme-sprite~="empty-star"]:before {
  content: "\e915";
}
[data-theme-sprite~="star-alt"]:before {
  content: "\e934";
}
[data-theme-sprite~="test-drive"]:before {
  content: "\e916";
}

[data-theme-sprite~="zoom"]:before {
  content: "\e918";
}

[data-theme-sprite~="play-background"]:before {
  content: "\e926";
}

[data-theme-sprite~="play-outline"]:before {
  content: "\e927";
}

[data-theme-sprite~="hour-business"]:before {
  content: "\e90a";
}

[data-theme-sprite~="promo-finacing"]:before {
  content: "\e917";
}

[data-theme-sprite~="promo-finacing"]:before {
  content: "\e917";
}

[data-theme-sprite~="about-form"]:before {
  content: "\e929";
}

[data-theme-sprite~="service-form"]:before {
  content: "\e92a";
}

[data-theme-sprite~="calculatrice"]:before {
  content: "\e92b";
}

[data-theme-sprite~="timer"]:before {
  content: "\e92c";
}

[data-theme-sprite~="gear"]:before {
  content: "\e931";
}

[data-theme-sprite~="picto-home"]:before {
  content: "\e933";
}

[data-theme-sprite~="arrow-left"]:before {
  content: "\e935";
}

[data-theme-sprite~="quote"]:before {
  content: "\e936";
}

[data-theme-sprite~="instagram-heart"]:before {
  content: "\e937";
}

[data-theme-sprite~="bodyshop"]:before {
  content: "\e938";
}

[data-theme-sprite~="glass"]:before {
  content: "\e939";
}