.catalog-details{
  /******************************/
  /*          HEADER            */
  /******************************/
  &__name{
    margin: rem-calc(0 0 20px);
  }
  &__header-right{
    margin-top: 20px;
  }
  &__header-block{
    position: relative;
    clear: both;
    .showroom-rebate{
      &__wrapper{
        position: absolute;
        left: 0;
        top: 170%;
        img{
          display: inline-block;
          float: left;
          max-width: 40px;
        }
      }
      &__content{
        display: inline-block;
        float: left;
        text-transform: uppercase;
        margin: rem-calc(4px 0 0 7px);
        @include font-size(12px);
        @include line-height(14px);
      }
      &__price{
        display: block;
        @include font-size(16px);
      }
    }
  }
  &__header-wrapper{
    overflow: visible;
  }
  &__header-vehicle-trims{
    @include grid-col-row();
    margin-bottom: 10px;
  }
  &__header-vehicle-trims-items{
    display: inline-block;
    margin-right: 30px;
    .link__regular{
      text-transform: none;
      &:hover{
        text-decoration: none;
      }
    }
  }
  &__main-image{
    margin-bottom: 10px;
  }
  &__gallery-section .page-wrapper{
    padding-top: rem-calc(20px);
  }
  &__gallery-button{
    padding: 10px 20px 15px;
    text-align: center;
  }
  &__gallery-button-title{
    text-align: center;
    @include inline-block();
    margin-right: 35px;
  }
  &__gallery-button--more{
    @include inline-block();
    padding-left: 15px;
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
    .overlay{
      overflow: visible;
    }
  }
  &__gallery-wrapper{
    margin-bottom: 20px;
    .overlay-content{
      @include font-size(20px);
      color: $white;
    }
  }
  &__gallery-list{
    @include grid-column-row(0);
    width: 100%;
  }
  &__gallery-item{
    width: 40%;
    padding: 5px;
    display: inline;
    float: left;
  }
  &__gallery-medium{
    width: 20%;
    float: left;
  }
  &__gallery-item-medium{
    display: block;
    padding: 5px;
  }
  &__colors-thumbnails-button{
    display: inline-block;
    background-color: #fff;
    margin-right: 10px;
    vertical-align: top;
  }
  &__header-images{
    position: relative;
  }
  &__header-information{
    position: relative;
    box-shadow: 0px 0px 10px 0px $box-shadow;

  }
  &__header-information-wrapper{
    @include grid-col-row();
    @include grid-column-position(center);
  }
  &__header-information-nexted{
    @include  grid-row-nest();
  }
  &__information-prices {
    @include grid-column(100%);
    .showroom-price__wrapper{
      .price__detail-wrapper{
        width: 100%;
        min-height: 0;
        float: none;
        @include padding(13px 0 0);
        .price__detail-price-label{
          position: relative;
          top: -7px;
        }
        .price__detail-price--regular{
          display: inline-block;
          @include font-size(20px);
          margin-top: rem-calc(4px);
        }
      }
      .price__detail-rebate{
        width: 100%;
        min-height: 0;
        float: none;
        border: 0;
        @include padding(0 0 11px);
        .price__detail-price--old{
          display: inline-block;
          line-height: 22px;
          float: initial;
          @include font-size(14px);
          @include line-height(22px);
          text-decoration: none;
        }
        .price__detail-price-price{
          text-decoration: line-through;
        }
        .price__detail-price-label{
          @include font-size(13px);
          font-weight: normal;
        }
        .price__detail-rebate-wrapper{
          display: inline-block;
          @include line-height(22px);
          vertical-align: baseline;
          margin-left: rem-calc(5px);
          .price__detail-rebate-text,
          .price__detail-rebate-price{
            display: inline;
            float: none;
            @include font-size(14px);
          }
        }
      }
    }
    .price__detail-price-legend{
      display: block;
      float: none;
      margin-top: 4px;
    }
  }
  &__information-actions-wrapper,
  &__information-prices-wrapper{
    @include grid-column();
  }
  &__information-prices-item{
    display: table-cell;
    vertical-align: middle;
    float: left;
  }
  &__information-prices-coming-soon,
  &__information-prices-label{
    @include font-size(16px);
    line-height: 18px;
  }
  &__information-prices-regular{
    @include font-size(24px);
    line-height: 26px;
  }
  &__information-prices-old{
    @include font-size(16px);
    line-height: 26px;
    text-decoration: line-through;
  }
  &__information-prices-label-rebate{
    @include font-size(13px);
    line-height: 15px;
  }
  &__information-prices-rebate{
    @include font-size(16px);
    line-height: 20px;
  }
  &__information-actions{
    @include inline-block();
    @include padding(20px 0);
    width: 100%;
  }
  &__information-actions-item{
    vertical-align: middle;
    margin-left: 10px;
  }
  &__information-trims-wrapper{
    padding: 36px 0;
  }
  &__information-trims-dropdown{
    .widget-dropdown__button{
      border: none;
      padding: 0;
    }
  }
  &__gallery-link{
    @include inline-block();
    .catalog-details__gallery-button--more{
      text-decoration: none;
    }
    .more-photo__button-icon{
      display: inline;
      margin-right: 5px;
    }
  }
  &__information-compare{
    @include grid-column-row();
    text-align: left;
    .widget-checkbox-label{
      @include inline-block();
      width: 200px;
      position: relative;
      text-align: left;
      line-height: 14px;
    }
  }
  &__colors{
    width: 100%;
    margin: 10px auto;
    text-align: center;
  }
  &__colors-thumbnails{
    @include inline-block();
    position: relative;
    width: 35px;
    height: 35px;
    margin: 0px;
    @include border-radius(50%);
    @include box-shadow(0px 3px 5px 0px rgba(0,0,0,0.4));
  }
  &__colors-thumbnails-image{
    border: 2px solid $white;
    @include border-radius(50%);
    @include absolute-center;
  }
  &__colors-thumbnails-button--selected{
    img{
      opacity: 0.3;
    }
  }
  &__colors-thumbnails-icon{
    display: none;
  }
  &__colors-thumbnails--selected{
    @include border-radius(50%);
    .catalog-details__colors-thumbnails-image{
      width: 35px;
      height: 35px;
    }
    .catalog-details__colors-thumbnails-icon{
      position: absolute;
      top: 11px;
      left: 9px;
      display: inline;
      &:before{
        @include font-size(18px);
      }
    }
  }
  &__colors-name {
    @include font-size(12px);
    padding: 5px 0;
  }

  &__header-actions{
    position: relative;
    margin-top: 10px;
  }
  &__header-cta a {
    display: block;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  &__inventory-availability {
    display: inline-block;
    box-sizing: border-box;
    margin-bottom: 10px;
    z-index: 1;
    -moz-animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
    padding: 10px;
    min-width: 145px;
    border-radius: 20px;
    overflow: visible;
    text-align: center;
    float: left;
  }
  &__inventory-availability--text{
    .link__regular-text{
      span{
        @include font-size(20px);
        font-family: $title-font-family;
        font-weight: 700;
      }
    }
  }
  &__inventory-availability--link{
    text-decoration: none;
    :hover{
      text-decoration: underline;
    }
  }

  /*********************************************************
    Catalog Vehicle Details - Images Interior View
**********************************************************/
  &__views-wrapper{
    position: relative;
    z-index: 0;
  }
  &__interior{
    display: none;
    text-align: center;
    .catalog-details__content-images__slider-item{
      //margin: 0 130px;
      margin: 0 5px;
    }
    .catalog-details__content-images__slider-item[aria-hidden=true]{
      opacity: 0;
    }
  }
  &__interior-colors-main-image{
    display: initial;
    margin: 0 auto;
  }
  &__interior-thumbnails{
    @include inline-block();
  }
  &__interior-thumbnails-color{
  }
  &__colors-thumbnails-button{
    @include inline-block();
    margin-right: 10px;
  }

  /*********************************************************
      Catalog Vehicle Details - Images Slider
  **********************************************************/
  &__content-images{
    position: relative;
    @include inline-block();
    width: 100%;
    text-align: center;
    .bx-viewport{
      width: 100%;
      position: relative;
      overflow: visible!important;
    }
    .bx-controls.bx-has-pager{
      @include show-for-only(small);
    }
    &__slider{
      overflow: hidden;
    }
    &__slider-item{
      position: absolute;
      width: 650px;
      margin: 0 5px;
      .overlay:hover img{
        opacity: 1;
      }
      .overlay-content{
        border-radius: 100%;
        background: $black;
        border-color: $black;
      }
      .overlay-content__picto{
        @include font-size(20px);
      }
    }
    &__slider-item[aria-hidden="true"] {
      opacity: 0.1;
    }
    &__slider-item[aria-hidden="false"] {
      opacity: 1;
    }
    &__control{
      @include absolute-center;
      @include hide-for-only(small);
      width: 100%;

      .link__slider{
        box-shadow: none;
      }
    }
    &__control-anchor{
      position: absolute;
      @include font-size(40px);
      line-height: 40px;
    }
    &__control-anchor-prev{
      position: absolute;
      top: 50%;
      left: 0;
      @include transform(translate(0, -50%));
      z-index: 10;
    }
    &__control-anchor-next{
      position: absolute;
      top: 50%;
      right: 0;
      @include transform(translate(0, -50%));
      z-index: 10;
    }
  }
  &__360-ext,
  &__360-int{
    display: none;
    text-align: center;
  }

  /******************************/
  /*        MAIN CONTENT        */
  /******************************/
  &__content{
    padding-bottom: 60px;
  }
  &__widget-showroom{
    background-color: #000000;
    &--wrapper{
      padding: 0;
    }
  }
  &__info-vehicle{
    text-align: center;
  }
  &__consumptions{
    text-align: center;
  }
  &__consumptions-block{
    display: inline-table;
    padding: 0 20px;
  }
  &__consumptions-value{
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(34px);
    line-height: 26px;
    margin-bottom: 5px;
    span{
      text-transform: uppercase;
      display: block;
      @include font-size(12px);
      color: $dark-gray;
      font-family: $body-font-family;

    }
  }
  &__consumptions-label{
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(18px);
    margin-bottom: 2px;
  }
  &__descriptions{
    max-width: 710px;
    margin: 0 auto;
  }
  &__ctas{
    @include clearfix();
    clear: both;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  &__ctas-block{
    @include grid-column-position(center);
  }
  &__ctas-instance{
    @include grid-column(12);
    text-align: center;
    margin-bottom: 40px;
  }
  &__ctas-instance-top{
    position: relative;
    min-height: 75px;
  }
  &__ctas-instance-image{
    @include absolute-center;
    width: 65px;
    height: 65px;
  }
  &__ctas-instance-anchor{
    width: auto;
    font-size: 12px;
  }
  &__ctas-instance-info{
    font-size: 18px;
    line-height: 20px;
    margin: 20px 0;
  }
  &__main-image{
    @include grid-col(12);
  }
  &__accordion{
    @include grid-col(12);
    margin-bottom: 25px;
    .widget-accordion-item{
      border-bottom: 0;
      border-top: 1px solid $border-grey;
    }
  }
  &__trim-compare{
    @include grid-col(12);
    clear: both;
    margin-bottom: 40px;
  }
  &__trim-count{
    @include grid-col(12);
    margin: 0;
    span:first-of-type{
      margin: 0 3px 0 0;
    }
  }
  &__trim-link{
    @include grid-col(12);
    margin: 0;
    @include font-size(14px);
    line-height: 20px;
    text-align: center;
  }
  &__trim-link:last-child:not(:first-child){
    float: left;
  }
  &__trim-count,
  &__trim-link{
    padding-top: 20px;
    padding-bottom: 20px;
    display: inline;
    vertical-align: middle;
  }
  &__trim-compare-icon{
    padding-left: 10px;
    font-size: 18px;
    top: 3px;
    position: relative;
  }
  &__promotion{
    clear: both;
    text-align: center;
    margin: rem-calc(60px 0 70px);
  }
  &__content-cta{
    @include grid-column(12);
    text-align: center;
    margin-bottom: 40px;
  }
  &__cta-title{
    margin-bottom: 20px;
  }
  &__cta-wrapper{
    @include grid-column-size(12);
    @include grid-column-position(center);
  }
  &__cta{
    width: 100%;
    margin-bottom: 20px;
  }
  &__disclaimer{
    @include inline-block;
    max-width: 964px;
    text-align: left;
    margin: 40px auto 0;
  }
  &__details{
    @include clearfix();
  }
  &__details-title{
    margin-bottom: 0;
  }
  &__details-gallery{
    margin-bottom: 30px;
  }
  &__details-gallery-img{
    display: inline-block;
    vertical-align: top;
    margin-right: -3px;
    width: 150px;
    height: 110px;
    .more-photo__button-text{
      text-transform: uppercase;
    }
    .more-photo__button-icon{
      margin-bottom: 5px;
    }
  }
  &__details-infos{
    padding-bottom: 15px;
  }
  &__details-specs .widget-accordion__content{
    padding-left: rem-calc(45px);
  }
  &__details-specs-title{
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(14px);
    @include padding(15px 0 15px 45px);
    &:before{
      right: auto;
      left: 15px;
    }
  }
  &__details-specs-list{
    margin-left: 20px;
  }
  &__details-specs-element{
    list-style-type: disc;
    margin: 10px 0;
  }
  &__details-specs-type{
    font-family: $title-font-family;
    font-weight: 700;
    margin: 5px 0;
  }
  &__legal-wrapper{
    @include padding(10px 0);
  }
  &__form{
    clear: both;
    border-top: 5px solid;
    padding-bottom: rem-calc(105px);
    background-size: cover;
    .page-wrapper{
      overflow: visible;
    }
    .form-footer{
      @include grid-column-row();
      @include grid-column-position(center);
      max-width: 810px;
      margin: 0 auto;
      &__title{
        text-align: center;
        @include font-size(36px);
        @include line-height(38px);
      }
      &__radio-label{
        display: block;
        @include font-size(13px);
        font-weight: normal;
      }
      .radio-button__label{
        display: block;
        margin-right: 0;
      }
      &__content{
        float: none !important;
        text-align: center;
      }
      .form-container__fieldset-required{
        margin: rem-calc(5px -10px 0);
      }
      .form-field__label-checkbox{
        text-align: center;
      }
      .submit-wrapper{
        width: 100%;
        text-align: center;
        margin-bottom: rem-calc(10px);
        .submit{
          width: auto;
        }
      }
      .form-container__link-policy{
        text-align: center;
      }
    }
  }
  &__form-content{
    iframe{
      width: 100%;
      height: 810px;
    }
  }
}

/***********************************************************
           Breakpoints :
            - small: 0,               //Mobile
            - medium: 768px,          //Tablet
            - large: 1024px,          //Desktop
            - xlarge: 1200px,
            - xxlarge: 1920px,
************************************************************/

@include breakpoint(medium){
  .catalog-details{
    &__ctas-instance{
      @include grid-column(4);
      @include grid-column-end;
      margin-bottom: 0;
    }
    &__consumptions-block{
      padding: 0 60px;
    }
    &__main-image{
      @include grid-col(6);
      margin-top: 40px;
    }
    &__accordion{
      @include grid-col(6);
    }
    &__trim-count {
      @include grid-col(10);
      margin: 10px 0;
      span:first-of-type {
        margin: 0 3px 0 20px;
      }
    }
    &__trim-link{
      @include grid-col(2);
      margin: 10px 0;
    }
    &__trim-count,
    &__trim-link {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    &__content-cta{
      text-align: center;
    }
    &__cta-wrapper{
      @include grid-column-size(10);
      @include grid-column-position(center);
    }
    &__cta{
      width: auto;
      margin-bottom: 0;
      &:last-child{
        margin-left: 20px;
      }
    }
    &__gallery-link{
      margin-top: 5px;
      border-top: 1px solid $dark-gray;
      border-bottom: 1px solid $dark-gray;
      padding: 10px 0;
    }
    &__content-images{
      &__slider{
        height: 375px!important;
      }
      &__slider-item{
        height: 100%;
      }
    }
    &__information-compare{
      float: right;
      display: block;
      top: calc(100% - 55px);
      position: absolute;
      right: 15px;
      text-align: right;
      width: auto;
      .widget-checkbox-label{
        width: 100px;
        display: inline-block;
        position: relative;
        top: 2px;
        left: 5px;
        text-align: left;
        line-height: 14px;
      }
    }
    &__information-prices {
      @include grid-column(30%);
    }
    &__information-actions{
      float: right;
      width: auto;
    }
    &__form .form-footer{
      .radio-button__label {
        margin-right: rem-calc(25px);
        @include inline-block();
      }
    }
  }
}

@include breakpoint(large) {
  .catalog-details {
    &__ctas-instance {
      padding: 0 30px;
    }
    &__ctas-instance-image {
      width: 75px;
      height: 75px;
    }
    &__ctas-instance-info {
      @include font-size(20px);
      @include line-height(24px);
    }
    &__ctas-instance-anchor {
      font-size: 15px;
    }
    &__details-specs-title {
      @include font-size(18px);
    }
    &__trim-count {
      @include grid-col(10);
      span:first-of-type {
        margin: 0px 3px 0 20px;
      }
    }
    &__trim-link{
      @include grid-col(2);
      a:hover{
        text-decoration: underline;
      }
    }
    &__gallery-wrapper {
      margin-bottom: 40px;
    }
    &__information-compare {
      float: right;
      display: block;
      top: calc(100% - 55px);
      position: absolute;
      .widget-checkbox-label {
        width: 50%;
        display: inline;
        position: relative;
        top: 2px;
        left: 0;
      }
    }
    &__interior{
      .catalog-details__content-images__slider-item{
        margin: 0 5px;
        width: 600px !important;
      }
    }
    &__content-images {
      &__slider {
        margin-left: 230px;
      }
      &__slider-item {
        width: 745px !important;
        margin: -20px;
      }
    }
    &__information-actions{
      @include padding(13px 0 11px);
    }
    &__information-actions-item{
      margin-left: 20px;
    }
    &__information-prices {
      float: left;
      margin-left: 30px;
      width: 45%;
      .showroom-price__wrapper{
        .price__detail-wrapper{
          width: 60%;
          min-height: 70px;
          float: left;
          @include padding(13px 10px 11px 0);
          .price__detail-price--regular{
            @include font-size(26px);
          }
        }
        .price__detail-rebate{
          width: 40%;
          min-height: 70px;
          float: left;
          border-left: 1px solid lighten($dark-gray, 15%);
          @include padding(13px 0 11px 20px);
          .price__detail-price--old{
            display: block;
            float: initial;
            margin-bottom: rem-calc(5px);
            line-height: normal;
          }
          .price__detail-rebate-wrapper{
            display: block;
            @include line-height(20px);
            vertical-align: middle;
            margin-left: 0;
            .price__detail-rebate-text{
              @include font-size(13px);
            }
            .price__detail-rebate-price{
              @include font-size(16px);
            }
          }
        }
      }
    }
  }
}

@include breakpoint(xlarge) {
  .catalog-details{
    &__ctas-block{
      max-width: $global-width;
    }
    &__trim-count{
      span:first-of-type{
        margin: 0px 3px 0 30px;
      }
    }
    &__gallery-wrapper{
      margin-bottom: 50px;
    }
    &__interior{
      .catalog-details__content-images__slider-item{
        //margin: 0 32px;
        width: 640px !important;
      }
    }
  }
}
