.catalog-listing{
  &__make-block{
    @include clearfix();
    position: relative;
  }
  &__title-secondary{
      @include grid-column-row(12);
  }
  &__promo{
    @include grid-column-row(12);
    .promotion__header{
      @include grid-column-row(12);
      margin: 15px 0 40px 0;
    }
  }
  .listing-block__tradesii{
    margin-bottom: 20px;
  }
  &__filter-wrapper{
    @include grid-column-row(12);
    margin-bottom: 20px;
  }
  &__filter{
    @include grid-column-row(12);
    .form-field__label{
      margin: 0;
    }
    .form-field__label-checkbox--wrapper{
      margin: 0 25px 0 0;
    }
    [type=checkbox]:checked+label, [type=checkbox]:not(:checked)+label{
      padding-left: 20px;
    }
    label{
      color: inherit;
    }
  }
  &__form{
    min-height: 950px;
    background-color: $black;
    border-top: 5px solid;
    background-size: cover;
    .page-wrapper{
      @include overflow(visible);
    }
    &-logo{
      position: absolute;
      left: 50%;
      top: -79px;
      @include transform(translate(-50%, 0));
    }
    .title__primary{
      @include font-size(36px);
    }
  }
  &__text-seo{
    @include grid-column-position(center);
    padding: 10px;
    margin: 40px 10px 10px;
  }
  &__html-seo{
    @include columns-divider(1,50px, transparent, none,0);
    p{
      @include font-size(14px);
      margin: rem-calc(0 0 15px);
    }
    .title{
      @include font-size(24px);
      @include line-height(28px);
    }
  }
  &__disclaimer{
    @include grid-column-row();
    margin: rem-calc(70px 0 45px);
    text-align: left;
  }
}


/***********************************************************
           Breakpoints :
            - small: 0,               //Mobile
            - medium: 640px,
            - tablet: 768px           //Tablet
            - large: 1024px,          //Desktop
            - xlarge: 1200px,
            - xxlarge: 1920px,
************************************************************/

@include breakpoint(medium){
  .catalog-listing{
    &__text-seo{
      padding: 40px;
      margin: 40px;
    }
  }
}

@include breakpoint(tablet){
  .catalog-listing{
    &__html-seo{
      @include columns-divider(2,50px, transparent, none,0);
    }
  }
}