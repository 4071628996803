.contactpage {
  //@include grid-column-row(12);
  .title__primary {
    margin-bottom: 15px;
  }
  &__form {
    @include grid-column(12);
  }
  &__img{
    @include grid-column(4);
  }
  .form-container__link-policy {
    text-align: left;
  }
}