.page-cpo{
  .title__primary{
    padding: 0;
  }
  .wysiwyg,
  .wysiwyg p{
    @include font-size(16px);
    @include line-height(24px);
  }
  &-header__second-part-wrapper{
    margin-top: 30px;
    padding-top: 30px;
    border-top: 4px solid;
  }
  &-header__instances{
    @include grid-row-nest();
    &-item{
      @include grid-column(6);
      margin: 20px 0;
    }
    &-image{
      @include inline-block();
    }
    &-info{
      @include inline-block();
      width: 60%;
      margin-left: 20px;
    }
  }
  &-header__cta{
    @include grid-column(12,0);
    text-align: left;
    padding: 20px 0;
  }
  &-inspection{
    &__row{
      @include grid-row-nest();
    }
    &__column{
      @include grid-column(6);
      &:last-child{
        @include grid-column(5);
      }
    }
  }
  &-financing{
    &__row{
      @include grid-row-nest();
    }
    &__column{
      @include grid-column(12);
      &:first-child{
        @include hide-for-only(medium);
      }
    }
  }
}

/***********************************************************
           Breakpoints :
            - small: 0,               //Mobile
            - medium: 768px,          //Tablet
            - large: 1024px,          //Desktop
            - xlarge: 1200px,
            - xxlarge: 1920px,
************************************************************/

@include breakpoint(large){
  .page-cpo-financing__column {
    @include grid-column(6);
    &:last-child {
      @include grid-column(5);
    }
  }
}