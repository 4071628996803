/***********************************
      Home Inventory Header
************************************/

.home-inventory-header{
  &__wrapper{
    margin: rem-calc(0 0 25px);
  }
  &__title{
    @include font-size(60px);
    @include line-height(60px);
    margin: 0;
  }
  &__subtitle{
    @include font-size(30px);
    @include line-height(32px);
    margin: 0;
  }
}

/***********************************
      Inventory Slider Generic
************************************/
.inventory-slider{
  position: relative;
  padding: 40px 0 20px;
  margin-bottom: 40px;
  &__slider-wrapper{
    margin: 0 auto;
  }
  &__slider{
    display: block;
  }
  &__slider-disabled{
    @include grid-row-nest();
    .inventory-slider__slider-block{
      @include grid-column(6);
    }
  }
  &__controls{
    @include horizontal-center;
    width: 150px;
    bottom: 0;
  }
  &__control-left, &__control-right{
    @include inline-block();
    width: 25px;
    height: 25px;
    &:hover{
      opacity: 0.2;
    }
  }
  &__slider-pager {
    @include inline-block();
    margin:0 5px;
  }
  &__pager-item{
    @include inline-block();
    width: 12px;
    height: 12px;
    @include border-radius(50%);
    border: 2px solid;
    margin: 0 5px;
    cursor: pointer;
  }
}

/***********************************
  "Inventory Slider - Generic"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/
@include breakpoint(medium) {
  .inventory-slider__slider-disabled .inventory-slider__slider-block{
    @include grid-column(3);
  }
}
