.page-thanks{
  &__main-content{
    padding:23px;
    text-align: center;
    h1{
      text-align: center;
    }
  }
  &__link-content{
    margin:10px 0;
  }
}
