.inventory-details{
  /******************************/
  /*          HEADER            */
  /******************************/
  &__header-section{
    position: relative;
    .page-wrapper{
      padding-bottom: 0;
    }
  }
  &__title{
    @include grid-col(12);
  }
  &__name{
    margin-top: 0;
    margin-bottom: rem-calc(5px);
    @include grid-col(12);
  }
  &__header-main{
    .page-wrapper{
      padding-top: rem-calc(20px);
      padding-bottom: 0;
    }
  }
  &__header{
    @include grid-col(12);
    clear: both;
    .more-photo__button--more{
      margin: 8px 6px 0 0;
      position: absolute;
      right: 15px;
      bottom: 55px;
      width: 60px;
      height: 60px;
      border-radius: 60px;
      .more-photo__button-icon{
        display: inline-block;
      }
    }
  }
  &__info{
    @include grid-col(12);
    float: left;
    margin-bottom: rem-calc(10px);
  }
  &__header-tagline{
    @include font-size(16px);
  }
  &__header-stock, &__header-serial{
    display: block;
    @include font-size(12px);
  }
  &__header-stock{
    @include padding(0 12px 0 0);
  }
  &__header-serial{
    padding-left: rem-calc(12px);
  }
  &__header-pictos, &__header-tagline{
    padding-top:5px;
    padding-bottom: 5px;
  }
  &__header-pictos .inventory-list-layout__preview-pictos-top{
    display: inline-block;
  }
  &__header-content{
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;
    .overlay-content{
      @include font-size(20px);
    }
  }
  &__header-main-picture{
    width: 100%;
    float: left;
    @include clearfix();
    position: relative;
    &--center{
      float: none;
      margin: 0 auto;
    }
    .inventory-list-layout__ruban-sold{
      width: 150%;
      position: absolute;
      left: 50%;
      top: 50%;
      text-align: center;
      @include font-size(80px);
      @include line-height(80px);
      text-transform: uppercase;
      @include padding(3px 0 6px);
      @include transform(rotate(-35deg) translate(-50%, -50%));
      @include transform-origin(top left);
    }
    iframe{
      max-width: 100%;
      max-height: 357px;
    }
  }
  &__clearance{
    position: absolute;
    left: 20px;
    bottom: 20px;
    @include border-radius(0 0 20px);
    &-text{
      display: block;
      @include padding(8px 20px 8px 10px);
      @include font-size(18px);
      @include line-height(20px);
      text-transform: uppercase;
    }
  }
  &__header-gallery-item {
    display: block;
  }
  &__header-gallery-image{
    width: 50%;
    float: right;
    margin-bottom: 15px;
    display: block;
  }
  &__header-price-wrapper{
    min-height: 70px;
    padding: 0;
    width:100%;
    margin:0 auto;
    text-align: left;
  }
  &__vehicle-info-wrapper{
    min-height: 70px;
    @include padding(8px 0 0);
    border-top: 1px solid $border-grey;
    text-align: center;
    margin:0 auto;
    width: 100%;
  }
  &__vehicle-info-container{
    display: table;
    width: 100%;
  }
  &__vehicle-info{
    display: table-cell;
    width: 50%;
    float: left;
    margin: 10px 0;
    padding-right:10px;
    padding-left:10px;
    text-align: left;
    .icon{
      @include font-size(26px);
      margin-right: 10px;
      position: relative;
      top: 5px;
      float: left;
      &[data-theme-sprite="km"]{
        @include font-size(20px);
        top: 10px;
      }
    }
  }
  &__vehicle-info-content{
    display: block;
    float: left;
  }
  &__vehicle-info-legend{
    display: block;
    @include font-size(12px);
  }
  &__vehicle-info-value{
    font-family: $title-font-family;
    font-weight: 700;
    color: $black;
    display: block;
    @include font-size(14px);
  }
  &__gallery-button{
    display: inline-block;
  }
  &__header-compare,
  &__header-actions{
    display: inline-block;
  }
  &__vehicle-info-wrapper,
  &__header-price-wrapper,
  &__header-price,
  &__header-rebate-price,
  &__header-pictos,
  &__header-buttons-wrapper{
    display: inline-table;
    vertical-align: middle;
  }
  &__header-price{
    .price__detail-wrapper{
      display: block;
      .price__detail-price-label,
      .price__detail-price--regular,
      .price__detail-price-legend {
        display: block;
      }
      .price__detail-price--old,
      .price__detail-price-label{
        display: inline;
      }
    }
    &-sold{
      font-family: $title-font-family;
      font-weight: 700;
      @include font-size(16px);
      display: inline-block;
      margin: rem-calc(0 40px 0 10px);
    }
  }
  &__header-rebate-price{
    .price__detail-price--old{
      float: none;
      @include font-size(13px);
      margin-bottom: rem-calc(4px);
      .price__detail-price-label{
        display: none;
      }
    }
    .price__detail-rebate-wrapper{
      @include font-size(13px);
    }
    .price__detail-rebate-text{
      display: inline;
    }
    .price__detail-rebate-price{
      @include font-size(18px);
      display: inline;
      float: none;
    }
  }
  &__header-buttons-wrapper{
    width: 100%;
    min-height: 0;
    @include padding(15px 0 0);
    text-align: center;
    vertical-align: top;
  }
  &__headerInfo{
    padding: 0;
  }
  &__header-information{
    clear: both;
    overflow: hidden;
    margin: 0 auto;
    display:table;
    padding: 20px;
  }
  &__header-compare{
    margin: 0;
    .widget-checkbox-label {
      width: 135px;
    }
  }
  &__header-carproof{
    padding-top: 15px;
    margin-top: 15px;
    .picto__carproof{
      margin: 8px 0;
      display: block;
    }
  }
  &__header-ctas{
    text-align: center;
    padding: 20px 0;
  }
  &__header-cta{
    display: inline-table;
    .icon{
      @include font-size(42px);
      color: $gray;
    }
    .link__regular{
      display: inline-block;
      padding-left: 5px;
      position: relative;
      top: -14px;
    }
  }
  &__header-cta.brochure{
    margin-left: 80px;
  }
  &__header-actions{
    position: relative;
  }
  &__header-price{
    line-height: 26px;
    width: 45%;
  }
  /******************************/
  /*        MAIN CONTENT        */
  /******************************/
  &__ctas{
    @include clearfix();
    clear: both;
    @include padding(50px 0 45px);
  }
  &__ctas-block{
    @include grid-column-position(center);
  }
  &__ctas-instance{
    @include grid-column(12);
    text-align: center;
    margin-bottom: 40px;
  }
  &__ctas-instance-top{
    position: relative;
    min-height: 75px;
  }
  &__ctas-instance-image{
    @include absolute-center;
    width: 65px;
    height: 65px;
  }
  &__ctas-instance-anchor{
    width: auto;
    font-size: 12px;
  }
  &__ctas-instance-info{
    font-size: 18px;
    line-height: 20px;
    margin: 20px 0;
  }
  &-content{
    overflow: hidden;
  }
  &__content{
    padding-bottom: 60px;
  }
  &__content-sidebar{
    @include grid-column(10,0);
    @include grid-column-position(center);
  }
  &__content-block{
    margin-bottom: 50px;
    width: 100%;
  }
  &__content-contact{
    margin-bottom: 70px;
  }
  &__content-contact-image,
  &__content-contact-info{
    display: block;
    vertical-align: middle;
  }
  &__content-contact-info{
    .title__tertiary{
      margin-bottom: 0px;
    }
    .title__quaternary{
      margin: 0px;
      &+.title__tertiary{
        margin-top: 0px;
        margin-bottom: rem-calc(10px);
      }
    }
  }
  &__content-certified,
  &__content-carfax{
    margin-bottom: 70px;
    img{
      padding: 0;
    }
  }
  &__content-carproof{
    margin: rem-calc(0 0 70px);
    @include font-size(20px);
    @include line-height(22px);
    a{
      @include font-size(13px);
      @include line-height(14px);
      &:not(.link__regular){
        display: block;
        margin: rem-calc(15px 0 12px);
      }
    }
  }
  &__content-certified{
    .wysiwyg{
      ul{
        margin-left: 0px;
      }
      li{
        position: relative;
        list-style-type: none;
        padding-left: rem-calc(20px);
        &:before{
          @extend [data-theme-sprite];
          content: "\e906";
          @include font-size(14px);
          position: absolute;
          left: 0;
          top: 7px;
        }
      }
      .icon{padding-right: 10px;}
    }
  }
  &__content-btn{
    margin-top: 60px;
    position: relative;
  }
  &__content-btn-info{
    position: absolute;
    top: 42px;
    left: 35px;
    text-align: center;
    .link__ghost{
      margin-top: 10px;
    }
  }
  &__details{
    margin-top: 50px;
    @include clearfix();
  }
  &__details-infos-wrapper{
    @include clearfix();
    margin-bottom: 20px;
    .title__secondary{
      @include font-size(36px);
      @include line-height(38px);
    }
  }
  &__details-infos{
    width: 100%;
    float: left;
    margin-bottom: 15px;
    line-height:22px;
  }
  &__details-infos-title{
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(18px);
    line-height: 24px;
    margin-bottom: 10px;
  }
  &__details-title{
    border-bottom: $code-border;
    padding-bottom: 15px;
    margin-bottom: 20px;
  }
  &__content-wrapper{
    padding: 10px 0;
    @include font-size(14px);
    line-height: 14px;
    border-bottom: 1px solid $border-grey;
    &:last-of-type{
      border-bottom: none;
    }
  }
  &__content-label{
    font-family: $body-font-family;
    display: inline-block;
    min-width: 150px;
  }
  &__content-value{
    display: inline-block;
  }
  &__content-specs,
  &__content-warranty,
  &__content-history,
  &__content-cta{
    @include clearfix();
    padding: 40px 0;
    border-top: 4px solid;
  }
  &__content-specs .title__secondary{
    @include font-size(36px);
    @include line-height(38px);
  }
  &__content-cta{
    @include grid-column(12);
    text-align: center;
    margin-bottom: 40px;
  }
  &__cta-title{
    margin-bottom: 20px;
  }
  &__cta-wrapper{
    @include grid-column-size(12);
    @include grid-column-position(center);
  }
  &__cta{
    width: 100%;
    margin-bottom: 20px;
  }
  &__content-options{
    padding: 40px 0;
    .title{
      font-family: $title-font-family;
      font-weight: 700;
      @include font-size(36px);
      @include line-height(38px);
      margin-bottom: rem-calc(15px);
    }
  }
  &__content-options-list{
    @include inline-block;
  }
  &__content-options-item{
    @include grid-column(50%,0);
    @include font-size(14px);
    list-style-type: disc;
    line-height: 24px;
  }
  &__content-warranty{
    padding-bottom: rem-calc(40px);
  }
  &__disclaimer{
    @include inline-block;
    width: 100%;
    margin: 40px 0;
  }
  &__form{
    border-top: 5px solid;
    padding-bottom: rem-calc(105px);
    background-size: cover;
    .page-wrapper{
      overflow: visible;
    }
    .form-footer{
      @include grid-column-row();
      max-width: 810px;
      margin: 0 auto;
      &__title{
        text-align: center;
        @include font-size(36px);
        @include line-height(38px);
      }
      &__radio-label{
        display: block;
        @include font-size(13px);
        font-weight: normal;
      }
      .radio-button__label{
        display: block;
        margin-right: 0;
      }
      &__content{
        float: none !important;
        text-align: center;
      }
      .form-container__fieldset-required{
        margin: rem-calc(5px -10px 0);
      }
      .form-field__label-checkbox{
        text-align: center;
      }
      .submit-wrapper{
        width: 100%;
        text-align: center;
        margin-bottom: rem-calc(10px);
        .submit{
          width: auto;
        }
      }
      .form-container__link-policy{
        text-align: center;
      }
    }
  }
  &__form-content{
    iframe{
      width: 100%;
      height: 810px;
    }
  }
  &__comparable-title{
    text-align: center;
    margin-bottom: 40px;
  }
  &__social{
    text-align: right;
  }
  /******************************/
  /*          SIDEBAR           */
  /******************************/
  &-content{
    padding-bottom: 0;
    .page-main__content{
      width: auto;
      float: none;
    }

    .page-sidebar__right{
      width: auto;
      float: none;
      @include padding(30px);
      margin: rem-calc(0 -20px);
    }
  }
  /******************************/
  /*          COMPARABLES         */
  /******************************/
  &__comparables{
    overflow: hidden;
    padding-bottom: rem-calc(95px);
    clear: both;
    .inventory-list-layout__preview-image{
      border: 1px solid $border-grey;
      border-bottom: 0px;
    }
  }
}

/***********************************************************
           Breakpoints :
            - small: 0,               //Mobile
            - medium: 768px,          //Tablet
            - large: 1024px,          //Desktop
            - xlarge: 1200px,
            - xxlarge: 1920px,
************************************************************/

@include breakpoint(medium){
  .inventory-details{
    &__header-stock, &__header-serial{
      display: inline;
    }
    &__header-serial{
      padding-left: rem-calc(12px);
    }
    &__header-price-wrapper{
      width:50%;
    }
    &__vehicle-info-wrapper{
      width: 50%;
      border-top: none;
      border-left: 1px solid $border-grey;
    }
    &__vehicle-info{
      padding-right:10px;
      padding-left:10px;
    }
    &__header-main-picture{
      width: 65%;
    }
    &__header-gallery-image{
      width: 31%;
    }
    &__header-main-picture iframe{
      max-height: none;
    }
    &__ctas-instance{
      @include grid-column(4);
      @include grid-column-end;
      margin-bottom: 0;
    }
    &__form .form-footer{
      .radio-button__label {
        margin-right: rem-calc(25px);
        @include inline-block();
      }
    }
    &__content-cta{
      text-align: center;
    }
    &__content-sidebar{
      @include grid-column(12);
      @include grid-column-position(center);
    }
    &__content-block{
      @include grid-column(6);
    }
    &__cta-wrapper{
      @include grid-column-size(10);
      @include grid-column-position(center);
    }
    &__cta{
      width: auto;
      margin-bottom: 0;
      &:last-child{
        margin-left: 20px;
      }
    }
  }
}

@include breakpoint(large) {
  .inventory-details{
    &__cta-wrapper{
      @include grid-column-row(0);
    }
    &__ctas-instance {
      padding: 0 30px;
    }
    &__ctas-instance-image {
      width: 75px;
      height: 75px;
    }
    &__ctas-instance-info {
      @include font-size(20px);
      @include line-height(24px);
    }
    &__ctas-instance-anchor {
      font-size: 15px;
    }
    &__header-price-wrapper{
      width: 30%;
    }
    &__vehicle-info-wrapper{
      width: 30%;
      border-right: 1px solid $border-grey;
    }
    &__header-buttons-wrapper{
      width: 40%;
      @include padding(10px 0);
      min-height: 70px;
      text-align: left;
      float: right;
    }
    &__header-information{
      width: $global-width;
      padding: 20px 30px;
    }
    &__vehicle-info,
    &__header-actions,
    &__header-compare{
      display: inline-block;
    }
    &__header-compare{
      margin: 0 20px;
      .widget-checkbox-label {
        width: 120px;
      }
    }
    &__content{
      max-width: 597px;
      padding: 0;
      margin-left: auto;
      margin-right: 0;
      &-sidebar{
        max-width: 307px;
        margin-left: 0;
      }
    }
    &-content{
      padding: 0;
      max-width: none;
      @include clearfix();
      .page-main__content{
        width: 58.33333%;
        float: left;
        @include padding(80px 40px 0);
      }
      .page-sidebar__right{
        width: 41.66667%;
        position: absolute;
        right: 0;
        height: 100%;
        @include padding(80px 30px 65px);
        margin-right: 0;
      }
    }
    &__content-block{
      margin-bottom: 50px;
      width: 100%;
      float: left;
    }
    &__content-cta-pricecheck{
      margin-bottom: 10px;
    }
  }
}

@include breakpoint(xlarge) {
  .inventory-details{
    &__header-information{
      width: $global-width;
    }
    &__ctas-block{
      max-width: $global-width;
    }
    &__vehicle-info{
      padding-right:10px;
      padding-left:10px;
    }
    &__header-compare{
      .widget-checkbox-label {
        width: 135px;
      }
    }
  }
}

@include breakpoint(xlarge) {
  .inventory-details{
    &__ctas-block{
      max-width: $global-width;
    }
    &__header-compare{
      .widget-checkbox-label {
        width: 135px;
      }
    }
  }
}
