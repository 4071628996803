/*************************************************
            Block About-Us (Alpha)
*************************************************/
.block-about-alpha{
  &__logo{
    position: absolute;
    left: 50%;
    top: -42px;
    @include transform(translate(-50%, 0));
  }
  &__wrapper{
    @include clearfix();
    padding: 50px 10px;
  }
  &__title{
    @include grid-col(12);
    @include font-size(23px);
    @include line-height(28px);
    margin: 0 0 25px;
    text-align: center;
  }
  &__subtitle {
    display: block;
    @include font-size(14px);
    @include line-height(17px);
    font-weight: 700;
    text-align: center;
    margin-top: rem-calc(10px);
  }
  &__instances-wrapper {
    margin-bottom: rem-calc(25px);
  }
  &__instances-title{
    @include font-size(16px);
    @include line-height(20px);
    font-weight: 700;
  }
  &__instances-unit-separator{
    position: relative;
    width: 125px;
    height: 2px;
    margin-bottom: 15px;
  }
  //&__line{
  //  width: 230px;
  //  margin-left: 0;
  //  border-bottom: 2px solid;
  //}
  &__content{
    @include grid-col(12);
    .link__alpha{
      display: block;
      margin-top: 10px;
      text-decoration: none;
    }
  }
  &__picture{
    @include show-for(large);
    z-index: 1;
  }
}

/***********************************
  "Block About - Alpha"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/

@include breakpoint(tablet){
  .block-about-alpha {
    &__title {
      @include font-size(45px);
      @include line-height(50px);
    }
    &__subtitle {
      @include font-size(20px);
      @include line-height(24px);
      margin-top: rem-calc(15px);
    }
   &__instances-wrapper{
     display: flex;
     @include grid-col(12,0);
   }
    &__instances-content{
      @include grid-col(4);
    }
    &__content .link__alpha {
      margin: 0 auto;
      width: fit-content;
      clear: both;
    }
  }
}

@include breakpoint(large){
  .block-about-alpha{
    &__logo{
      @include transform(translate(-100px, 0));
    }
    &__wrapper{
      @include inline-block();
      padding: 0;
    }
    &__picture{
      @include transform(scale(0.8));
      position: absolute;
      bottom: 0;
      left: 45%;
    }
  }
}

@include breakpoint(xlarge){
  .block-about-alpha{
    &__picture{
      @include transform(scale(1));
    }
  }
}

/*************************************************
            Block About-Us (Beta)
*************************************************/

.block-about-beta{
  @include padding(50px 20px 80px);
  -webkit-background-size: cover;
  background-size: cover;
  &__wrapper{
    padding-top: rem-calc(20px);
    border-top: 3px solid;
  }
  &__title{
    @include font-size(43px);
    @include line-height(48px);
    margin: rem-calc(0 0 20px);
    padding: 0 !important;
  }
  h2{
    @include font-size(28px);
    @include line-height(30px);
    margin-bottom: rem-calc(20px);
  }
  h3{
    @include font-size(20px);
    margin-bottom: 0;
  }
  &__content .link__alpha{
    display: block;
  }
}

/***********************************
  "Block About - Beta"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/

@include breakpoint(medium){
  .block-about-beta{
    &__content .link__alpha{
      display: inline-block;
    }
    h2{
      @include font-size(36px);
      @include line-height(38px);
    }
  }
}

@include breakpoint(large) {
  .block-about-beta {
    @include padding(60px 100px 80px);
  }
}

/*************************************************
            Block About-Us (Charlie)
*************************************************/

.block-about-charlie{
  padding: 0;
  &__wrapper{
    @include clearfix();
    @include grid-row();
  }
  &__title{
    text-align: left;
    position: relative;
    margin: rem-calc(0 20px 40px);
  }
  &__maintitle{
    @include font-size(44px);
    @include line-height(48px);
    padding: 0 !important;
    margin: rem-calc(0 0 20px);
  }
  &__subtitle{
    @include font-size(24px);
    @include line-height(26px);
    padding: 0;
    margin: 0;
  }
  &__content{
    margin: rem-calc(0 20px);
    padding: rem-calc(0 20px);
    position: relative;
    .link__alpha{
      display: block;
    }
  }
  &__wysiwyg{
    position: relative;
    padding: rem-calc(40px 0);
    p{
      margin: rem-calc(0 0 20px);
    }
    h3{
      @include font-size(24px);
      @include line-height(26px);
      margin: rem-calc(0 0 20px);
    }
  }
  &__content-bg{
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
  }
}

/***********************************
  "Block About - Charlie"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/

@include breakpoint(medium) {
  .block-about-charlie{
    &__title{
      @include grid-column(12);
      margin: rem-calc(0 0 40px);
    }
    &__maintitle{
      @include font-size(64px);
      @include line-height(66px);
    }
    &__content{
      @include grid-column(12);
      margin: 0;
      .link__alpha{
        display: inline-block;
      }
    }
  }
}

@include breakpoint(large) {
  .block-about-charlie{
    padding: rem-calc(75px 0 235px);
    &__title{
      @include grid-column(6);
      text-align: right;
      margin: 0;
    }
    &__maintitle{
      padding: rem-calc(0 25px 0 0) !important;
    }
    &__subtitle{
      padding: rem-calc(0 25px 0 0);
    }
    &__content{
      @include grid-column(6);
      position: static;
    }
    &__wysiwyg{
      padding: rem-calc(0 0 0 25px);
    }
    &__content-bg{
      width: 50%;
    }
  }
}