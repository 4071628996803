/*****************************************************************
                            picto
*****************************************************************/
.picto {
  display: inline-block;
  font-family: $body-font-family;
  vertical-align: top;
  padding: 2px 8px;
  border-radius: 3px;
  font-size: 11px;
  color: $white;
  margin: 2px;
  &__certified, &__carfax{
    background-color: transparent;
  }
}

.picto-play{
  //position:relative;
  @include absolute-center;
  @include inline-block;
  width:54px;
  height:54px;
  @include border-radius(50%);
  background-color:$white;

  &:hover,&:focus,&:active{
    cursor:pointer;
    background-color: $medium-gray;
  }

  &:before{
    @include absolute-center;
    @include css-triangle(8px,$black,right);
  }
}

.ruban_sold{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 3;
}

.detail-sold-picto{
  width: 600px;
}