// ----------------------------------------------------------------------

// Mixins file

// ----------------------------------------------------------------------

@mixin box-shadow( $shadow1, $shadow2:false, $shadow3:false, $shadow4:false, $shadow5:false, $shadow6:false, $shadow7:false, $shadow8:false, $shadow9:false ) {
  $params: $shadow1;
  @if $shadow2 { $params: $shadow1, $shadow2; }
  @if $shadow3 != false { $params: $shadow1, $shadow2, $shadow3; }
  @if $shadow4 != false { $params: $shadow1, $shadow2, $shadow3, $shadow4; }
  @if $shadow5 != false { $params: $shadow1, $shadow2, $shadow3, $shadow4, $shadow5; }
  @if $shadow6 != false { $params: $shadow1, $shadow2, $shadow3, $shadow4, $shadow5, $shadow6; }
  @if $shadow7 != false { $params: $shadow1, $shadow2, $shadow3, $shadow4, $shadow5, $shadow6, $shadow7}
  @if $shadow8 != false { $params: $shadow1, $shadow2, $shadow3, $shadow4, $shadow5, $shadow6, $shadow7, $shadow8 }
  @if $shadow9 != false { $params: $shadow1, $shadow2, $shadow3, $shadow4, $shadow5, $shadow6, $shadow7, $shadow8, $shadow9 }

  box-shadow: $params;
  -moz-box-shadow: $params;
  -webkit-box-shadow: $params;
}



@mixin default-box-shadow {
  box-shadow: 0 2px 3.64px 0.36px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 0 2px 3.64px 0.36px rgba(0,0,0,0.11);
  -webkit-box-shadow: 0 2px 3.64px 0.36px rgba(0,0,0,0.11);
}

// Border radius shorthand
@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
}

// Single side border-radius

@mixin border-top-radius($radius) {
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
  background-clip: padding-box;
}
@mixin border-right-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
  background-clip: padding-box;
}
@mixin border-bottom-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
  background-clip: padding-box;
}
@mixin border-left-radius($radius) {
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
  background-clip: padding-box;
}

@mixin inline-block {
  display: -moz-inline-stack;	// ff 2
  display: inline-block;
  zoom:1; *display: inline; _height: 15px; // ie 6-7
}

// Padding shorthand version
@mixin padding($padding) {
  padding: ($padding);
}

// Padding longhand version
@mixin padding-all($top, $right, $bottom, $left) {
  padding-top: $top;
  padding-right: $right;
  padding-bottom: $bottom;
  padding-left: $left;
}

// Margin shorthand version
@mixin margin($margin) {
  margin: $margin;
}

// Margin longhand version
@mixin margin-all($top, $right, $bottom, $left) {
  margin-top: $top;
  margin-right: $right;
  margin-bottom: $bottom;
  margin-left: $left;
}

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}


@mixin line-height($size) {
  line-height: $size;
  line-height: calculateRem($size);
}


@mixin box-sizing($box-model:null) {
  @if ($box-model == null) {
    $box-model : border-box;
  }
  -webkit-box-sizing: $box-model; // Safari <= 5
  -moz-box-sizing: $box-model; // Firefox <= 19
  box-sizing: $box-model;
}

@mixin button-base($radius: "") {
  font-family: $body-font-family;
  @include disable-mouse-outline;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  -webkit-appearance: none;
  vertical-align: middle;
  @if $radius == "" {
    border-radius: $global-radius;
  } @else {
    border-radius: $radius;
  }

}

@mixin inline-block($position: "") {
  display: -moz-inline-stack;	// ff 2
  display: inline-block;
  zoom:1; *display: inline; _height: 15px; // ie 6-7
  @if $position == top {
    vertical-align: top;
  } @else if $position == bottom {
    vertical-align: bottom;
  } @else {
    vertical-align: middle;
  }
}

@mixin animation($animation){
  animation: $animation;
  -webkit-animation: $animation;
  -moz-animation: $animation;
  -o-animation: $animation;

}

@mixin transition($transtion){
  transition: $transtion;
  -webkit-transition: $transtion;
  -moz-transition: $transtion;
  -o-transition: $transtion;

}

@mixin transform($transform){
  transform: $transform;
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -o-transform: $transform;
}

@mixin transform-origin($transform-origin: center center) {
  -webkit-transform-origin: $transform-origin;
  -moz-transform-origin: $transform-origin;
  -ms-transform-origin: $transform-origin;
  -o-transform-origin: $transform-origin;
  transform-origin: $transform-origin;
}

@mixin arrow-right($border-width,$border-height, $color){
  width: 0;
  height: 0;
  border-style: solid;
  border-width: $border-height/2 0 $border-height/2 $border-width;
  border-color: transparent transparent transparent $color;
}

@mixin arrow-left($border-width,$border-height, $color){
  width: 0;
  height: 0;
  border-style: solid;
  border-width: $border-height/2 $border-width $border-height/2 0;
  border-color: transparent  $color transparent transparent;
}

@mixin arrow-top($border-width,$border-height, $color){
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 $border-width/2 $border-height $border-width/2;
  border-color: transparent transparent $color transparent;
}

@mixin arrow-bottom($border-width,$border-height, $color){
  width: 0;
  height: 0;
  border-style: solid;
  border-width: $border-height $border-width/2 0 $border-width/2;
  border-color: $color transparent transparent transparent;
}


// @param {color} $triangle-color - We have to declare it in theme.jsp file.
// Step: 1. Get the direction code selected.
//      Example: (border-color: transparent transparent $triangle-color;) for "UP" direction.
// Step: 2. Put it inside theme.jsp file.
// Step: 3. Change the variable ($triangle-color) by the wanted jsp variable.

@mixin css-triangle(
  $triangle-size,
  $triangle-color,
  $triangle-direction
) {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border: inset $triangle-size;

  @if ($triangle-direction == down) {
    border-color: $triangle-color transparent transparent;
    border-top-style: solid;
    border-bottom-width: 0;
  }
  @if ($triangle-direction == up) {
    border-color: transparent transparent $triangle-color;
    border-bottom-style: solid;
    border-top-width: 0;
  }
  @if ($triangle-direction == right) {
    border-color: transparent transparent transparent $triangle-color;
    border-left-style: solid;
    border-right-width: 0;
  }
  @if ($triangle-direction == left) {
    border-color: transparent $triangle-color transparent transparent;
    border-right-style: solid;
    border-left-width: 0;
  }
}

// Column MIXINS
@mixin columns-divider($colcount, $colgap, $columnRuleColor, $columnRuleStyle, $columnRuleWidth) {
  -moz-column-count: $colcount;
  -moz-column-gap: $colgap;
  -moz-column-rule-color: $columnRuleColor;
  -moz-column-rule-style: $columnRuleStyle;
  -webkit-column-count: $colcount;
  -webkit-column-gap: $colgap;
  -webkit-column-rule-color: $columnRuleColor;
  -webkit-column-rule-style: $columnRuleStyle;
  column-count: $colcount;
  column-gap: $colgap;
  column-rule-color: $columnRuleColor;
  column-rule-style: $columnRuleStyle;
}

// Makes a gradient over the multiple instances of the Body Shop's page instances list
@mixin gradient-over-bodyshop-instances($count, $startColor, $currentClassName) {
  @for $i from 0 through $count {
    $background-color: darken($startColor, $i * (15/$count));
    .#{$currentClassName}-#{$i} {
      background: $background-color;
      &:before {
        background-image: linear-gradient(to bottom left, transparent 48%, $background-color 50%);
      }
      &:after {
        background-image: linear-gradient(to top left, transparent 48%, $background-color 50%);
      }
    }
  }
}