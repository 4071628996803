.page-article-details{
  .title__primary{
    padding: 0;
  }
  .smallprint{
    @include font-size(11px);
  }
  .article-details{
    @include grid-column(12);
    padding-bottom: rem-calc(30px);
    &__image-container{
      @include margin(35px 0 20px 0);
      img{
        width:100%;
      }
      iframe{

      }
    }
    &__preview{
      font-family: $title-font-family;
      font-weight: 700;
      @include font-size(13px);
      margin-bottom:rem-calc(15px);
    }
    &__social-container{
      margin-bottom:rem-calc(28px);
      text-align:right;
    }
  }
  &__gallery-anchor{
    display: inline-block;
    .overlay-content{
      @include font-size(20px);
    }
  }
}

.youtube-player {
  position: relative;
  padding-bottom: 56.23%;
  /* Use 75% for 4:3 videos */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: $black;

  iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: transparent;
  }
  &__image{
    bottom: 0;
    display: block;
    left: 0;
    margin: auto;
    max-width: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    height: auto;
    cursor: pointer;
    @include transition(.4s all);
    &:hover{
      -webkit-filter: brightness(75%);
    }
  }
  &__play{
    @include absolute-center;
    font-size: 70px;
    cursor: pointer;
  }
}

/***********************************************************
           Breakpoints :
            - small: 0,               //Mobile
            - medium: 640px,          //Medium
            - tablet: 768px,          //Tablet
            - large: 1024px,          //Desktop
            - xlarge: 1200px,
            - xxlarge: 1920px,
************************************************************/

@include breakpoint(large){
  .article-listing__picture-container{
    padding-left:0;
    padding-right:0;
  }
}