/*****************************************************************
      BLOCK REVIEWS
*****************************************************************/

.home-reviews{
  @include grid-column-row();
  position: relative;
  .link__regular:hover{
    text-decoration: none;
  }
  &.home-reviews-echo{
    width: 100%;
    margin-top: rem-calc(30px);
    padding: 0;
  }
  &__background-image{
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &__wrapper{
    position: relative;
    padding: 10px 10px 40px;
  }
  &__title{
    @include font-size(28px);
    @include line-height(30px);
    margin-bottom: rem-calc(20px);
    text-transform: none;
  }
  &__header{
      @include padding(30px 10px);
  }
  &__all {
    @include clearfix();
  }
  &__all-score {
    @include inline-block;
    float: left;
  }
  &__all-score-rating{
    @include font-size(64px);
    @include line-height(64px);
  }
  &__all-score-empty{
    position: absolute;
    left: 0;
    top: 0;
  }
  &__all-score-full{
    @include clearfix();
    position: relative;
  }
  &__all-content{
    @include inline-block;
    float: left;
    margin: rem-calc(14px) 0 0 rem-calc(8px);
    @include clearfix();
  }
  &__all-content-rating {
    position: relative;
    @include font-size(16px);
    @include line-height(24px);
    min-width: 150px;
  }
  &__all-total{
    @include inline-block;
    @include font-size(14px);
    float: left;
  }
  &__all-link{
    @include font-size(14px);
    margin-left: 0;
  }
  &__main{
    @include padding(10px);
  }
  &__listing-body-container{
    position: relative;
    @include padding(15px 20px);
    margin-bottom: 30px;
    @include font-size(14px);
    line-height: 18px;

    &:after{
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-top-width: 20px;
      border-top-style: solid;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      position: absolute;
      left: 18px;
      top: 100%;
    }
  }
  &__listing-picture{
    @include grid-column(2);
    img{
      max-width: 65px;
      margin: 0 auto;
    }
  }
  &__listing-main{
    @include grid-column(10);
  }
  &__listing-author-container {
    @include clearfix();
  }
  &__listing-author {
    @include inline-block;
    @include font-size(16px);
    float: left;
  }
  &__listing-rating {
    display: block;
    position: relative;
    @include font-size(14px);
    @include line-height(22px);
  }
  &__listing-rating-empty{
    position: absolute;
    left: 0;
    top: 0;
  }
  &__listing-rating-full{
    @include clearfix();
    position: relative;
  }
}

.home-reviews-echo{
  .home-reviews__background-image{
    width: auto;
    left: -10px;
    right: -10px;
  }
  .home-reviews__wrapper{
    padding: rem-calc(0 0 20px);
  }
  .home-reviews__listing-body-container:after{
    background-color: transparent !important;
  }
}

/***********************************
  "Block Review Echo"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/

@include breakpoint(medium){
  .home-reviews-echo{
    padding: rem-calc(0 10px);
    .home-reviews__background-image{
      width: 100%;
      left: auto;
      right: 0;
    }
    .home-reviews__wrapper{
      padding: rem-calc(10px 10px 40px);
    }
  }
}

@include breakpoint(large){
  .home-reviews{
    @include grid-column(6);
    position: static;
    &.home-reviews-echo{
      margin: rem-calc(0);
      @include grid-column(6);
    }
    &__background-image{
      width: 45%;
    }
    &__wrapper{
      padding: 0 0 0 90px;
    }
  }
  .home-reviews-echo{
    .home-reviews__background-image{
      width: 45%;
    }
    .home-reviews__wrapper{
      padding: rem-calc(0 0 0 90px);
    }
  }
}

/*****************************************************************
      BLOCK REVIEWS - ALPHA
*****************************************************************/

.home-reviews-alpha{
  display: block;
  width: 100%;
  float: none;
  .home-reviews__wrapper{
    padding: 0;
  }
  .home-reviews__header{
    margin-bottom: 40px;
    padding: 0;
    text-align: center;
  }
  .home-reviews__title{
    @include font-size(25px);
    @include line-height(30px);
    margin: rem-calc(0 0 25px);
  }
  .home-reviews__all{
    display: none;
    //display: inline-block;
    width: auto;
    text-align: center;
    &-score{
      display: block;
      float: none;
    }
    &-score-rating{
      @include font-size(60px);
      @include line-height(60px);
    }
    &-content{
      margin: 0;
      @include clearfix();
    }
    &-content-rating{
      display: inline-block;
      @include font-size(18px);
      @include line-height(37px);
      min-width: 115px;
      .widget-rating__star{
        @include line-height(37px);
      }
    }
  }
  .home-reviews__all-link{
    display: block;
    float: none;
    @include font-size(14px);
    @include line-height(37px);
  }
  .home-reviews__main{
    @include grid-row();
    padding: 0;
  }
  .home-reviews__listing{
    @include clearfix();
    &-item{
      position: relative;
      @include box-shadow(0px 5px 30px 9px rgba(0, 0, 0, 0.05));
      @include clearfix();
      margin-bottom: 50px;
      &:first-child {
        margin-top: 30px;
      }
    }
    &-item-icon {
      position: absolute;
      top: -30px;
      left: 15px;
      z-index: 1;
    }
    &-picture{
      display: none;
      width: auto;
      max-width: 40px;
      @include border-radius(100%);
      position: absolute;
      left: 50%;
      top: -24px;
      @include transform(translate(-50%, 0));
      @include box-shadow(0px 0px 25px 0 rgba(0,0,0,.3));
      padding: 0;
      float: none;
      z-index: 2;
      img{
        width: 100%;
      }
    }
    &-main{
      display: block;
      width: 100%;
      float: none;
      padding: 0;
    }
    &-body-container{
      padding: rem-calc(20px 25px 8px);
      text-align: left;
      margin-bottom: 0;
      &:before{
        display: none;
        content: "";
        width: 51%;
        height: 50px;
        position: absolute;
        left: 0;
        bottom: 0;
        @include transform-origin(left bottom);
        @include transform(rotate(10deg));
      }
      &:after{
        display: none;
        width: 51%;
        height: 50px;
        left: auto;
        right: 0;
        top: auto;
        bottom: 0;
        border: 0;
        @include transform-origin(right bottom);
        @include transform(rotate(-10deg));
      }
    }
    &-preview{
      position: relative;
      margin-bottom: 15px;
      font-style: italic;
      z-index: 2;
    }
    &-rating {
      margin: 8px 0;
      display: inline-block;
    }
    &-author-container{
      text-align: left;
    }
    &-author{
      @include font-size(12px);
      float: none;
    }
  }
}

/***********************************
  "Block Review Alpha"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/
@include breakpoint(medium) {
  .home-reviews-alpha {
    .home-reviews__title{
      @include font-size(35px);
      @include line-height(40px);
    }
    .home-reviews__all{
      text-align: left;
      &-score{
        display: inline-block;
        float: left;
      }
      &-score-rating{
        @include font-size(35px);
        @include line-height(37px);
      }
      &-content{
        margin: rem-calc(0 0 0 8px);
      }
      &-content-rating{
        float: left;
        min-width: 150px;
      }
      &-link{
        display: inline-block;
        float: left;
      }
    }
    .home-reviews__listing {
      &-item {
        display: inline-block;
        width: calc(33.333333333333% - 20px);
        margin-bottom: 0;
        &:nth-child(even) {
          margin-left: 20.5px;
          margin-right: 20.5px;
        }
      }
      &-body-container{
        min-height: 200px;
      }
      &-author {
        position: absolute;
        bottom: 8px;
      }
    }
  }
}

/*****************************************************************
      BLOCK REVIEWS - BETA
*****************************************************************/

.home-reviews-beta{
  padding: 0 10px;
  &__section{
    background-size: cover;
    .page-wrapper{
      padding-top: rem-calc(30px);
      padding-bottom: rem-calc(30px);
    }
  }
  .home-reviews__wrapper{
    padding: 0;
  }
  .home-reviews__header{
    padding: 0;
  }
  .home-reviews__title{
    @include font-size(24px);
    @include line-height(26px);
    text-transform: none;
    margin: rem-calc(0 0 20px);
  }
  .home-reviews__all-score-rating{
    @include font-size(80px);
    @include line-height(65px);
  }
  .home-reviews__all-content-rating{
    @include font-size(18px);
    @include line-height(28px);
    min-width: 120px;
  }
  .home-reviews__main{
    padding: rem-calc(20px) 0 0;
  }
  .home-reviews__listing{
    padding: 0;
    &-body-container{
      background: transparent !important;
      padding: 0;
      margin-bottom: rem-calc(20px);
      @include font-size(14px);
      @include line-height(16px);
      &:after{
        display: none;
      }
    }
    &-preview:before,
    &-preview:after{
      content: "“";
      display: inline;
    }
    &-author{
      @include font-size(14px);
      @include line-height(16px);
      &:before{
        content: "- ";
        display: inline;
      }
    }
    &-rating{
      display: none;
    }
  }
}

/***********************************
  "Block Review Beta"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/

@include breakpoint(medium){
  .home-reviews-beta{
    @include grid-row-nest();
    .home-reviews__header,
    .home-reviews__main{
      @include grid-column(6);
    }
    .home-reviews__header{
      padding: 30px 10px;
    }
    .home-reviews__all-content-rating{
      @include font-size(25px);
      min-width: 160px;
    }
    .home-reviews__main{
      padding: rem-calc(20px 10px 10px);
    }
    .home-reviews__listing {
      @include padding(0 40px);
    }
  }
}

@include breakpoint(large){
  .home-reviews-beta {
    width: auto;
    @include grid-row-nest();
  }
}

/*****************************************************************
      BLOCK REVIEWS - CHARLIE
*****************************************************************/
.home-reviews-charlie{
  display: block;
  width: 100%;
  float: none;
  .home-reviews__wrapper{
    @include clearfix();
    @include grid-row();
    padding: 0;
  }
  .home-reviews__header{
    @include grid-column(12);
    text-align: center;
    padding-top: 0;
  }
  .home-reviews__subtitle{
    @include font-size(28px);
    @include line-height(30px);
    margin: 0;
  }
  .home-reviews__title{
    @include font-size(44px);
    @include line-height(46px);
  }
  .home-reviews__all{
    display: inline-block;
    &-score{
      display: block;
      float: none;
    }
    &-score-rating{
      @include font-size(32px);
      @include line-height(34px);
    }
    &-content{
      display: block;
      float: none;
      margin: rem-calc(0 0 0 8px);
      @include clearfix();
    }
    &-content-rating{
      display: inline-block;
      float: none;
      @include font-size(18px);
      @include line-height(34px);
      min-width: 115px;
      .widget-rating__star{
        @include line-height(34px);
      }
    }
  }
  .home-reviews__all-link{
    display: block;
    float: none;
    @include font-size(14px);
    @include line-height(34px);
  }
  .home-reviews__main{
    @include grid-column(12);
    padding-bottom: rem-calc(20px);
    .bx-wrapper{
      position: static;
    }
    .bx-wrapper .bx-pager{
      width: auto;
      margin: 0;
      padding: 0;
      left: 50%;
      bottom: 0;
      @include transform(translate(-50%, 0));
    }
    .bx-wrapper .bx-pager a{
      margin: 0 10px;
    }
  }
  .home-reviews__listing{
    &-item{
      @include clearfix();
    }
    &-iten-container{
      display: table;
      width: 100%;
    }
    &-picture{
      display: table-cell;
      width: 10%;
      padding: 0;
      float: none;
      vertical-align: top;
      img{
        max-width: 100%;
      }
    }
    &-main{
      display: table-cell;
      width: 90%;
      float: none !important;
      vertical-align: top;
      padding-top: 0;
    }
    &-author-container{
      margin-bottom: rem-calc(20px);
    }
    &-author{
      display: block;
      float: none;
      text-transform: uppercase;
    }
    &-rating{
      display: block;
      float: none;
      @include font-size(12px);
      @include line-height(17px);
      margin-top: 2px;
      margin-left: 0;
    }
    &-body-container{
      background: transparent !important;
      padding: rem-calc(0 0 0 0);
      margin: 0;
      &:before{
        @extend [data-theme-sprite];
        content: "\e936";
        position: absolute;
        left: -35px;
        top: 3px;
        @include font-size(16px);
        background-color: transparent !important;
      }
      &:after{
        display: none;
      }
    }
  }
}

/***********************************
  "Block Review Charlie"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/

@include breakpoint(medium){
  .home-reviews-charlie{
    .home-reviews__all-score,
    .home-reviews__all-content,
    .home-reviews__all-content-rating,
    .home-reviews__all-link,
    .home-reviews__listing-author,
    .home-reviews__listing-rating{
      display: inline-block;
      float: left;
    }
    .home-reviews__all-content-rating{
      min-width: 130px;
    }
    .home-reviews__listing-picture{
      width: 42.5%;
      vertical-align: middle;
    }
    .home-reviews__listing-main{
      width: 57.5%;
      vertical-align: middle;
      padding-top: rem-calc(15px);
    }
    .home-reviews__listing-rating{
      margin-left: 15px;
    }
    .home-reviews__listing-body-container{
      padding: rem-calc(0 0 0 45px);
      &:before{
        @include font-size(20px);
        left: 0;
      }
    }
  }
}

@include breakpoint(large){
  .home-reviews-charlie{
    .home-reviews__header{
      @include grid-column(5);
      text-align: left;
    }
    .home-reviews__all{
      display: block;
    }
    .home-reviews__main {
      @include grid-column(7);
      padding-bottom: rem-calc(10px);
    }
  }
}


/*****************************************************************
      BLOCK REVIEWS - DELTA
*****************************************************************/

.home-reviews-delta{
  @include grid-column(12);
  .home-reviews {
    &__wrapper {
      padding: 0;
    }
    &__title {
      @include font-size(43px);
      @include line-height(46px);
      text-transform: none;
      span {
        @include font-size(36px);
        @include line-height(38px);
      }
    }
    &__all-score-rating {
      @include font-size(60px);
      @include line-height(80px);
    }
    &__all-content {
      margin: rem-calc(25px 0 0 20px);
    }
    &__all-link {
      margin-top: rem-calc(5px);
    }
    &__listing-item {
      margin-bottom: rem-calc(20px);
      @include grid-row-nest();
      @include clearfix();
    }
    &__listing-picture {
      padding: 0;
      img {
        max-width: 100%;
      }
    }
    &__listing-main {
      @include grid-column(10);
    }
    &__listing-rating {
      display: block;
      float: none;
      margin-left: 0;
      margin-top: 2px;
    }
    &__listing-author {
      display: block;
      float: none;
      @include font-size(18px);
      &:hover {
        text-decoration: underline;
      }
    }
    &__listing-author-container {
      margin: rem-calc(8px 0 27px);
    }
    &__listing-body-container {
      margin: 0;
      &:after {
        border-top: 0;
        border-bottom: 20px solid;
        top: auto;
        bottom: 100%;
        background-color: transparent !important;
      }
    }
  }
}

/***********************************
  "Block Review Delta"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/

@include breakpoint(medium){
  .home-reviews-delta{
    .home-reviews__all-score-rating {
      @include font-size(80px);
    }
    .home-reviews__listing-picture{
      @include grid-column(2);
    }
    .home-reviews__listing-picture img{
      max-width: 65px;
    }
    .home-reviews__listing-rating {
      display: inline-block;
      float: left;
      margin-left: 15px;
    }
    .home-reviews__listing-author {
      display: inline-block;
      float: left;
    }
  }
}

@include breakpoint(large){
  .home-reviews-delta {
    @include grid-column(6);
  }
}
