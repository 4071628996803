/***********************************
    Instagram Feed Alpha
************************************/
$icon-heart-color: #fff;

.home-instagram-alpha{
  margin: rem-calc(0 0 20px);
  &__title{
    @include clearfix();
    margin: 0;
    @include font-size(20px);
    @include line-height(22px);
  }
  &__firstIcon,
  &__title-content,
  &__secondIcon{
    display: inline-block;
    float: left;
    margin-right: 10px;
  }
  &__secondIcon{
    margin-right: 0;
  }
  &__inner-wrapper{
    @include clearfix();
    .instafeed__link{
      display: inline-block;
      float: left;
      position: relative;
      width: calc(50% - 0.5px);
      margin: 0 0 1px 1px;
      &:nth-child(2n+1){
        margin-left: 0;
      }
      &:last-child{
        width: 100%;
        margin: 0;
      }
      &:before{
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        background: rgba(0, 0, 0, 0.3);
        opacity: 0;
        @include transition(all 0.3s ease);
        z-index: 1;
      }
      &:after{
        content: "\e937";
        @extend [data-theme-sprite];
        display: block;
        height: 20px;
        @include font-size(19px);
        color: $icon-heart-color;
        position: absolute;
        left: 50%;
        top: 50%;
        @include transform(translate(-50%, -50%));
        opacity: 0;
        @include transition(all 0.3s ease);
        z-index: 2;
      }
      &:hover:before,
      &:hover:after{
        opacity: 1;
      }
    }
    .instafeed__image{
      display: block;
      width: 100%;
      height: auto;
    }
  }
}

/***********************************
  "Instagram Feed - Alpha"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/
@include breakpoint(medium){
  .home-instagram-alpha{
    @include grid-column(6);
  }
}

@include breakpoint(large) {
  .home-instagram-alpha{
    display: block;
    width: 100%;
    float: none;
    padding: 0;
  }
}