.form__inner-wrapper{
  @include grid-column-size(11); /* == 520px*/
  margin: 0 auto;
  text-align: center;
  &--iframe{
    @include grid-column-size(100%);
  }
  .title__primary{
    text-align: center;
    margin-bottom: 15px;
  }
}

/*****************************************************************
                      Form Container
*****************************************************************/

.form-container{
  &__link-policy{
    display: table;
    width: 100%;
    @include font-size(10px);
    line-height: 12px;
    margin: 15px 0;
    text-align: left;

    a{
      text-decoration: underline;
      white-space: nowrap;
      &:hover{
        text-decoration: none;
      }
    }
  }
  &__optional-fields{
    margin: rem-calc(20px 0);
  }
  &__link-policy-ico{
    display: table-cell;
    vertical-align: middle;
    @include font-size(20px);
  }
  &__link-policy-text{
    display: table-cell;
    vertical-align: middle;
    padding-left: 5px;
  }
  &__fieldset-optional{
    display: none;
    margin-top: 12px;
  }
  .ui-state-error{
    color: $input-error-color;
    &:not(.form-message-wrapper){
      border: 1px solid $input-error-color;
    }
  }
}

.form-message-wrapper{
  margin-bottom: 5px;
  &--custom{
    padding-left:rem-calc(10px);
  }
}
/*****************************************************************
                      Form Field
*****************************************************************/
.form-field{
  &__label{
    margin-bottom:12px;
    position: relative;
    @include clearfix;
    &--last{
      margin:0;
    }
    &-subscription [type=checkbox]+label{
      @include font-size(12px);
    }
    input,
    .widget-dropdown__button{
      border:1px solid transparent;
      @include border-radius($global-radius);
    }
    &-select-contactLanguageId{
      display: none;
      visibility: hidden;
    }
  }

  &__label-checkbox{
    @include inline-block;
    @include clearfix;
    margin-top: 8px;
    &--wrapper{
      margin :0 0 10px;
    }
  }

  &__label-checkbox-input{
    margin-top: 5px;
    @include grid-col(1,0);
  }

  &__label-checkbox-text{
    @include font-size(16px);
    line-height: 18px;
  }

  &__label-file-resume{
    text-align: left;
    margin-bottom: 0;
  }
  &__label-file {
    display: none;
  }
  &__label-file-block{
    position: relative;
    padding: 10px 15px;
    @include border-radius($button-radius);
    &:not(.form-field__label-file-block--last):hover{
      opacity: 0.9;
      cursor: pointer;
    }
    &--last{
      display: none;
    }
  }
  &__label-file-block-text{
    @include font-size(14px);
    line-height: 18px;
  }
  &__label-file-block-filename{
    @include font-size(12px);
    line-height: 15px;
  }
  &__label-file-block-picto{
    @include vertical-center;
    right: 15px;
    @include font-size(18px);
    line-height: 20px;
    &:hover{
      opacity: 0.5;
      cursor: pointer;
    }
  }

  &__label-select{
    height: 50px !important;
    border-radius: 4px;
    border:0;
    @include font-size(14px);
    &--hidden{
      display:none;
    }
  }
  &__label--text {
    position: relative;
    transition: transform 0.5s;
    z-index: 1;
    top: 2px;
    width: 100%;
    pointer-events: none;
    text-align: left;
    &:before{
      position:absolute;
      top:15px;
      right:15px;
      font-size: 1.3rem;
      color:$gray;
    }
    &.active:before {
      content: attr(data-placeholder);
      position: absolute;
      top: 0;
      left:12px;
      @include font-size(11px);
      line-height: 20px;
      color: $dark-gray;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 230px;
    }
  }
}

/*****************************************************************
                        Form Sidebar
*****************************************************************/
.form-ninjabox,
.form-bottom{
  @include box-shadow(0px 2px 14px 0px rgba(0, 0, 0, 0.2));
  padding: 0;
  &__title{
    position: relative;
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(36px);
    line-height: 36px;
    @include padding(20px);
    &--border-bottom{
      border-bottom: 1px solid $border-color;
    }
  }
  &__title-cta{
    width: 100%;
    margin-top: rem-calc(20px);
  }
  &__content{
    @include padding(20px);
  }
  .submit-wrapper{
    @include padding(10px 0);
  }
  .form-container__link-policy-text{
    padding-left: 10px;
  }
  .form-field__label-checkbox--wrapper{
    margin: 0 0 10px 0;
  }
  .checkbox-label{
    color:$white;
  }
}
.form-ninjabox{
  .submit-wrapper{
    .submit{

      @include grid-column(12);
      float: none;
    }
  }

}



/*****************************************************************
                        Form Footer
*****************************************************************/
.form-footer{
  text-align: left;
  &__radio-label{
    margin-right: 40px;
  }
  input[type=radio]+label {
    margin-right: 40px;
  }
  &__content{
    @include grid-column-row(0);
    margin-top: 8px;
    .form-field__label{
      margin-bottom: 10px;
    }
    .form-field__label-checkbox{
      margin-bottom: 0;
    }
  }
  .form-container__content{
    @include grid-column(12,0);
    position: relative;
    padding: 15px 0 0;
  }
  .submit-wrapper{
    @include grid-column(4,0);
    text-align: right;
  }
  .submit{
    width: 100%;
  }
  .form-message-wrapper{
    position: absolute;
    top: 0;
  }
  .form-container__fieldset-required{
    margin-top: 5px;
  }
  .form-field__label{
    @include grid-column();
  }
  .form-field__label-checkbox{
    @include grid-column-row(0);
    text-align: left;
  }
  .form-container__link-policy{
    text-align: left;
    margin: 0;
    a{
      display: inline-block;
      white-space: nowrap;
    }
  }
  input[type=radio]+label {
    margin-right: 40px;
  }
  &__link-policy{
    display: table;
    text-align: left;
  }
}

@include breakpoint(medium) {
  .form-container__link-policy {
    text-align: center;
    &-ico, &-text {
      display: inline-block;
    }
  }
}

/*****************************************************************
  Form Bootom  -  2 columns (Services - Financing - details Promos)
*****************************************************************/

.bottom-page-form {
  .form-footer-2columns{
    @include grid-column(12,0);
    @include grid-column-position(center);
    text-align: center;
    &__title{
      @include font-size(36px);
      @include line-height(36px);
      padding: 20px 0 30px;
    }
  }
  .form-field__label-checkbox--wrapper{
    margin: 0px;
  }
  .form-field__label-subscription{
    margin-bottom: 12px;
    .checkbox-label {
      color: $white;
    }
  }
}

/*****************************************************************
          Form Footer -  2 columns (Inventory Listing)
*****************************************************************/
.form-footer-2columns{
  @include grid-column(12,0);
  text-align: center;
  .form-container__content{
    @include grid-column(12);
    @include padding(10px 0);
    position: relative;
  }
  .submit-wrapper{ 
    @include inline-block();
    @include grid-column-size(12);
    @include padding(10px 0);
    .submit{
      @include padding(15px 40px);
    }
  }
  .form-message-wrapper{
    position: absolute;
    top: -10px;
  }
  .form-container__fieldset-required{
    margin-top: 5px;
  }
  .form-container__fieldset-required-content{
    @include grid-column(12);
    margin: 0 auto 10px;
    display: inline-block;
  }
  .form__textarea-container{
    height: 100%;
    .form-field__label-input{
      height: 100%;
    }
  }
  .form-field__label-textarea-field{
    height: 100%;
    resize: none;
    &.field-travaux {
      height: auto;
    }
  }
  .form-field__label:last-child{
    margin: 0;
  }
  .form-field__label-checkbox{
    @include grid-column-row();
  }
  .form-container__link-policy{
    @include grid-column-row();
    display: table;

    &-text{
      @include font-size(10px);
      padding-left: 5px;
    }
    a{
      display: inline-block;
    }
  }
}

/*****************************************************************
                      Form Vehicle
*****************************************************************/
.form-vehicle{
  margin-bottom:rem-calc(20px);
  &__disclaimer{
    padding:10px;
  }
  &__image{
    @include inline-block;
    width:153px;
    vertical-align:top;
  }
  &__information{
    @include inline-block;
    padding: 0 10px;
    vertical-align:top;
  }
}


/*****************************************************************
                      Select Styles
*****************************************************************/
/******************* Remove default Style *******************/
select.custom-dropdown {
  -webkit-appearance: none;  /*REMOVES DEFAULT CHROME & SAFARI STYLE*/
  -moz-appearance: none;     /*REMOVES DEFAULT FIREFOX STYLE*/
  border: 0 !important;      /*REMOVES BORDER*/
}

/*****************************************************************
                  Input Types - Default State
*****************************************************************/
[type=text],[type=password],[type=date],[type=datetime],[type=datetime-local],[type=month],
[type=week],[type=email], [type=tel],[type=time],[type=url],[type=color],
[type=number],[type=search],textarea{
  margin:0;
  padding:18px 12px 11px;
  height:auto;
  @include font-size(14px);
  @include border-radius(2px);
  @include box-shadow( 0 0px 2px 0 rgba(0,0,0,1));
  border: none;
  background-color: $white;
}

[type=checkbox]+label, [type=radio]+label{
  @include font-size(16px);
  margin: 0;
}

.checkbox-container {
  @include inline-block;
  position:relative;
  text-align: left;
}

/*****************************************************************
                       checkbox
*****************************************************************/
/******************* Hide Checkbox *******************/
.site-container [type="checkbox"]:not(:checked),
.site-container [type="checkbox"]:checked,
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}

/******************* Prepare Label *******************/
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label{
  position: relative;       /* permet de positionner les pseudo-éléments */
  padding-left: 25px;       /* fait un peu d'espace pour notre case à venir */
  cursor: pointer;          /* affiche un curseur adapté */
  margin-left:0;
}

/******************* Checkbox Styles  :before sert à créer la case à cocher *******************/
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before,
.widget-checkbox__check:before{
  content: '';
  position: absolute;
  left:0; top: 0;
  width: 15px;
  height: 15px;
  border: 1px solid $medium-gray;
  background-color: $white;
  @include border-radius(3px);
}

/******************* Aspect générale de la coche *******************/
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after,
.widget-checkbox__check:after
{
  content: '';
  position: absolute;
  top: 2px;
  left: 5px;
  transition: all .2s;
  border-bottom: 2px solid;
  border-right: 2px solid;
  display: block;
  width: 5px;
  height: 8px;
  color:$black;
  @include transform(rotate(45deg));
}

/******************* Aspect si non coché *******************/
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"][data-checked="false"] + label:after,
.widget-checkbox__check:not(.checked):after,
.widget-checkbox__check.unchecked:after{
  opacity: 0;
}

/******************* If checked with JS *******************/
[type="checkbox"][data-checked="true"]:not(:checked) + label:after{
  opacity: 1;
}

/*****************************************************************
                       Radio Button
*****************************************************************/
/******************* Hide Checkbox *******************/
input[type="radio"]:not(:checked),
input[type="radio"]:checked {
  position: absolute;
  left: -9999px;
}

input[type="radio"] + label{
  margin-right: 15px;
  position: relative;
}

input[type="radio"] + label .radio-button__label-radio{
  width: 16px;
  height: 16px;
  margin-right: 5px;
  border: 2px solid;
  @include border-radius(50%);
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

input[type="radio"] + label .radio-button__label-radio:before{
  content: '';
  width: 8px;
  height: 8px;
  @include border-radius(50%);
  display: block;
  position: absolute;
  left: 2px;
  top: 2px;
  background: transparent;
}

textarea {
height:115px;
  resize:vertical;
}

.radio-button__label-text{
  @include inline-block();
  @include font-size(13px);
  line-height: 15px;
}

/*****************************************************************
                      DatePicker
*****************************************************************/
.ui-datepicker{
  margin:4px;
  background:$white;
}

.ui-datepicker-header{
  margin:4px;
}

.ui-datepicker-prev{
  float:left;

  &:hover,&:focus,&:active{
    text-decoration:underline;
  }
}

.ui-datepicker-next{
  float:right;

  &:hover,&:focus,&:active{
    text-decoration:underline;
  }
}

.ui-datepicker-title{
  font-family: $title-font-family;
  font-weight: 700;
  text-align:center;
}

.ui-datepicker-calendar{
  th, td{
    padding:9px;
  }
}

.ui-state-active{
/*  @include inline-block;
  @include absolute-center;
  border:1px solid $black;
  padding:2px 4px;
  border-radius: 50%;
  width:36px;
  height:36px;*/
  font-weight:bold;
}


/***********************************************************
           Breakpoints :
            - small: 0,               //Mobile
            - medium: 640px,
            - tablet: 768px           //Tablet
            - large: 1024px,          //Desktop
            - xlarge: 1200px,
            - xxlarge: 1920px,
************************************************************/

@include breakpoint(medium){
  .form-footer-2columns{
    .form-container__fieldset-required-content{
      @include grid-column(6);
    }
  }
  .form__inner-wrapper{
    @include grid-column-size(8);
  }
  .form-footer{
    .form-field__label{
      @include grid-column(1 of 3);
    }
  }
}

@include breakpoint(tablet){
  .form__inner-wrapper{
    @include grid-column-size(6);
  }
}