/*********************************************************
        Block CTAs Inventory Alpha
**********************************************************/

.block-ctas-inventory-alpha{
  @include padding(30px 0 20px);
  &__wrapper{
    @include grid-row();
    @include clearfix();
  }
  &__item{
    @include grid-column(4);
  }
  &__btn{
    display: block;
    @include border-radius(5px);
    @include box-shadow(0 3px 10px 0 rgba(0,0,0,.3));
    position: relative;
    overflow: hidden;
  }
  &__image{
    width: 100%;
  }
  &__overlay{
    display: none;
  }
  &__infos{
    position: absolute;
    width: 100%;
    left: 0;
    top: 50%;
    @include transform(translate(0, -50%));
    text-align: center;
  }
  &__title{
    @include font-size(18px);
    @include line-height(22px);
    margin-bottom: rem-calc(10px);
  }
  &__icon{
    display: inline-block;
    width: 24px;
    height: 24px;
    @include font-size(10px);
    line-height: 20px;
    @include border-radius(100%);
    border: 2px solid;
    position: relative;
    overflow: hidden;
    &:before{
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: -100%;
      top: 0;
      @include transition(all ease 0.3s);
    }
    &:after{
      content: "\e914";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      @include transition(all ease 0.3s);
    }
  }
  &__btn:hover .block-ctas-inventory-alpha__icon:before{
    left: 0;
  }
  &__btn:hover .block-ctas-inventory-alpha__icon:after{
    left: 100%;
  }
}

/***********************************
  "Block CTAs Inventory - Alpha"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/
@include breakpoint(large){
  .block-ctas-inventory-alpha{
    @include padding(30px 0 100px);
    &__title{
      @include font-size(30px);
      @include line-height(32px);
    }
  }
}

/*********************************************************
        Block CTAs Inventory Beta
**********************************************************/

.block-ctas-inventory-beta{
  @include grid-row();
  &__wrapper{
    padding: rem-calc(10px 0);
    @include box-shadow(0px 0px 55px 0 rgba(0,0,0,.3));
    @include clearfix();
  }
  &__item{
    @include grid-column(4);
  }
  &__btn{
    display: block;
    overflow: hidden;
    position: relative;
    &:hover .block-ctas-inventory-beta__overlay{
      right: 0;
    }
  }
  &__image{
    width: 100%;
  }
  &__overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    right: 57%;
    top: 0;
    @include transition(all 0.3s ease);
    img{
      height: 100%;
      max-width: none;
    }
  }
  &__infos{
    position: absolute;
    width: 100%;
    left: 0;
    top: 50%;
    @include transform(translate(0, -50%));
    padding-left: rem-calc(40px);
  }
  &__title{
    @include font-size(25px);
    @include line-height(27px);
    span{
      @include font-size(15px);
      @include line-height(17px);
    }
  }
}

/***********************************
  "Block CTAs Inventory - Beta"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/
@include breakpoint(large){
  .block-ctas-inventory-beta{
    @include grid-column(4);
    margin: 0;
    &__wrapper{
      padding: rem-calc(10px);
    }
    &__item{
      display: block;
      float: none;
      width: 100%;
      padding: 0;
    }
    &__btn {
      margin: rem-calc(10px 0 0);
    }
    &__item:first-child .block-ctas-inventory-beta__btn{
      margin: 0;
    }
  }
}