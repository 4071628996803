.inventory-list-layout{
  position: relative;
  text-align: left;
  padding-bottom: 20px;
  border: 1px solid $border-grey;

  @include box-shadow(none !important);
  &-wrapper{
    @include grid-column(12);
    min-width: 240px;
    margin-bottom: 20px;
    &--featured{
      @include grid-column(12);
    }
  }
  &__preview-image {
    position: relative;
    border-bottom: 0px;
    overflow: hidden;
    .inventory-list-layout__ruban-sold{
      width: 150%;
      position: absolute;
      left: 50%;
      top: 50%;
      text-align: center;
      @include font-size(36px);
      @include line-height(38px);
      text-transform: uppercase;
      @include padding(3px 0 6px);
      @include transform(rotate(-35deg) translate(-50%, -50%));
      @include transform-origin(top left);
    }
  }
  &__preview-clearance {
    position: absolute;
    left: 10px;
    bottom: 10px;
    @include border-radius(0 0 20px 0);
    z-index: 1;
  }
  &__preview-clearance-text{
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(12px);
    line-height: 20px;
    margin-left: 10px;
    margin-right: 20px;
  }
  &__preview-price-label{
    font-family: $body-font-family;
    display: block;
    font-weight: normal;
  }
  &__preview-price {
    padding: 0 0 5px;
  }
  &__preview-price-current {
    @include inline-block(top);
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(18px);
    line-height: 18px;
  }
  &__preview-price-strike {
    display: block;
    vertical-align: top;
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(14px);
    line-height: 14px;
    margin-top: 5px;
  }
  &__preview-price-value{
    position: relative;
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      top: 7px;
      right: 0;
      @include transform(rotate(10deg));
    }
  }
  &__preview-specs {
    @include padding(15px 0);
    text-align: center;
    border-bottom: 0px;
    .block__separator{
      border-color: $border-grey;
    }
  }
  &__preview-name{
    display: block;
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(14px);
    line-height: 17px;
    margin-bottom: 7px;
    @include padding(0 20px);
  }
  &__preview-tagline{
    @include font-size(11px);
    line-height: 14px;
    margin-bottom: 10px;
    padding: 5px 0;
  }
  &__preview-transmission, &__preview-km{
    @include font-size(12px);
    line-height: 20px;
    padding: 5px 0;
    display: inline-block;
    .icon{
      padding-right: 5px;
    }
  }
  &__preview-transmission{
    margin-right: 15px;
    .icon{
      font-size: 14px;
    }
  }
  &__preview-km{
    .icon{
      position: relative;
    }
  }
  &__preview-pictos {
    margin-top: 8px;
    img{
      margin: 0 auto;
      display: inline-block;
      vertical-align: middle;
      &.picto__certified{
        padding-right: 5px;
      }
    }
  }
  &__preview-actions{
    padding: 0 20px;
    text-align: center;
  }
  &__preview-actions-anchor{
    display: block;
    padding-left: 15px;
    padding-right: 15px;
  }
  &__preview-actions-compare{
    display: block;
    margin-top: 10px;
  }
}

/*********************************************
          Page Inventory Listing
*********************************************/
.inventory-listing{
  &__top{
    display: initial;
    @include clearfix();
    .page-wrapper{
      padding-bottom: 0;
    }
  }
  &__title{
    text-align:left;
    @include grid-col(12);
  }
  &__title-sub{
    margin-left: 10px;
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(22px);
  }
  &__show-filters{
    @include hide-for(tablet);
    position: relative;
    display: block;
    padding: 15px 0;
    &::before{
      content: '+';
      @include vertical-center;
      right: 10px;
      @include font-size(20px);
      line-height: 20px;
    }
    &.active::before{
      content: '-';
    }
  }
  &__filters-content{
    margin: 20px 0;
  }
  &__slider-sidebar{
    @include show-for-only(small);
  }
  &__promo{
    @include grid-column-row(12);
    margin-bottom: rem-calc(40px);
    .promotion__header{
      @include grid-column-row(12);
    }
  }
  &__text-seo{
    @include grid-column-position(center);
    padding: rem-calc(40px);
    margin: rem-calc(70px 50px 50px);
    background-color: $background-color-seo;
  }
  &__results{
    @include grid-column-row(0);
    @include font-size(14px);
    padding-bottom: rem-calc(12px);
    padding-left: 10px;
  }
  &__results-info-total{
    font-family: $title-font-family;
    font-weight: 700;
    &:hover{
      text-decoration: underline;
    }
  }
  &__results-pagination{
    @include inline-block;
    width: 100%;
    text-align: right;
    padding-top: rem-calc(20px);
  }
  &__reset-url{
    position: absolute;
    top: -20px;
    @include font-size(11px);
    line-height: 11px;
    span:first-child{
      font-family: $title-font-family;
      font-weight: 700;
      @include font-size(12px);
      margin-right: rem-calc(5px);
    }
    span:last-child{
      text-decoration: underline;
    }
  }
  &__vehicles{
    @include grid-row-nest();
  }
  &__filter-wrapper{
    //@include grid-col(12);
    //@include grid-row-nest();
    margin: rem-calc(40px 0 15px);
  }
  &__filter{
    display: table;
    width: 100%;
    @include padding(20px 15px);
    @include clearfix();
  }
  &__search{
    position: relative;
    width: 100%;
    float: none;
  }
  &__search-block{
    border-top: 1px solid $border-grey;
    border-bottom: 1px solid darken($border-grey, 10%);
    .page-wrapper{
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  &__results-info,&__option-filter-alignright {
    display:inline-block;
  }
  &__option-filter-alignright{
    display: block;
    float: none;
  }
  &__results-info{
    display: block;
    float: none;
    margin-left: 0;
    @include font-size(14px);
  }
  &__options{
    @include grid-column-row(0);
    vertical-align: middle;
    float: right;
    margin-bottom: 10px;
    @include font-size(14px);
    text-align: left;
    .widget-dropdown{
      background: transparent;
    }
    .widget-dropdown__button{
      font-family: $title-font-family;
      font-weight: 700;
      @include font-size(14px);
      line-height: 14px;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid;
      text-align: center;
      padding: 0;
      @include box-shadow(none);
    }
    .widget-dropdown__content{
      @include border-radius(5px);
      overflow-y: visible;
      max-height: inherit;
      text-transform: uppercase;
      margin-top: 10px;
      min-width: 150px;
      @include horizontal-center();
      &:before{
        content:'';
        position: absolute;
        bottom: 100%;
        left: 0;
        right: 0;
        margin: 0 auto;
        @include arrow-top(28px, 9px, $medium-gray);
      }
      &:after{
        content:'';
        position: absolute;
        bottom: 100%;
        left: 0;
        right: 0;
        margin: 0 auto;
        @include arrow-top(26px, 8px, #fff);
      }
    }
    .widget-dropdown__content-element{
      border-bottom: none;
      text-align: center;
    }
  }
  &__options-text{
    @include font-size(14px);
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
  &__options-text:last-child{
    margin-right: 0;
  }
  &__options-element{
    display: inline-block;
    .widget-dropdown{
      width: auto;
      min-width: auto;
      margin-right: 5px;
      &:after{
        border: none;
      }
    }
  }
  &__filters{
    &-top{
      @include show-for(medium);
      border-bottom: 1px solid $border-grey;
      .page-wrapper{
        padding-top: 0;
        padding-bottom: 0;
        overflow: visible;
      }
      .widget-dropdown{
        position: static;
        background-color: transparent;
      }
      .widget-dropdown__button{
        @include font-size(13px);
        border: 0;
        @include box-shadow(none);
      }
      .widget-dropdown__select-arrow{
        top: 60%;
      }
      .widget-dropdown__content{
        width: calc(100% + 2px);
        left: -1px;
        @include font-size(13px);
        @include box-shadow(0 5px 7px 0 rgba(50,50,50,.2));
        border-color: $border-grey;
      }
      .inventory-list__filters{
        @include clearfix();
        &-left,
        &-right{
          display: inline-block;
          width: 60%;
          float: left;
          @include clearfix();
        }
        &-right{
          float: right;
          width: 40%;
        }
        .nav-block{
          display: inline-block;
          width: 50%;
          float: left;
          @include padding(0 24px 0 0);
          margin: 0;
          border: 0;
          &__navigation-title{
            padding-top: rem-calc(5px);
          }
        }
        &-element{
          position: relative;
          display: inline-block;
          float: left;
          width: 50%;
          @include padding(4px 5px 11px);
          border-right: 1px solid $border-grey;
          &:first-child{
            border-left: 1px solid $border-grey;
          }
        }
        [data-theme-sprite~=arrow-down]{
          border-bottom-color: $gray;
        }
      }
      &--new{
        .inventory-list__filters .nav-block{
          width: 100%;
        }
      }
    }
    .widget-filter__slide-wrapper{
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      margin-bottom: rem-calc(30px);
      float: none;
      &.ui-slider-handle{
        margin-left: -20px;
      }
    }
    .widget-filter__slide-max{
      right: 0;
    }
    .page-sidebar__left{
      position: relative;
      border: 1px solid $border-grey;
      @include border-radius(15px);
      margin: 40px 0 20px;
    }
    .page-content__right{
      margin-top: 15px;
    }
  }
  &__content{
    .page-wrapper{
      padding-top: 0;
    }
  }
  &__form-top-section{
    min-height: 430px;
    padding-top: 0;
  }
  &__form{
    min-height: 950px;
    background-color: $black;
    border-top: 5px solid;
    background-size: cover;
    clear: both;
    .page-wrapper{
      padding-top: 65px;
      padding-bottom: 65px;
      overflow: visible;
    }
    .title__primary{
      @include font-size(36px);
    }
    .form-container__link-policy{
      text-align: center;
    }
    .form-container__link-policy-text{
      vertical-align: sub;
    }
    .big-block{
    }
  }
  &__html-seo{
    @include columns-divider(2,50px, transparent, none,0);
    .title{
      font-size: 24px;
      line-height: 28px;
    }
    p{
      @include font-size(14px);
      margin: rem-calc(0 0 15px);
    }
  }
  &__disclaimer{
    @include inline-block();
    width: 100%;
    margin: rem-calc(30px 0 20px);
  }
}

/***********************************************************
           Breakpoints :
            - small: 0,               //Mobile
            - medium: 640px,          //Medium
            - tablet: 768px,          //Tablet
            - large: 1024px,          //Desktop
            - xlarge: 1200px,
            - xxlarge: 1920px,
************************************************************/

@include breakpoint(medium){
  .inventory-list-layout{
    &-wrapper{
      @include grid-column(6);
      &--featured{
        @include grid-column(6);
        @include grid-column-end;
      }
    }
  }
}

@include breakpoint(tablet){
  .inventory-list-layout {
    &-wrapper {
      @include grid-column(6);
      @include grid-column-end;
      &--featured {
        @include grid-column(3);
        @include grid-column-end;
      }
    }
  }
  .inventory-listing__options{
    text-align: right;
  }
  .inventory-listing__results-info{
    @include inline-block();
    float: left;
    margin-left: -5px;
  }
  inventory-listing__option-filter-alignright{
    @include inline-block();
    float: right;
  }
  .inventory-listing__filters{
    .page-sidebar__left{
      border: none;
      @include border-radius(0);
    }
  }
  .inventory-listing__filters-content{
    display: block !important;
  }
}

@include breakpoint(large) {
  .inventory-list-layout {
    &-wrapper {
      @include grid-column(4);
      @include grid-column-end;
      &--featured {
        @include grid-column(3);
        @include grid-column-end;
      }
    }
  }
  .inventory-listing__filters-top {
    .inventory-list__filters {
      .nav-block__navigation-title {
        padding-top: rem-calc(10px);
      }
      &-left,
      &-right{
        width: 50%;
      }
      &-element {
        padding: rem-calc(8px 20px 17px);
      }
    }
    .widget-dropdown__button{
      @include font-size(16px);
    }
    .widget-dropdown__content{
      @include font-size(16px);
    }
  }
  .inventory-listing__form-top-section{
    margin: 0 auto;
  }
}
