/******************************************************
      Ninjabox - popup
*******************************************************/
[data-ninjabox]{
  >div{z-index:10000;}

  [data-ninjabox-background]{
    width:100%;
    height:100%;
    position:fixed;
    top:0;
    left:0;
    background-color: $black;
    cursor: pointer;
  }
  [data-ninjabox-mainbox]{
    max-width: 940px;
    max-height: 100%;
    [data-ninjabox-content]{
      background-color: #eaeaea;
      float:none;
      clear:both;
    }
    [data-ninjabox-closeButton]{
      position: relative;
      float: right;
      width: 30px;
      height: 30px;
      right: 0;
      top: -10px;
      border-radius: 50%;
      background-color: #606060;
      z-index: 2;
      text-align: center;
      padding: 7px;
      padding-left: 8px;
      color: $white;
      cursor: pointer;
      font-family: Arial, Helvetica, sans-serif;
      i{
        @include absolute-center();
        color: $white;
      }
    }
    .ninjabox-pricecheck-form{
      max-width: 600px;

      .form-ninjabox{


        &__title{
          padding: 40px 40px;
          text-transform: none;
        }
        &__content{
          padding:40px !important;
        }
      }
      .form-container__link-policy{
        position: relative;
        &-ico{
          position: absolute;
          left: 0;
          top: 0;
        }
        &-text{
          padding-left: 22px;
        }
      }
    }
  }
}

.widget-ninjabox{
  &__container{
    position: relative;
    text-align: center;
    max-height: 705px;
    max-width: 740px;
    overflow: hidden;
  }
  &__bxslider-count{
    @include horizontal-center;
    top: 0;
  }
  &__bxslider-count-box{
    font-family: $title-font-family;
    font-weight: 700;
    display: block;
    width: 60px;
    background-color: $black;
    color: $white;
  }
  &__bxslider-controls{
    &--prev, &--next{
      @include vertical-center;
    }
    &--prev{left: 10px;}
    &--next{right: 10px;}
  }
  &__bottom{
    @include box-sizing();
    @include padding(10px 15px);
    position: relative;
    display: table;
    width: 100%;
    max-height: 200px;
    .form-container {
      display: table-cell;
      vertical-align: top;
      position: relative;
      height: 65px;
      width: 100%;
      .form-message-wrapper{
        position: absolute;
        left: 10px;
        top: -20px;
      }
    }
  }
}

/***********************************************************
           Breakpoints :
            - small: 0,               //Mobile
            - medium: 768px,          //Tablet
            - large: 1024px,          //Desktop
            - xlarge: 1200px,
            - xxlarge: 1920px,
************************************************************/
@include breakpoint(large) {
  .widget-ninjabox {
    &__container {
      max-width: 790px;
    }
  }
}