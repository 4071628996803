.why-buy-header{
  &__content{
    text-align: center;
  }
  &__title{
    @include font-size(44px);
    @include line-height(48px);
    text-align: center;
    margin-bottom: rem-calc(10px);
  }
  &__text{
    @include font-size(24px);
    @include line-height(26px);
  }
  &__cta{
    margin-top: rem-calc(20px);
  }
}

.why-buy-steps{
  text-align: center;
  &__title{
    @include font-size(30px);
    @include line-height(34px);
    text-align: center;
    margin: rem-calc(0 0 40px);
  }
  &__main-wrapper{
    @include flex();
    @include flex-align('spaced', 'top');
  }
  &__step{
    width: 130px;
    text-align: left;
  }
  &__step-image{
    margin: rem-calc(0 0 20px);
  }
  &__step-title{
    @include font-size(20px);
    @include line-height(24px);
    margin: rem-calc(0 0 6px);
  }
  &__step-html{
    @include font-size(16px);
    @include line-height(20px);
    font-style: italic;
  }
}

.why-buy-content{
  position: relative;
  background-size: cover;
  overflow: hidden;
  .page-wrapper{
    position: static;
  }
  &__wrapper{
    @include grid-row();
    @include clearfix();
  }
  &__main{
    @include grid-column(12);
    position: relative;
    z-index: 2;
  }
  &__image{
    margin-top: rem-calc(50px);
  }
  &__title{
    @include font-size(44px);
    @include line-height(46px);
    margin: rem-calc(20px 0 40px);
  }
  &__text{
    @include font-size(16px);
    @include line-height(20px);
    ul{
      margin: 0;
      padding: 0;
    }
    li{
      padding: rem-calc(0 0 0 35px);
      margin: rem-calc(0 0 15px);
      position: relative;
      &:before{
        @extend [data-theme-sprite];
        content: "\e906";
        @include font-size(20px);
        @include line-height(20px);
        position: absolute;
        left: 0;
        top: 4px;
      }
    }
  }
  &__cta{
    margin: rem-calc(0 0 0 35px);
  }
}

.why-buy-banner{
  &__main{
    display: table;
    width: 100%;
  }
  &__text{
    display: table-cell;
    vertical-align: middle;
    @include font-size(18px);
    @include line-height(22px);
  }
  &__cta-wrapper{
    display: table-cell;
    vertical-align: middle;
    text-align: right;
  }
}

/***********************************
  "Block Review Beta"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/

@include breakpoint(large) {
  .why-buy-content{
    &__main{
      @include grid-column(5);
    }
    &__image{
      position: absolute;
      max-width: 55%;
      z-index: 1;
      right: 0;
      top: 50%;
      @include transform(translate(0, -50%));
    }
    &--even{
      .why-buy-content__main{
        @include grid-column-offset(7);
      }
      .why-buy-content__image{
        right: auto;
        left: 0;
      }
    }
  }
}