body{
  font-family: $body-font-family;
  @include font-size(16px);
  line-height: $global-lineheight!important;
}

p{
  margin: 0;
  padding: 0;
  line-height: inherit;
}
li{
  list-style-type: none;
}

sup{
  top: 0;
}

.footer{
  &__title{
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(12px);
    @include line-height(24px);
  }

  &__list-element-anchor{
    @include font-size(14px);
    @include line-height(28px);
  }

  &__social-link{
    @include font-size(30px);
  }

  &__bottomblock-anchor{
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(12px);
  }

  &__bottomblock-content{
    @include font-size(12px);
    @include line-height(13px);

    a{
      text-decoration: none;
      &:hover, &:focus, &:active{
        text-decoration: underline;
      }
    }
  }
}

/**************************************************
                        Title
***************************************************/
.title{
  &__primary, h1{
    font-family: $title-bolder-font-family;
    font-weight: 900;
    @include font-size(32px);
    @include line-height(34px);
    clear: both;
  }
  &__secondary, h2{
    font-family: $title-bolder-font-family;
    font-weight: 900;
    @include font-size(25px);
    @include line-height(27px);
  }
  &__tertiary, h3{
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(18px);
    @include line-height(20px);
    margin: 10px 0;
  }
  &__quaternary, h4{
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(16px);
    @include line-height(20px);
    margin: 10px 0 10px;
  }
  &__quinary, h5{
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(14px);
    @include line-height(18px);
  }
  &__subtitle{
    display: block;
    font-family: $body-font-family;
    @include font-size(18px);
    @include line-height(22px);
    margin: 10px 0;
  }
  &__home{
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(70px);
    @include line-height(70px);
    margin-bottom: rem-calc(25px);
    text-transform: uppercase;
  }
  /*&__why-buy{
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(50px);
    line-height: 55px;
  }*/
}

.label__checkbox,
[type=checkbox]+label.label__checkbox{
  @include font-size(11px);
  @include line-height(22px);
  &.active{
    font-weight: bold;
  }
}

.form__label,label{
  @include font-size(14px);
}

.showroom-rebate__wrapper{
  @include font-size(13px);
  @include line-height(22px);
  position: absolute;
}
.vehicle__tagline{
    @include font-size(13px);
    @include line-height(22px);
}
.vehicle__rebate-old-price{
    @include font-size(13px);
    @include line-height(22px);
}
.vehicle__rebate{
  @include font-size(15px);
  @include line-height(22px);
}
.vehicle__rebate-old-price{
  @include font-size(13px);
  @include line-height(22px);
}

.breadcrumb{
  @include font-size(12px);
  @include grid-col(12);
  @include clearfix();
  a:hover{
    text-decoration: underline;
  }
}

.vehicle__result{
  font-family: $title-font-family;
  font-weight: 700;
  @include font-size(11px);
}

.smallprint{
  @include font-size(11px);
  @include line-height(13px);
  a{
    text-decoration: underline;
    &:hover{
      text-decoration: none;
    }
  }
}

.wysiwyg{
  @include font-size(16px);
  line-height: 24px;

  a:not(.link__alpha,.link__beta){
    text-decoration: underline;
    &:hover{
      text-decoration: none;
    }
  }
  h1{
    font-family: $body-font-family;
    @include font-size(30px);
    @include line-height(30px);
    margin: 10px 0 40px;
    padding: 0;
  }
  h2{
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(28px);
    @include line-height(30px);
    margin: 10px 0 15px;
  }
  h3{
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(18px);
    line-height: 20px;
    margin: 10px 0;
  }
  h4{
    @include font-size(14px);
    @include line-height(18px);
    margin: 10px 0 10px;
  }
  h5{
    @include font-size(14px);
    @include line-height(18px);
    margin: 10px 0 ;
  }
  p{
    @include font-size(14px);
    line-height: 20px;
    margin-bottom: 1rem;
  }
  li{
    list-style-type: disc;
  }
}

.text-uppercase{
  text-transform: uppercase;
}

.text__bold{
  font-weight: bold;
}

.review__author{
  @include font-size(16px);
  @include line-height(20px);
  font-style: italic;
}

.review__title{
  @include font-size(16px);
  @include line-height(20px);
}

a.review__title,
a.review__author{
  &:hover,&:focus,&active{
    text-decoration:underline;
  }
}


/***********************************************************
           Breakpoints :
            - small: 0,               //Mobile
            - medium: 640px,
            - tablet: 768px           //Tablet
            - large: 1024px,          //Desktop
            - xlarge: 1200px,
            - xxlarge: 1920px,
************************************************************/

@include breakpoint(medium){
  .title{
    &__primary, h1{
      @include font-size(36px);
      @include line-height(38px);
    }
    &__secondary, h2{
      @include font-size(32px);
      @include line-height(30px);
    }
    &__tertiary, h3{
      @include font-size(20px);
      line-height: 22px;
    }
    &__quaternary, h4{
      @include font-size(16px);
      @include line-height(20px);
    }
    &__quinary, h5{
      @include font-size(14px);
      @include line-height(18px);
    }
    &__subtitle{
      @include font-size(18px);
      @include line-height(22px);
    }
    &__home{
      @include font-size(70px);
      @include line-height(70px);
    }
  }

  .wysiwyg{
    @include font-size(16px);
    line-height: 24px;

    h1{
      @include font-size(44px);
      @include line-height(44px);
    }
    h2{
      @include font-size(30px);
      @include line-height(32px);
    }
    h3{
      @include font-size(20px);
      line-height: 22px;
    }
    h4{
      @include font-size(14px);
      @include line-height(18px);
    }
    h5{
      @include font-size(14px);
      @include line-height(18px);
    }
    p{
      @include font-size(16px);
      line-height: 24px;
    }
  }
}