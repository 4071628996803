.promotion-details{
  @include grid-column-row;
  &__description,
  &__image-container{
    display: block;
    @include grid-column(12,0);
  }
  &__image-container{
    margin-bottom: 20px;
  }
  &__small-print-container {
    margin: rem-calc(20px 0);
  }
  &__vehicle-image {
    display: inline-block;
    .overlay {
      display: inline-block;
    }
    img {
      display: inline-block;
      height: 70px;
      width: auto;
      margin: 0;
    }
  }
  &__vehicle{
    display:flex;
    flex-direction: row;
    align-items: center;
  }
  &__vehicle-actions {
    display: inline-block;
    margin-left: 20px;
  }
  &__cta-container{
    text-align: center;
  }
  &__cta{
    margin-bottom: 20px;
  }
  &__form{
    background-size: cover;
  }
}

/***********************************************************
           Breakpoints :
            - small: 0,               //Mobile
            - medium: 640px,
            - tablet: 768px           //Tablet
            - large: 1024px,          //Desktop
            - xlarge: 1200px,
            - xxlarge: 1920px,
************************************************************/

@include breakpoint(medium){
  .promotion-details{
    &__description,
    &__image-container{
      @include inline-block();
      @include grid-column(6);
    }
    &__image-container{
      margin: 0;
    }
    &__cta-container{
      text-align: left;
    }
  }
}