.page-sidebar{
  &__right-element {
    margin-bottom: 30px;
    @include clearfix();
  }
  &__general-phone{
    @include font-size(20px);
    line-height: 36px;
  }
}

/***********************************
    Sidebar Article
***********************************/
.sidebar-article{
  &__preview{}
  &__description{
    padding:10px;
    @include clearfix();
  }
  &__title{
    @include grid-col(100%,0);
  }
}

/***********************************
    Sidebar Catalog
***********************************/
.sidebar-catalog{
  &__preview{}
  &__preview-picture-wrapper{
    width:280px;
    overflow:hidden;
  }
}

/***********************************
    Sidebar PROMOTION
***********************************/
.sidebar-promotion{
  @include inline-block;
  margin-bottom:rem-calc(4px);
  &__picture{
    margin-top:rem-calc(-20px);
    width:100%;
  }
}
