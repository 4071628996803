.article-listing{

  &__article-item{
    display: table;
    width: 100%;
    padding: 10px 0;
    margin-bottom: 15px;

    &--first-article{
      margin-top:rem-calc(9px);
      .article-listing__body-container{
        @include grid-column(12);
      }

      .article-listing__picture-container{
        @include grid-column(12);
      }
    }
    .smallprint{
      @include font-size(11px);
    }
    .title__quaternary{
      margin-top: 0;
    }
    .wysiwyg,
    .link__regular{
      @include font-size(16px);
    }
    .link__regular{
      display: block;
    }
  }

  &__body-container{
    display: table-cell;
    vertical-align: top;
    padding-left:rem-calc(12px);
    @include grid-column(6);
  }

  &__body-no-picture{
    @include grid-column(12);
  }

  &__picture-container, &__video-container{
    display: table-cell;
    vertical-align: top;
    @include grid-column(6);
  }
}

.page-article{
  .separator{
    @include margin(40px 0);
  }
}

/***********************************************************
           Breakpoints :
            - small: 0,               //Mobile
            - medium: 640px,          //Medium
            - tablet: 768px,          //Tablet
            - large: 1024px,          //Desktop
            - xlarge: 1200px,
            - xxlarge: 1920px,
************************************************************/

@include breakpoint(medium){
  .article-listing{
    &__article-item{
      &--first-article{
        .article-listing__body-container{
          @include grid-column(4);
        }
        .article-listing__picture-container{
          @include grid-column(8);
        }
      }
    }
    &__body-container{
      @include grid-column(9);
    }
    &__picture-container, &__video-container{
      @include grid-column(3);
    }
  }
}

@include breakpoint(large){

  .article-listing{
    &__body-container{
      @include grid-column(10);
    }
    &__picture-container, &__video-container{
      @include grid-column(2);
    }
  }
}
