/****************************************
    Layout Specific Homepage
****************************************/

.site-container{
  overflow: hidden;
}

.footer, .footer-bottom {
  .page-wrapper{
    position: relative;
    @include grid-column-row(0);
  }
}

/****************************************
    Layouts
****************************************/
.page {
  padding-top: 90px; // Offset space not taken by menu when in fixed position
}

.page-wrapper{
  @include grid-column-row($grid-column-gutter);
  max-width: $global-width;
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;

  &:last-child:not(:first-child){
    float: none;
  }
  &--nest{
    //@include grid-row-nest($grid-column-gutter);
  }
  &__side-padding{
    //padding-left: $grid-column-gutter;
    //padding-right: $grid-column-gutter;
  }
  &__big-padding-top{
    padding-top: 200px;
  }
}

.page-main{
  &__content{
    position: relative;
    @include grid-column(12);
    .breadcrumb{
      padding: 0;
    }
  }
}

.page-static-2columns .page-main__content{
  @include grid-column(12);
}

.page-sidebar{
  &__right{
    position: relative;
    padding-top: rem-calc(30px);
    @include grid-column(12);
  }
  &__left{
    @include grid-column(12);
  }
}

.page-content{
  &__right{
    @include grid-column(12);
  }
}


/***********************************************************
           Breakpoints :
            - small: 0,               //Mobile
            - medium: 768px,          //Tablet
            - large: 1024px,          //Desktop
            - xlarge: 1200px,
            - xxlarge: 1920px,
************************************************************/

@include breakpoint(medium){
  .page-sidebar{
    &__left{
      @include grid-column(4);
    }
  }
  .page-content{
    &__right{
      @include grid-column(8);
    }
  }
}

@include breakpoint(large){
  .page {
    padding-top: 0;
    clear: both;
  }

  .page-main{
    &__content{
      width:620px;
      float: left;
      padding-left: 10px;
    }
  }

  .page-static-2columns .page-main__content{
    width:620px;
  }

  .page-sidebar{
    &__right{
      padding-top: rem-calc(0px);
      position: relative;
      width: 310px;
      float: right;
      padding-right: rem-calc(10px);
    }
    &__left{
      @include grid-column(3);
    }
  }

  .page-content{
    &__right{
      @include grid-column(9);
    }
  }
}


@include breakpoint(xlarge){
  .page-main{
    &__content{
      width:680px;
    }
  }

  .page-static-2columns .page-main__content{
    width:560px;
  }

  .page-sidebar{
    &__right{
      position: relative;
      padding-left: 60px;
      width: 420px;
    }
  }
}

@include breakpoint(xxlarge) {
  .page-wrapper {
    &:last-child:not(:first-child) {
      float: none;
      &--nest {
        @include grid-row-nest(30px);
      }
      &__side-padding {
        padding-left: rem-calc(5px);
        padding-right: rem-calc(5px);
      }
      &__big-padding-top {
        padding-top: 200px;
      }
    }
  }
}
