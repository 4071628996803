/* Declaration variables */
$scrollbar_bg_color                 :#eaeeed;
$scrollbar_handle_bg_color          :#ffffff;
$scrollbar_handle_hover_bg_color    :#2274ac;
$scrollbar_fill_bg_color            :#2274ac;
$scrollbar_value_bg_color           :#1a1a1a;

/**************************************
            widget-showroom
**************************************/
.showroom-rebate {
  &__icon{
    @include font-size(25px);
  }
  &__icon,&__text,&__price{

  }
  &__price{
    font-family: $title-font-family;
    font-weight: 700;
  }
}

.showroom-price{
  &__price{
    &-wrapper{
      font-family: $title-font-family;
      font-weight: 700;
      display: inline-block;
      @include font-size(13px);
    }
    &--regular{
      font-family: $title-font-family;
      font-weight: 700;
    }
    &--old{
      @include font-size(12px);
      position: relative;
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        top: 7px;
        right: 0;
        transform: rotate(10deg);
      }
    }
    &-label{
      font-family: $title-font-family;
      font-weight: 700;
      &--starting-at{
        @include font-size(13px);
        line-height: 17px;
        display: inline;
      }
    }
  }
}

.showroom-financing{
  &__wrapper {
    width: 100%;
    margin: 0 auto 10px;
    padding-top: 10px;
    text-align: left;
    @include font-size(11px);
    border-top: 1px solid $light-gray;
  }
  &__type {
    @include font-size(11px);
    line-height: 20px;
    text-transform: uppercase;
  }
  &__spec-wrapper{
    @include grid-col(6,0);
  }
  &__payment, &__rate{
    display: block;
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(20px);
  }
  &__frequency, &__term{
    @include font-size(11px);
    line-height: 12px;
  }
  &__cashdown{
    @include inline-block();
    @include font-size(12px);
    width: 100%;
  }
}
// Conflict showroom and foundation
.widget-showroom-popup-desktop .widget-showroom-popup-desktop-body .widget-showroom-popup-desktop-left-side .legal-trim {
  line-height: 10px;
}
.widget-showroom-popup-desktop .widget-showroom-popup-desktop-body .widget-showroom-popup-desktop-left-side-body-lease>table,
.widget-showroom-popup-desktop .widget-showroom-popup-desktop-body .widget-showroom-popup-desktop-left-side-body-finance>table{
  margin-bottom: 0;
}

.widget-showroom-popup-desktop .widget-showroom-popup-desktop-body .widget-showroom-popup-desktop-left-side{
  width: 690px!important;
}

.widget-showroom-popup-desktop .widget-showroom-popup-desktop-body .widget-showroom-popup-desktop-left-side-body-lease>table>tbody,
.widget-showroom-popup-desktop .widget-showroom-popup-desktop-body .widget-showroom-popup-desktop-left-side-body-finance>table>tbody{
  background: transparent!important;
}

.widget-showroom-popup-desktop .widget-showroom-popup-desktop-body .widget-showroom-popup-desktop-right-side-downpayment>label .currencyinput>input{
  width: 60px!important;
  display: inline-block;
  height: auto;
}

.widget-showroom-teaser-desktop-vertical .widget-showroom-teaser-desktop-vertical-main{
  border-radius: 0!important;
}



/**************************************
            Widget Calculator
**************************************/
/**************************************
            Widget Calculator
**************************************/
.widget-calculator {
  &__wrapper{
    //@include grid-column(11,0);
  }
  &__legend{
    display: table;
    width: 100%;
  }
  &__legend-title{
    display: table-cell;
    vertical-align: middle;
    @include font-size(20px);
    line-height: 24px;
    width: 200px;
  }
  &__legend-picto{
    display: table-cell;
    vertical-align: middle;
    @include font-size(45px);
    line-height: 45px;
  }
  &_payments{
    @include font-size(16px);
    line-height: 16px;
    border-top: $code-border;
    padding-top: 15px;
    display: block;
    margin: 15px 0 0;
  }
  &__total{
    @include font-size(24px);
    font-weight: bold;
  }
  &__frequency{
    font-weight: bold;
  }
  &__link-expand{
    display: block;
    margin: 10px 0 15px;
    text-decoration: underline;
    font-style: normal;
    &:hover{
      text-decoration: none;
    }
  }
  &__container{
    margin: 15px 0;
  }
  .form-field__label {
    margin-bottom: 8px;
  }
}





/**************************************
            widget-Tabs
**************************************/
.widget-tabs{
  @include margin(10px 0 45px 0);
  @include grid-column(12);
  //padding-top: rem-calc(60px);
  &.article-listing__subnav-wrapper{
    padding-top: 0;
  }
  .tabs{
    width:100%;
    display: block;
    flex-direction: row;
    justify-content: space-between;
    border: none;
    border-bottom: 2px solid $light-gray;

    &-content{
      border: none;

    }
    &-departments{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      padding-top:5px;
      flex: 5 1 auto;
    }
    &-title{
      flex: 1 1 auto;
      border: 0;
      padding-left: rem-calc(20px);
      &:first-child{
        padding-left: 0;
      }

      // Overwrite css by default the foundation (color: #1779ba;)
      > a{
        color: inherit;
        padding:0;
        @include font-size(12px);
        @include line-height(14px);
      }

      &.is-active{
        .tabs-title__anchor:after {
          transform: scaleX(1);
          transform-origin: left;
        }
      }
      &__anchor{
        text-align: center;
        @include font-size(14px);
        &:after{
          display:block;
          content: '';
          border-bottom: 2px solid;
          padding-bottom: 10px;
          margin-bottom: -2px;
          @include transform(scaleX(0));
          @include transition(transform 250ms ease-in-out);
        }
      }
    }
    &-recall{
      padding-left: 20px;
      min-width: 150px;
      &-link{
        padding:5px;
        font-size: 12px;
        min-width: 150px;
        height: 45px;
        text-align: left;
        text-transform: uppercase;
        display:inline-block;
      }
      .link__ghost{
        &-text{
          display:inline-block;
          font-weight: 700;

        }
        &-picto::before{
          display:inline-block;
          font-size: 1.5rem;
        }
      }
    }
  }
}

@include breakpoint(large){
  .widget-tabs .tabs-departments{
    padding-top:20px;
  }
}




/**************************************
            widget-accordion
**************************************/
.widget-accordion{
  @include accordion-container();
}

.widget-accordion__content{
  @include accordion-content;
  padding: 10px 0;
  :last-child > &:last-child {
    border-bottom: none;
  }
}

.widget-accordion-item{
  border-bottom: $code-border;
}

.widget-accordion__title{
  @include accordion-title;
  @include font-size(14px);
  border: 1px solid transparent;
  &:before{
    content: '+';
    position: absolute;
    right: 1rem;
    margin-top: -.5rem;
    top:41%;
    @include font-size(25px);
  }
}

.is-active>.widget-accordion__title:before{
  content: '–';
}

/**************************************
            widget-countdown
**************************************/
.widget-countdown{
  display: table;
  width: 100%;
  margin-bottom:rem-calc(20px);
  @include border-radius(10px);
  @include padding(10px);
  &__arrow{
    @include font-size(45px);
    line-height: 45px;
    margin-right: 10px;
  }
  &__element{
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding-left: 20px;
  }
  &__left-content{
    position:relative;
    display: table-cell;
    vertical-align: middle;
    width:45px;
    min-height: 45px;
  }
  &__right-content{
    display: table-cell;
    vertical-align: middle;
  }
  &__number{
    position:relative;
    @include font-size(30px);
    @include line-height(30px);
  }
  &__tagline{
    @include show-for(medium);
    @include font-size(24px);
    line-height: 30px;
  }

  &__text{
    @include font-size(13px);
    line-height: 0;
  }
}

/**************************************
          widget-dropdown
**************************************/
.widget-dropdown{
  position:relative;
  @include inline-block();
  @include border-radius(5px);
  background:#fff;
  margin:0;
  min-width:130px;
  width:100%;
  &__button{
    @include border-radius(5px);
    border: $code-border;
    display:block;
    position:relative;
    text-align: left;
    text-decoration: none;
    padding: 16px 10px 7px;
    line-height: 25px;
    @include box-shadow( 0 0px 1px 0 rgba(0,0,0,.5));
    .text-updated{
      color: inherit;
    }
  }
  &__button-content{
    @include inline-block();
    position: relative;
  }
  &__button-picto{
    width: 60px;
    margin-right: 10px;
    img{
      margin: 0 auto;
    }
  }
  &__content{
    display:none;
    position:absolute;
    top:100%;
    left:0;
    right:0;
    margin:0;
    border: $code-border;
    background:#ffffff;
    color: #111111;
    z-index: 100;
    box-shadow:         0 2px 7px 0 rgba(50, 50, 50, 0.36);
    -webkit-box-shadow: 0 2px 7px 0 rgba(50, 50, 50, 0.36);
    -moz-box-shadow:    0 2px 7px 0 rgba(50, 50, 50, 0.36);
    max-height:250px;
    list-style: none;
    padding: 0;
    overflow-y: auto;
  }
  &__content-element{
    border-bottom:$code-border;
    text-align: left;
    &--selected,&:hover{
      background-color:#f2f2f2;
    }
    &--disabled{
      padding: 5px 10px;
      text-align:center;
      opacity: 0.90;
    }
  }
  &__content-element-text{
    display: block;
  }
  &__content-element-icon{
    display: inline-block;
    vertical-align: middle;
  }
  &__content-element-label{
    display: inline-block;
    vertical-align: middle;
  }
  &__content-element-link{
    display: block;
    padding:10px;
    text-decoration:none;
  }
  &__select-arrow{
    position: absolute;
    right: 2px;
    top: 50%;
    color:#616161;
  }
  &--deactivated{
    opacity: 0.5;
    &:hover{}
    * {
      cursor: default !important;
    }
  }

  &--homepage{
    display: block;
    margin-bottom: 15px;
    @include border-radius($global-radius);
    .widget-dropdown__button{
      border: none;
      padding: 10px 15px;
    }
    .widget-dropdown__content{
      border: none;
    }
    .form-field__label--text{
      display: none;
    }
  }
}


/**********************************/
/*        Filter Catalog          */
/**********************************/
.widget-filter{
  //&__search{
  //  position: relative;
  //  @include grid-col(8);
  //}
  &__search{
    max-width: 420px;
    margin: 0 auto;
  }
  &__search-input{
    outline: none;
    padding: 15px 30px 15px 15px;
    @include box-shadow(none);
    @include font-size(14px);
    background-color: transparent;
    &:focus{
      border: 0;
      background-color: transparent;
      @include box-shadow(none);
    }
  }
  &__search-input-icon{
    position: absolute;
    right: 10px;
    top: 8px;
    @include font-size(16px);
    @include line-height(33px);
    text-align: center;
    width: 35px;
    height: 35px;
    border: 1px solid;
    @include border-radius(50%);
  }
  &__slide-wrapper{
    @include grid-col(8);
  }
  &__slide-title{
    margin-top: 0;
    text-transform: uppercase;
  }
  &__slide-wrapper{
    position: relative;
    padding-right: 20px;
    .ui-slider-handle{
      @include inline-block();
      @include box-shadow(0 0 5px 0px rgba(0,0,0,0.3));
      @include vertical-center();
      margin-left: -20px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      cursor: pointer;
      border: none;
      z-index: 1;
      &:hover, &:active{
        background-color: #c0c0c0;
      }
    }
  }
  &__slide-bar{
    background-color: $light-gray;
    height:6px;
    position: relative;
    margin-top: 20px;
    &.ui-corner-all{
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
    }
  }
  &__slide-range{
    @include vertical-center;
    @include border-radius(5px);
    height: 5px;
    width: 100%;
  }

  &__slide-max{
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(14px);
    position: absolute;
    right: 15px;
    top: 0;
  }
  &__check-wrapper {
    display: table;
    width: 100%;
    margin: rem-calc(0 -10px 30px);
    @include clearfix();
  }
  &__bodystyle{
    display: table-cell;
    vertical-align: top;
    padding: rem-calc(0 5px);
    &-name{
      @include font-size(12px);
      display: table-cell;
      width: 100%;
      height: 45px;
      vertical-align: middle;
    }
  }
  &__check-element{
    display: table;
    @include border-radius(5px);
    padding: 0 10px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    @include font-size(18px);
    width: 100%;
    background-color: $white;
    border: 1px solid $dark-gray;
    &--selected{

    }
    &.disabled{
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.3;
    }
    &:hover{
      background-color: $light-gray;
    }
  }
  &__check-image{
    margin: 0 auto;
  }
  &__check-icon{
    display: block;
    margin: 0 auto;

  }
}

/**************************************
          widget-newsletter
**************************************/
.widget-newsletter{
  &__block{
    @include grid-column-row(0);
    position: relative;
  }
  &__label{
    @include grid-column(100%,0);
    margin-bottom:5px;

    .form-field__label--text.active:before {
      left: 45px;
    }
  }
  &__input{
    border:none;
    padding-left: rem-calc(45px);

    &:hover, &:focus, &:active {
      border:none;
    }
  }
  &__messages{
    position: absolute;
    top: 0;
  }
  &__messages-error{
    color: map-get($foundation-palette, alert);
  }
  &__messages-success{
    color: map-get($foundation-palette, success);
  }
  &__picto-email{
    position:absolute;
    top:19px;
    left:13px;
  }
  &__social{
    @include grid-col(8);
    @include vertical-center;
  }
  &__social-icon{
    @include grid-col(4);
    text-align:center;
    @include font-size(36px);
    &:last-child:not(:first-child){
      float:left
    }
  }
  &__social-link{
    .widget-newsletter{
      &__social-facebook:hover{ color: map-get($foundation-palette, facebook);}
      &__social-twitter:hover{ color: map-get($foundation-palette, twitter);}
      &__social-google:hover{ color: map-get($foundation-palette, google);}
      &__social-youtube:hover{ color: map-get($foundation-palette, youtube);}
    }
  }
  &__subscribe{
    position: relative;
    @include grid-col(12);
    text-align: center;
    padding: 20px 0;
  }
  &__subscribe-button{
    @include grid-col(50%);
    @include grid-column-position(center);
    margin-top: 15px;
  }
  &__subscribe-field{
    @include grid-col(9, 10px);
  }
  &__title{
    margin-bottom:30px;
    text-align: center;
  }
}


/**************************************
          widget-social
**************************************/
.widget-social{
  @include inline-block;
  position: absolute;
  top: 15px;
  right: 5px;
  padding:rem-calc(0 10px);
  text-align: right;
  &__link-container{
    margin-bottom:10px;
    padding-right: 15px;
  }
  &__share-container{
    position:relative;
    display:none;
    border:1px solid #ccc;
    border-radius:3px;
    @include padding-all(13px,13px,11px,15px);
    z-index:2;
    right: 15px;
    background:$white;
    &::before {
      content: "";
      position: absolute;
      top: -8px;
      right: 12px;
      width: 14px;
      height: 14px;
      border-top: 1px solid #ccc;
      border-left: 1px solid #ccc;
      border-top-left-radius:3px;
      background: #fefefe;
      transform: rotate(45deg) translate(-50%,50%);
    }

    &.active{
      display: inline-block;
    }
  }
}

/*************************************
        Widget ScrollTop
*************************************/
.widget-scrolltop{
  position: fixed;
  bottom: 50px;
  right: 15px;
  z-index: 99;
  text-align: center;
  cursor: pointer;
  &__block{
    @include absolute-center;
    width: 40px;
    height: 40px;
  }
  &__picto-wrapper{
    position: absolute;
    top: 50%;
    left: 48%;
    transform: translate(-50%,-48%) rotate(90deg);
  }
}

/**************************************
      widget-services-courtesy
**************************************/
.widget-services-courtesy{
  margin-bottom:rem-calc(10px);
  border:1px solid;
  padding: 8px 0;

  &__picto{
    @include inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include font-size(20px);
    width:44px;
    height:52px;
  }
  &__element{
    text-align: center;
  }

  &__left-content{
    position:relative;
    @include inline-block;
    width:65px;

  }
  &__right-content{
    @include inline-block;
  }

  &__tagline{
    margin:0;
  }

  &__text{
    @include font-size(13px);
    @include line-height(23px);
  }
}

/*************************************
        Widget Rating
*************************************/
.widget-rating{
  @include inline-block;
  @include margin-all(0,5px,5px,0);
  @include border-radius(12px);
  &__count{
    @include font-size(20px);
    @include line-height(20px);
    color:#fff;
  }
  &__star{
    @include inline-block;
    float: left;
    margin-left: rem-calc(4px);
    color: map-get($foundation-palette, star);

    &:first-child{
      margin-left: 0;
    }
    &--empty{
      color: map-get($foundation-palette, gray);
    }
  }
}

/*************************************
        Widget Google Review
*************************************/
.google-review{
  &__container{
    padding:20px;
  }
  &__box{
    padding: 16px 0 35px;
    border-bottom:1px solid #ccc;

    &:last-child{
      border-bottom:none;
    }
  }
}


/*************************************
   Widget Compare Box
**************************************/

.widget-comparebox{
  display: none;
  position: absolute;
  top:100%;
  right: 0;
  z-index: 2;
  @include default-box-shadow;
  border: $code-border;
  min-width: 320px;
  cursor: default;
  margin: 0;
  @include font-size(14px);
  &::before{
    @include css-triangle(10px,$medium-gray,up); // Read comment inside, $medium-gray (color fallback)
  }
  &::after{
    @include css-triangle(10px,$white,up); // Read comment inside, $white (color fallback)
  }
  &::after, &::before{
    position: absolute;
    bottom: 100%;
    right: 15px;
  }
  &.sticky{
    right:3px;
  }
  &__element{
    @include padding(10px 10px 10px 30px);
    display: table;
    width: 100%;
    @include box-sizing(border-box);
  }
  &__element-inactive{
    opacity: 0.5;
    pointer-events: none;
  }
  &__element-label{
    display: table-cell;
    vertical-align: middle;
    text-align:left;
    text-indent: -20px;
    margin-left: rem-calc(20px);
  }
  &__element-text{
    text-transform: capitalize;
  }
  &__element-count{
    @include font-size(18px);
    @include margin(0 5px);
  }

  &__element-link{
    display: table-cell;
    vertical-align: middle;
    @include padding(0 10px);
    text-align:right;
    .btn{
      //width: 60px;
      padding: rem-calc(10px);
      font-weight: normal;
    }
  }
  &__notification{
    position:absolute;
    top: 100%;
    right:0;
    z-index: 1;
    @include default-box-shadow;
  }
  &__notification-template{
    display:none;
  }
  &__notification-element{
    padding:rem-calc(10px);
    width: 100%;
    min-width: 290px;
    box-sizing: border-box;

  }
  &__notification-icon{
    display: table-cell;
    vertical-align: middle;
    text-align:left;
  }
  &__notification-text{
    display: table-cell;
    vertical-align: middle;
    @include padding(0 10px);
    text-align:left;
  }
}

/*************************************
   Widget Checkbox for Compare
**************************************/
.widget-checkbox{
  position: relative;
  display: inline-block;
  vertical-align: middle;
  height: 15px;
  width: 15px;
  margin: 10px 0;
  cursor: pointer;
  &-label{
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    width: auto;
    text-align: left;
    line-height: 14px;
    padding-left: 10px;
  }
}

/*************************************
      Fix Cherry Popper Subscription
**************************************/
body > [id^=cherry-]{
  [type=text], [type=password], [type=date], [type=datetime], [type=datetime-local], [type=month], [type=week], [type=email], [type=number], [type=search], [type=tel], [type=time], [type=url], [type=color], textarea{
    height: 1.7rem;
    font-family: inherit;
    @include font-size(12px);
  }
  .label-checkbox{
    font-size: 13px;
    line-height: 15px;
    display: inline-block!important;
    vertical-align: top!important;
    width: 335px!important;
    padding-left: 5px!important;
  }
  [type="checkbox"]:not(:checked) + label:before,
  [type="checkbox"]:checked + label:before,
  .widget-checkbox__check:before {
    content:none;
  }
  [type="checkbox"]:not(:checked) + label:after,
  [type="checkbox"]:checked + label:after,
  .widget-checkbox__check:after {
    content:none;
  }
  [type=checkbox]+label, [type=radio]+label{
    font-size :inherit;
  }
  .form__label, label{
    font-family: inherit;
  }
}

/*************************************
   Widget Scrollbar Slider
**************************************/
.odometer-range,
.price-range{
  display: block;
  padding-bottom: rem-calc(5px);
  &__title{
    position: absolute;
    top: 5px;
    right: 24px;
    @include font-size(14px);
    line-height: 22px;
    text-align: right;
  }
}

.scrollbar{
  @include inline-block();
  width: 100%;
  background: $scrollbar_bg_color;
  margin: 10px 0;
  @include border-radius(6px);
}

.scrollbar-fill{
  @include slider-fill;
  left: -1px;
  top: -1px;
  @include border-radius(6px);
  background-color: $scrollbar_fill_bg_color;
}

.scrollbar-handle{
  @include slider-handle;
  @include border-radius(50%);
  position: relative;
  cursor: pointer;
  border: 2px solid;
  z-index: auto;
  &::before{
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    @include border-radius(50%);
    @include absolute-center;
  }
  &:hover{
    background-color: $scrollbar_handle_hover_bg_color;
  }
}

/*************************************
   Widget Tradesii
**************************************/
.listing-block__tradesii,
.inventory-details__tradesii{
  .page-wrapper{
    padding-top: 0;
    padding-bottom: 20px;
  }
}

/***********************************************************
           Breakpoints :
            - small: 0,               //Mobile
            - medium: 768px,          //Tablet
            - large: 1024px,          //Desktop
            - xlarge: 1200px,
            - xxlarge: 1920px,
************************************************************/

@include breakpoint(medium){
    .widget-accordion__title{
      @include font-size(16px);
    }
    .widget-countdown{
    &__tagline{
      margin-bottom: 5px;
    }
    &__element:first-of-type{
      padding-left: 0;
    }
  }
  .widget-filter{
    &__bodystyle-name{
      @include font-size(14px);
      height: 84px;
    }
  }
  .widget-newsletter{
    &__label{
      @include grid-column(61%,0);
      margin-bottom:5px;

      .form-field__label--text.active:before {
        left: 45px;
      }
    }
    &__subscribe{
      @include grid-col(8);
      @include grid-column-position(center);
    }
    &__subscribe-button{
      @include grid-col(37%);
      @include grid-column-position(center);
      margin-top: 5px;
    }
  }
}

@include breakpoint(large) {
  .widget-countdown{
    &__tagline{
      display: table-cell;
      vertical-align: middle;
      margin-bottom: 0;
    }
    &__element:first-of-type{
      padding-left: 20px;
    }
  }
  .widget-filter__search{
    &-input{
      padding: 24px 60px 24px 15px;
      @include font-size(18px);
    }
    &-input-icon{
      top: 20px;
      width: 40px;
      height: 40px;
      @include line-height(38px);
    }
  }

  .widget-tabs{
    .tabs{
      &-title{
        padding-left: rem-calc(55px);
        >a{
          @include font-size(14px);
          @include line-height(20px);
        }
      }
    }
  }

  .odometer-range,
  .price-range{
    padding-bottom: rem-calc(10px);
    &__title{
      top: 10px;
    }
  }

}

