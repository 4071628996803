/*********************************************************
        Block CTAs Big Generic
**********************************************************/
.block-ctas-big{
  margin-top: 0;
  &__wrapper{
    @include clearfix();
    text-align: center;
  }
  &__item{
    display: inline-block;
    width: 50%;
    min-height: 410px;
    float: left;
    position: relative;
    padding: rem-calc(10px);
    &:first-child{
      display: block;
      width: 100%;
      float: none;
      .block-ctas-big__title{
        @include font-size(44px);
        @include line-height(46px);
      }
      .block-ctas-big__body{
        padding-bottom: rem-calc(50px);
      }
      .block-ctas-big__content{
        padding-bottom: rem-calc(20px);
      }
    }
  }
  &__title{
    @include font-size(34px);
    @include line-height(36px);
    span{
      @include font-size(15px);
      @include line-height(17px);
    }
  }
  &__body{
    padding: rem-calc(15px 0 60px);
    .link__alpha{
      padding-left: rem-calc(15px);
      padding-right: rem-calc(15px);
      min-width: 180px;
    }
  }
  &__content{
    @include font-size(16px);
    @include line-height(20px);
    padding-bottom: rem-calc(30px);
    h3,
    .subtitle{
      @include font-size(24px);
      @include line-height(28px);
    }
  }
}

/***********************************
  "Block CTAs Big - Generic"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/
@include breakpoint(large){
  .block-ctas-big{
    margin-top: rem-calc(-90px);
    &__item{
      width: 26%;
      margin-top: rem-calc(40px);
      @include box-shadow(0px 5px 25px 0 rgba(0,0,0,.3));
      &:first-child{
        display: inline-block;
        width: 48%;
        margin-top: 0;
        float: none;
        z-index: 3;
      }
      &:last-child{
        float: right;
      }
    }
  }
}


/*********************************************************
        Block CTAs Big Alpha
**********************************************************/

.block-ctas-big--alpha{
  .block-ctas-big__header{
    padding: rem-calc(50px 0 10px);
    position: relative;
    &:after{
      content: "";
      display: block;
      width: 60px;
      height: 3px;
      position: absolute;
      left: 50%;
      bottom: 0;
      @include transform(translate(-50%, 0));
    }
  }
  .block-ctas-big__item:first-child .block-ctas-big__header{
    padding: rem-calc(60px 0 40px);
    margin: rem-calc(0 0 40px);
  }
  .block-ctas-big__content h3,
  .block-ctas-big__content .subtitle{
    margin: rem-calc(0 0 15px);
  }
}

/*********************************************************
        Block CTAs Big Beta
**********************************************************/
.block-ctas-big--beta{
  padding-bottom: 30px;
  .block-ctas-big__item{
    padding-right: 0;
    @include box-shadow(0px 5px 55px 0 rgba(0,0,0,.3));
    &:first-child{
      padding: rem-calc(20px 20px 10px);
      z-index: 3;
      .block-ctas-big__border{
        border-top: 30px solid;
        border-left: 240px solid transparent;
        border-right: 240px solid transparent;
      }
      .block-ctas-big__header{
        padding: rem-calc(45px 0 70px);
      }
      .block-ctas-big__body{
        padding-bottom: rem-calc(30px);
      }
    }
    &:last-child{
      padding-right: 10px;
      padding-left: 0;
    }
  }
  .block-ctas-big__border{
    display: block;
    width: 100%;
    height: 0;
    border-top: 25px solid;
    border-left: 125px solid transparent;
    border-right: 125px solid transparent;
    position: absolute;
    left: 0;
    top: 100%;
  }
  .block-ctas-big__header{
    padding: rem-calc(40px 0 55px);
  }
  .block-ctas-big__body{
    padding-bottom: rem-calc(35px);
  }
  .block-ctas-big__content h3,
  .block-ctas-big__content .subtitle{
    padding: rem-calc(0 0 20px);
    margin: rem-calc(0 0 6px);
    position: relative;
    &:after{
      content: "";
      display: block;
      width: 315px;
      height: 1px;
      position: absolute;
      left: 50%;
      bottom: 0;
      @include transform(translate(-50%, 0));
    }
  }
}

/*********************************************************
        Block CTAs Big Charlie
**********************************************************/

.block-ctas-big-charlie {
  @include clearfix();
  &__item{
    position: relative;
    display: block;
    width: 100%;
    float: none;
    @include box-shadow(0px 5px 15px 0 rgba(0,0,0,.3));
    @include border-bottom-radius(10px);
    margin-top: rem-calc(70px);
    &:first-child{
      float: none;
      margin-top: 0;
      .block-ctas-big-charlie__body{
        min-height: 0;
      }
    }
  }
  &__picto{
    position: absolute;
    left: 15px;
    top: 0;
    @include transform(translate(0, -50%));
  }
  &__body{
    min-height: 0;
    @include padding(60px 30px 0);
  }
  &__title{
    @include font-size(48px);
    @include line-height(48px);
    padding: rem-calc(0 0 30px);
    span{
      @include font-size(38px);
    }
  }
  &__content{
    @include font-size(18px);
    padding-bottom: rem-calc(30px);
    p{
      margin: 0;
      padding: rem-calc(0 0 10px);
    }
    ul{
      margin-bottom: 0;
    }
    li{
      @include font-size(14px);
      list-style-type: disc;
      padding: rem-calc(0 0 10px);
    }
  }
  &__footer{
    padding: rem-calc(20px 30px);
    @include border-bottom-radius(10px);
  }
  &__cta,
  &__cta-secondary{
    @include font-size(16px);
  }
  &__cta-secondary{
    margin-left: rem-calc(20px);
  }
}

/***********************************
  "Block CTAs Big - Charlie"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/
@include breakpoint(large){
  .block-ctas-big-charlie{
    &__item{
      display: inline-block;
      width: 49%;
      float: right;
      margin-top: 0;
      &:first-child{
        float: left;
      }
      &:last-child{
        margin-top: rem-calc(70px);
      }
    }
    &__item:first-child .block-ctas-big-charlie__body{
      min-height: 526px;
    }
    &__body{
      min-height: 185px;
    }
    &__title{
      @include font-size(35px);
      @include line-height(37px);
      span{
        @include font-size(25px);
      }
    }
    &__content{
      padding-bottom: 0;
    }
    &__content li{
      @include font-size(13px);
    }
    &__cta,
    &__cta-secondary{
      @include font-size(13px);
    }
    &__cta-secondary{
      margin-left: rem-calc(10px);
    }
  }
}

@include breakpoint(xxlarge){
  .block-ctas-big--larger {
    .block-ctas-big-charlie {
      &__item:first-child .block-ctas-big-charlie__body {
        min-height: 534px;
      }
      &__body {
        min-height: 185px;
      }
      &__title {
        @include font-size(48px);
        @include line-height(48px);
        span {
          @include font-size(38px);
        }
      }
      &__content {
        padding-bottom: 0;
      }
      &__content li {
        @include font-size(14px);
      }
      &__cta,
      &__cta-secondary {
        @include font-size(16px);
      }
      &__cta-secondary {
        margin-left: rem-calc(20px);
      }
    }
  }
}