/***********************************
    Catalog Preview Vehicle Alpha
************************************/
.catalog-preview-alpha{
  &__block-bottom{
    text-align: center;
  }
  &__vehicle-image{
    margin: 0 auto;
  }
  &__vehicle-name{
    @include font-size(24px);
    line-height: 40px;
    margin-bottom: 25px;
  }
  &__showroom-financing{
    &__wrapper{
      @include inline-block();
      text-align: left;
      width: auto;
      margin: 25px auto;
      position: relative;
      left: 50%;
      @include transform(translate(-50%, 0));
      white-space: nowrap;
    }
    &__type{
      @include font-size(16px);
      line-height: 18px;
    }
    &__spec-wrapper{
      @include inline-block();
      margin-right: 20px;
    }
    &__payment, &__rate{
      @include font-size(35px);
      line-height: 40px;
    }
    &__frequency, &__term{
      @include font-size(12px);
      line-height: 16px;
    }
    &__payment-wrapper{
      @include inline-block();
    }
    &__cashdown{
      @include font-size(14px);
      line-height: 20px;
    }
    &__rebate{
      @include font-size(35px);
      line-height: 40px;
      vertical-align: text-top;
      font-family: $title-font-family !important;
      font-weight: 700;
      &:before{
        font-family: "icomoon";
        @include font-size(27px);
      }
    }
  }
  &__showroom-price{
    &__wrapper{
      @include font-size(16px);
      line-height: 20px;
    }
    &__price--old{
      @include font-size(12px);
      line-height: 14px;
      text-decoration: line-through;
    }
  }
  &__cta-wrapper{
    display: block;
  }
}

/***********************************
    Catalog Preview Vehicle Beta
************************************/
.catalog-preview-beta{
  &__block-bottom{
    text-align: center;
  }
  &__block-column{
    @include grid-column(6);
  }
  &__vehicle-image{
    margin: 0 auto;
  }
  &__vehicle-name{
    @include font-size(35px);
    line-height: 40px;
    text-align: left;
  }
  &__showroom-financing{
    &__wrapper{
      text-align: left;
    }
    &__type{
      @include font-size(12px);
      line-height: 16px;
      text-transform: uppercase;
    }
    &__spec-wrapper{
      @include inline-block();
      margin-right: 20px;
    }
    &__payment, &__rate{
      @include inline-block(bottom);
      @include font-size(30px);
      line-height: 35px;
    }
    &__frequency, &__term{
      @include inline-block(bottom);
      @include font-size(12px);
      line-height: 16px;
    }
    &__payment-wrapper{
      display: block;
    }
    &__cashdown{
      @include font-size(14px);
      line-height: 20px;
    }
  }
  &__showroom-price{
    &__wrapper{
      display: block;
      margin-top: 5px;
      @include font-size(16px);
      line-height: 20px;
    }
    &__price--old{
      @include font-size(12px);
      line-height: 14px;
      text-decoration: line-through;
    }
  }
  &__cta{
    margin: 10px 0;
    min-width: 230px;
  }
  &__cta-wrapper{
    display: block;
  }
}

/***********************************
    Catalog Preview Vehicle Charlie
************************************/
.catalog-preview-charlie{
  &__block-bottom{
    position: relative;
    height: 200px;
  }
  &__vehicle-image{
    position: absolute;
    right: 0;
    bottom: calc(100% - 200px);
  }
  &__vehicle-name{
    @include font-size(36px);
    line-height: 40px;
    margin-bottom: 10px;
  }
  &__showroom-financing{
    &__wrapper{
      @include inline-block();
      text-align: left;
      margin-bottom: 10px;
    }
    &__type{
      @include font-size(14px);
      line-height: 20px;
    }
    &__spec-wrapper{
      @include inline-block();
      margin-right: 20px;
      &:last-child{
        margin-right: 0;
      }
    }
    &__payment, &__rate{
      @include inline-block(bottom);
      @include font-size(30px);
      line-height: 35px;
    }
    &__frequency, &__term{
      @include inline-block(bottom);
      @include font-size(12px);
      line-height: 16px;
    }
    &__payment-wrapper{
      display: block;
      margin: 10px 0;
    }
    &__cashdown{
      @include font-size(14px);
      line-height: 20px;
    }
  }
  &__showroom-price{
    &__wrapper{
      @include font-size(16px);
      line-height: 20px;
    }
    &__price--old{
      @include font-size(12px);
      line-height: 14px;
      text-decoration: line-through;
    }
  }
  &__cta-wrapper{
    display: block;
  }
}

/***********************************
    Catalog Preview Vehicle Delta
************************************/
.catalog-preview-delta{
  &__block-bottom{
    position: relative;
    text-align: left;
    margin-top: 25px;
  }
  &__vehicle-image{
    margin: 50px 0 0 auto;
    max-width: 500px;
  }
  &__block-header{
    position: absolute;
    left: 0;
    top: 50px;
  }
  &__vehicle-picto{
    display: inline-block;
    float: left;
    margin-right: 15px;
  }
  &__vehicle-name{
    display: inline-block;
    float: left;
    @include font-size(48px);
    @include line-height(48px);
    margin-bottom: 0;
  }
  &__vehicle-name-subtitle{
    display: block;
    @include font-size(24px);
    @include line-height(26px);
  }
  &__showroom-financing{
    &--mainprice{
      position: absolute;
      bottom: 100%;
      left: 0;
    }
    &__wrapper{
      display: block;
      text-align: left;
      margin-bottom: 20px;
    }
    &__type{
      @include font-size(16px);
      line-height: 18px;
    }
    &__spec-wrapper{
      @include inline-block();
      margin-right: 40px;
    }
    &__rate-wrapper{
      @include inline-block(bottom);
      margin-right: 20px;
      padding-left: 20px;
      border-left: 1px solid $border-grey;
    }
    &__payment, &__frequency, &__rate{
      @include inline-block(bottom);
      @include font-size(30px);
      line-height: 34px;
    }
    &__term{
      display: block;
      @include font-size(13px);
      line-height: 16px;
    }
    &__payment-wrapper{
      @include inline-block(top);
      margin-right: 20px;
    }
    &__cashdown{
      .cashdown-value{
        font-family: $title-font-family;
        font-weight: 700;
        @include font-size(30px);
        line-height: 34px;
      }
      span{
        display: block;
        @include font-size(16px);
        line-height: 18px;
      }
      span:last-child{
        @include font-size(13px);
        line-height: 16px;
      }
    }
  }
  &__showroom-price{
    &__price-label--starting-at{
      display: block;
    }
    &__price-wrapper{
      display: block;
      font-family: $title-font-family;
      font-weight: 700;
      @include font-size(24px);
      @include line-height(26px);
      padding-bottom: 15px;
    }
    &__wrapper{
      @include font-size(16px);
      line-height: 20px;
    }
    &__price--old{
      @include font-size(12px);
      line-height: 14px;
      text-decoration: line-through;
    }
  }
  &__cta-wrapper{
    display: block;
    margin-left: 5px;
  }
  &__cta{
    margin-left: 20px;
    &:first-child{
      margin-left: 0;
    }
  }
}

/***********************************
  "Catalog Preview Delta"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/

@include breakpoint(large){
  .catalog-preview-delta{
    &__vehicle-image{
      margin: 0 0 0 auto;
      max-width: none;
    }
    &__showroom-financing__wrapper{
      @include inline-block();
    }
    &__cta-wrapper{
      @include inline-block();
    }
  }
}

/***********************************
    Catalog Preview Vehicle Echo
************************************/
.catalog-preview-echo{
  &__block-bottom{
    @include clearfix();
    text-align: center;
    width: auto;
    min-width: 650px;
    position: relative;
    left: 50%;
    @include transform(translate(-50%, 0));
    white-space: nowrap;
    z-index: 10;
  }
  &__vehicle-image{
    margin: 0 auto;
  }
  &__vehicle-name{
    margin: 0;
    font-family: $title-font-family !important;
    font-weight: 700;
    @include font-size(36px);
    @include line-height(38px);
  }
  &__showroom-financing{
    &__wrapper{
      position: relative;
      @include inline-block();
      text-align: left;
      margin: 25px auto;
    }
    &__type{
      @include font-size(16px);
      line-height: 18px;
    }
    &__spec-wrapper{
      @include inline-block();
      margin-right: 20px;
    }
    &__payment, &__rate{
      @include font-size(35px);
      line-height: 40px;
    }
    &__frequency, &__term{
      position: relative;
      z-index: 1;
      @include font-size(12px);
      line-height: 16px;
    }
    &__payment-wrapper{
      @include inline-block();
    }
    &__cashdown{
      @include font-size(14px);
      line-height: 20px;
    }
    &__rebate-wrapper{
      position: absolute;
      left: 0;
      bottom: -60px;
      padding: rem-calc(10px 20px);
      margin: 0;
      @include font-size(14px);
      text-transform: uppercase;
      vertical-align: middle;
      .catalog-preview-echo__showroom-financing__term{
        @include font-size(14px);
        display: inline;
      }
      &:after,
      &:before{
        content:"";
        position: absolute;
        width:51%;
        height: 26px;
        z-index: 0;
        top:0;
      }

      &:before{
        left:0px;
        transform: rotate(-8deg);
        transform-origin: left top;
      }
      &:after{
        right:0px;
        transform: rotate(8deg);
        transform-origin: right top;
      }
    }
    &__rebate{
      position: relative;
      z-index: 1;
      display: inline;
      font-family: $title-font-family !important;
      font-weight: 700;
      &:before{
        display: none;
      }
    }
  }
  &__showroom-price{
    &__wrapper{
      @include font-size(16px);
      line-height: 20px;
    }
    &__price--old{
      display: none;
    }
  }
  &__cta-wrapper{
    display: inline-block;
    float: right;
    margin-top: rem-calc(46px);
  }
}

/***********************************
    Catalog Preview Vehicle Fox
************************************/
.catalog-preview-fox{
  &__block-bottom{
    text-align: left;
  }
  &__vehicle-image{
    margin: 0 auto;
  }
  &__vehicle-name{
    display: block;
    @include font-size(24px);
    line-height: 28px;
    margin-bottom: 20px;
  }
  &__showroom-financing{
    &__wrapper{
      display: block;
      text-align: left;
      margin-bottom: 20px;
    }
    &__type{
      @include font-size(16px);
      line-height: 18px;
    }
    &__spec-wrapper{
      @include inline-block();
      margin-right: 20px;
    }
    &__payment, &__rate{
      @include inline-block(bottom);
      @include font-size(35px);
      line-height: 40px;
    }
    &__frequency, &__term{
      @include inline-block(bottom);
      @include font-size(12px);
      line-height: 16px;
    }
    &__payment-wrapper{
      @include inline-block();
    }
    &__cashdown{
      @include font-size(14px);
      line-height: 20px;
    }
  }
  &__showroom-price{
    &__wrapper{
      @include font-size(16px);
      line-height: 20px;
    }
    &__price--old{
      @include font-size(12px);
      line-height: 14px;
      text-decoration: line-through;
    }
  }
  &__cta-wrapper{
    display: block;
  }
}

/***********************************
  "Catalog Preview Fox"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/

@include breakpoint(large){
  .catalog-preview-fox{
    &__block-bottom{
      text-align: center;
    }
    &__vehicle-name{
      @include inline-block(top);
      margin-right: 50px;
      margin-bottom: 0;
    }
    &__showroom-financing__wrapper{
      @include inline-block();
    }
    &__cta-wrapper{
      @include inline-block();
      margin-left: 20px;
    }
  }
}

/***********************************
    Catalog Preview Vehicle Gamma
************************************/

.catalog-preview-gamma{
  position: relative;
  padding-top: rem-calc(30px);
  &__vehicle-image{
    margin: 0 auto;
    max-width: 100%;
  }
  &__vehicle-name{
    position: relative;
    @include font-size(26px);
    @include line-height(30px);
    padding-bottom: 8px;
    margin-bottom: 10px;
    &:before{
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background: $border-grey;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
  &__showroom-financing{
    &__spec-wrapper{
      position: relative;
      padding-bottom: 8px;
      margin-bottom: 5px;
      &:before{
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background: $border-grey;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
    &__rebate-wrapper{
      position: relative;
      padding-bottom: 4px;
      margin-bottom: 10px;
      &:before{
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background: $border-grey;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
    &__payment-wrapper{
      position: relative;
      padding-bottom: 4px;
      margin-bottom: 5px;
      &:before{
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background: $border-grey;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
    &__type{
      @include font-size(13px);
      @include line-height(15px);
    }
    &__spec{
      @include inline-block(bottom);
    }
    &__payment,
    &__rate{
      @include inline-block(bottom);
      @include font-size(22px);
      @include line-height(26px);
    }
    &__rate{
      margin-left: 12px;
    }
    &__frequency,
    &__term{
      @include inline-block(bottom);
      @include font-size(11px);
      @include line-height(18px);
    }
    &__cashdown{
      @include font-size(10px);
      @include line-height(16px);
    }
    &__rebate-term,
    &__rebate{
      @include inline-block(bottom);
      @include font-size(16px);
      @include line-height(24px);
    }
  }
  &__showroom-price__price-label--starting-at{
    display: block;
    @include font-size(13px);
    @include line-height(15px);
  }
  &__showroom-price__price--regular{
    font-family: $title-font-family;
    font-weight: 700;
    @include inline-block(bottom);
    @include font-size(22px);
    @include line-height(26px);
  }
  &__showroom-price__price--legal{
    @include inline-block(top);
    @include font-size(11px);
    @include line-height(18px);
  }
  &__showroom-price__price--old{
    @include inline-block(bottom);
    @include font-size(11px);
    @include line-height(22px);
    text-decoration: line-through;
  }
  &__cta-wrapper{
    padding-top: 10px;
    text-align: center;
  }
  &__cta{
    padding-left: 20px;
    padding-right: 20px;
  }
}

/***********************************
  "Catalog Preview Gamma"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/

@include breakpoint(medium) {
  .catalog-preview-gamma{
    padding-top: rem-calc(60px);
    &__vehicle-image{
      margin: 0 0 0 auto;
      max-width: 500px;
    }
    &__vehicle-name{
      @include font-size(35px);
      @include line-height(38px);
      position: absolute;
      left: 10px;
      top: 15px;
      margin-bottom: 0;
      &:before{
        width: 372px;
      }
      &:after{
        content: "";
        display: block;
        width: 60px;
        height: 1px;
        background: $border-grey;
        position: absolute;
        left: 372px;
        bottom: 0;
        @include transform(rotate(40deg));
        @include transform-origin(left top);
      }
    }
    &__showroom-financing{
      &__spec-wrapper{
        position: absolute;
        left: 10px;
        top: 80px;
        margin-bottom: 0;
        &:before{
          width: 250px;
        }
        &:after{
          content: "";
          display: block;
          width: 20px;
          height: 1px;
          background: $border-grey;
          position: absolute;
          left: 250px;
          bottom: 0;
          @include transform(rotate(40deg));
          @include transform-origin(left top);
        }
      }
      &__rebate-wrapper{
        position: absolute;
        left: 530px;
        top: 60px;
        margin-bottom: 0;
        &:before{
          width: 170px;
        }
        &:after{
          content: "";
          display: block;
          width: 10px;
          height: 1px;
          background: $border-grey;
          position: absolute;
          right: 100%;
          bottom: 0;
          @include transform(rotate(-40deg));
          @include transform-origin(right top);
        }
      }
      &__payment-wrapper{
        position: absolute;
        left: 530px;
        top: 0;
        margin-bottom: 0;
        &:after{
          content: "";
          display: block;
          width: 55px;
          height: 1px;
          background: $border-grey;
          position: absolute;
          right: 100%;
          bottom: 0;
          @include transform(rotate(-40deg));
          @include transform-origin(right top);
        }
      }
      &__type{
        @include font-size(16px);
        @include line-height(18px);
      }
      &__payment,
      &__rate{
        @include font-size(30px);
        @include line-height(34px);
      }
      &__frequency,
      &__term{
        @include font-size(13px);
        @include line-height(22px);
      }
      &__cashdown{
        @include font-size(12px);
        @include line-height(16px);
      }
      &__rebate-term,
      &__rebate{
        @include font-size(20px);
        @include line-height(24px);
      }
    }
    &__showroom-price__price-label--starting-at{
      @include font-size(16px);
      @include line-height(18px);
    }
    &__showroom-price__price--regular{
      @include font-size(30px);
      @include line-height(34px);
    }
    &__showroom-price__price--legal{
      @include font-size(13px);
      @include line-height(22px);
    }
    &__showroom-price__price--old{
      @include font-size(13px);
      @include line-height(22px);
    }
    &__cta-wrapper{
      position: absolute;
      left: 10px;
      top: 220px;
      padding-top: 0;
      text-align: left;
      &:before{
        content: "";
        display: block;
        width: 60px;
        height: 1px;
        background: $border-grey;
        position: absolute;
        left: calc(100% + 10px);
        top: 50%;
      }
      &:after{
        content: "";
        display: none;
        width: 35px;
        height: 1px;
        background: $border-grey;
        position: absolute;
        right: calc(100% + 41px);
        top: 50%;
        @include transform(rotate(-40deg));
        @include transform-origin(right top);
      }
    }
  }
}

@include breakpoint(large) {
  .catalog-preview-gamma{
    &__vehicle-name{
      margin-bottom: 0;
    }
    &__vehicle-image{
      margin: 0 auto;
      max-width: none;
    }
    &__showroom-financing{
      &__rebate-wrapper{
        left: 10px;
        top: 175px;
        padding-bottom: 8px;
        &:before{
          width: 170px;
          left: 0;
        }
        &:after{
          width: 20px;
          left: 170px;
          right: auto;
          @include transform(rotate(40deg));
          @include transform-origin(left top);
        }
      }
      &__payment-wrapper{
        left: 753px;
        padding-bottom: 8px;
        &:before{
          width: 220px;
        }
        &:after{
          width: 75px;
          right: 100%;
          @include transform(rotate(-40deg));
          @include transform-origin(right top);
        }
      }
    }
    &__cta-wrapper{
      left: 830px;
      top: 115px;
      &:before{
        width: 26px;
        left: -41px;
        top: 50%;
      }
      &:after{
        display: block;
        width: 35px;
        right: calc(100% + 41px);
        top: 50%;
        @include transform(rotate(-40deg));
        @include transform-origin(right top);
      }
    }
  }
}