/***********************************
      Home Inventory Tabs
************************************/

.home-inventory-tabs{
  &__wrapper{
    padding: 0;
    text-align: center;
  }
  .tabs{
    display: inline-block;
    width: auto;
    @include border-radius(5px);
    border: 0;
  }
  .home-inventory-tabs__title{
    display: inline-block;
    margin: 0;
    padding: 0;
  }
  .home-inventory-tabs__title>a{
    display: inline-block;
    padding: rem-calc(14px 40px);
    border: 1px solid;
    border-left: 0px;
    @include border-right-radius(5px);
    @include font-size(13px);
    @include line-height(16px);
    position: relative;
    &:after{
      position: absolute;
      width: 0;
      height: 0;
      border: 0;
      border-top: 10px solid;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      top: calc(100% + 1px);
      left: 50%;
      @include transform(translate(-50%, 0));
      opacity: 0;
    }
  }
  .home-inventory-tabs__title.is-active>.tabs-title__anchor:after{
    @include transform(translate(-50%, 0));
    border-left-color: transparent;
    border-right-color: transparent;
    opacity: 1;
  }
  .home-inventory-tabs__title:first-child>a{
    border: 1px solid;
    border-right: 0px;
    @include border-right-radius(0);
    @include border-left-radius(5px);
  }
  .tabs-panel{
    padding-top: 0;
    padding-bottom: 0;
  }
}

/***********************************
  "Home Inventory Tabs"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/
@include breakpoint(medium) {
  .home-inventory-tabs .home-inventory-tabs__title>a{
    padding: rem-calc(14px 70px);
  }
}