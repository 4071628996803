/**************************************
          snippet-address
**************************************/

.snippet-address{
  font-style: normal;
  &--margin-bottom{
    margin-bottom:rem-calc(15px);
  }
  &__link{
    text-decoration:underline;
    &:hover, &:focus, &:active{
      text-decoration: underline;
    }
  }
  &__address{
    display: inline;
  }
}

/**************************************
          snippet-phone
**************************************/
.snippet-phone{
  &__department-wrapper{}
  &__department-name, &__department-phone{
    @include font-size(12px);
    @include line-height(17px);
  }
  &__department-phone {
    text-decoration: underline;
  }
  &__separator{
    &:after{
      content: '|';
      font-weight:normal;
      @include padding-all(0,0,0,4px);
    }
  }
  &__icon{
    margin-right: 5px;
  }
}

/**************************************
          Language Switch
**************************************/
.language-switch{
  display: block;
  position: relative;
  width: 26px;
  height: 60px;
  text-align: center;
  @include border-radius(13px);
  background-color: #f8f8f8;
  color: #616161;
  border: 1px solid #e6e6e6;
  &__item{
    display: block;
    position: absolute;
    width: 24px;
    height: 32px;
    left: 0;
    top: 0;
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(11px);
    line-height: 30px;
    text-decoration: none;
    border: 1px solid transparent;
    &:nth-child(2){
      top: auto;
      bottom: 0;
    }
    &--active{
      @include border-radius(13px);
      background-color: #e9e9e9;
      color: #616161;
      @include box-shadow(0px 0px 7px 0px rgba(0,0,0,0.5));
      border-color: #fff;
    }
    &:not(.language-switch__item--active){
      opacity: 0.5;
    }
    &:hover:not(.language-switch__item--active){
       opacity: 1;
    }
  }
  &--horizontal{
    width: 60px;
    height: 26px;
    .language-switch__item{
      width: 32px;
      height: 24px;
      line-height: 22px;
      &:nth-child(2){
        left: auto;
        right: 0;
      }
    }
  }
}

/**************************************
          Map - InfoBox
**************************************/
.googlemap-infobox{
  &.infobox_content{
    @include font-size(14px);
    line-height: 18px;
    text-align: center;
  }
  .infobox_dealer-name{
    @include font-size(18px);
    line-height: 22px;
  }
  .snippet-address{
    &__link{
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
    &__address{
      display: block;
    }
  }
  .snippet-phone__department-phone{
    @include font-size(16px);
    @include line-height(20px);
  }
}

// over-write default
.gm-style .gm-style-iw{
  overflow: auto;
}

/**************************************
          Breadcrumb
**************************************/
.breadcrumb{
  @include show-for(tablet);
  position: absolute;
  top: 0;
  left:0;
  right:0;
  padding:rem-calc(0 30px);
  margin:10px auto;
  width: 100%;
  text-align: left;
}

/**************************************
    View Interior & Exterior Switch
**************************************/
.view-switch-wrapper{
  @include grid-column-row();
}
.view-switch-legend{
  @include inline-block();
  padding-right: 10px;
  p{
    @include font-size(14px);
    line-height: 18px;
    font-family: $title-font-family;
    font-weight: 700;
    padding: 8px 10px 8px 15px;
    border-radius: 20px;
    margin-bottom: 5px;
  }
}
.view-switch-link{
  @include inline-block();
  :hover{
    background-color: $white;
    color: $black;
  }
  &--view-360{
    @include show-for(large);
  }
}
.view-switch-link.active{
  p{
    background-color: $white;
    color: $black;
  }
}

//.view-switch {
//  display: table-cell;
//  position: relative;
//  width: 18px;
//  height: 40px;
//  border-radius: 10px;
//  &__item {
//    height: 14px;
//    width: 14px;
//    border-radius: 50%;
//    @include horizontal-center;
//    @include transition(all 0.6s ease);
//    &--top {
//      @include transform(translate(-50%, 2px));
//    }
//    &--bottom {
//      @include transform(translate(-50%, 24px));
//    }
//  }
//}

/**************************************
            Overlay
**************************************/
.overlay {
  position: relative;
  display: block;
  height: 100%;
  overflow: hidden;
  img{
    opacity: 1;
    @include transition(opacity ease-in 0.3s);
    width: 100%;
    margin: 0 auto;
  }
  &:hover{
    .overlay-content{
      opacity: 1;
    }
    img{
      opacity: 0.5;
    }
  }
  &-content {
    font-family: arial;
    display: block;
    @include absolute-center();
    @include transition(opacity ease-in 0.3s);
    opacity: 0;
    width: 50px;
    height: 50px;
    @include border-radius(50%);
    @include font-size(40px);
    line-height: 50px;
    text-align: center;
    z-index: 10;
    &__picto{
      @include absolute-center;
      @include font-size(30px);
      line-height: 20px;
    }
    &__strap{
      display: block;
      width: 150%;
      height: 50%;
      position: absolute;
      left: 50%;
      top: 50%;
      @include transform(translate(-50%,-50%) rotate(-60deg));
    }
  }
  &-content.bg{
    border: none;
    background-color: $overlay-background;
  }
  &-content.transparent{
    border: 3px solid transparent;
  }
}

.overlay-video{
  &:hover,&:focus,&:active{
    opacity: 0.9;
    cursor: pointer;
  }
}

/**************************************
          snippet-dealers info
**************************************/
.snippet-dealers-info{
  &__wrapper{
    padding: 20px 0;
  }
  &__bloc{
    display: block;
    margin-bottom: 40px;
  }
  &__logo{
    @include inline-block();
  }
  &__phone{
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(20px);
    line-height: 24px;
  }
  &__address{
    @include font-size(12px);
    line-height: 17px;
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }
  &__cta{
    font-family: $title-font-family;
    font-weight: 700;
  }
  &--margin-bottom{
    margin-bottom: 10px;
  }
}

/***********************************************************
           Breakpoints :
            - small: 0,               //Mobile
            - medium: 768px,          //Tablet
            - large: 1024px,          //Desktop
            - xlarge: 1200px,
            - xxlarge: 1920px,
************************************************************/

@include breakpoint(medium){
  .view-switch-wrapper{
    display: block;
    width: auto;
    position: absolute;
    top: calc(100% - 135px);
    left: 10px;
  }
  .view-switch-legend{
    display: block;
    padding-right: 10px;
    p{
      @include font-size(14px);
      line-height: 18px;
      font-family: $title-font-family;
      font-weight: 700;
      padding: 8px 10px 8px 15px;
      border-radius: 20px;
      margin-bottom: 5px;
    }
  }
  .view-switch-link{
    display: block;
    :hover{
      background-color: $white;
      color: $black;
    }
  }
  .view-switch-link.active{
    p{
      background-color: $white;
      color: $black;
    }
  }
}

@include breakpoint(large){
  .view-switch-wrapper{
    top: calc(100% - 160px);
    left: 55px
  }
}

@include breakpoint(xlarge){
  .view-switch-wrapper{
    left: 65px
  }
}