.styleguide{

  &__table-pictos{
    [data-theme-sprite~=arrow-down]{
      position: relative;
      right: 0;
    }
  }
  &__button-group a {
    margin-right: 5px;
    //font-size:inherit;
  }
  &__tabs-content{
    padding: 20px 0;
  }
  &__tabs-panel{
    padding: 0;
  }
  &__title {
    text-align: center;
  }
  &__picto{
    @include font-size(20px);
  }
  &__sidebar{
    @include grid-column(4);
    display: inline-block;
    vertical-align: top;

    .promotion-preview__wrapper{
      width: 100%;
    }
    .form-sidebar{
      margin-top: 0;
    }

    .widget-social{
      position: relative;
    }
  }
  &__table-fonts{
    .breadcrumb{
      position: relative;
      width: auto;
      padding: 0;
    }
  }
}

//.page-styleguide table.styleguide__table-fonts tbody tr,
.page-styleguide table.styleguide__table-config tbody tr,
.page-styleguide table.styleguide__table-pictos tbody tr{
  border: 10px solid transparent;
  &:nth-child(2n){
    background-color: rgba(13, 177, 244, 0.1);
  }
}

.section-bloc__color{
  margin-bottom: 40px;
  border: 1px solid $border-color;
}

/*****************************************
                 theme Style
******************************************/
.theme-style{
  hr{
    margin:0;
  }

}
.theme-style-element{
  @include inline-block;
  width:33%;
  margin-bottom:25px;

  &--picto{
    width:13%;
  }

  &__color{
    @include inline-block;
    border:1px solid #cacaca;
    border-radius:50%;
    width: 50px;
    height: 50px;

  }
  &__value{
    font-style:italic;
    @include font-size(12px);
  }
}

.styleguide-element-button{
  @include inline-block;
  width:33%;
}

.styleguide-element-button-code{
  @include font-size(12px);
}

.styleguide-fonts__name{
  @include font-size(12px);
}

.classTest1{background:pink!important;color:blue!important; display: inline-block;}
.classTest2{background:yellow!important;color:red!important; display: inline-block;}
.classTest3{background:coral!important;}
.classTest4{background:steelblue!important;}
.classTest5{background:greenyellow!important;}
