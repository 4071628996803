/*********************************************************
        Block Social Alpha
**********************************************************/

.block-social-alpha{
  padding: rem-calc(20px 30px);
  @include transform(translate(0, -50%));
  @include box-shadow(0px 0px 40px 0 rgba(0,0,0,.3));
  text-align: center;
  &.block-social--border-radius{
    @include border-radius(0);
  }
  &__wrapper{
    @include clearfix();
  }
  &__item{
    position: relative;
    text-align: left;
    padding-top: 20px;
    border-top: 1px solid $border-grey;
    &:first-child{
      padding: 0 0 20px;
      border-top: 0px;
    }
  }
  &__item-icon{
    position: absolute;
    left: 0;
    top: 50%;
    @include transform(translate(0, -50%));
    max-width: 40px;
  }
  &__item-body{
    padding-left: rem-calc(50px);
  }
  &__item-title{
    @include font-size(24px);
    @include line-height(26px);
    margin: rem-calc(0 0 5px);
  }
  &__item-content{
    @include font-size(18px);
    @include line-height(22px);
  }
  &__item-cta.link__regular{
    @include font-size(14px);
  }
  &__item-fb-like{
    margin-top: rem-calc(14px);
  }
}

/***********************************
  "Block Social - Alpha"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/

@include breakpoint(medium) {
  .block-social-alpha{
    text-align: left;
    &.block-social--border-radius{
      @include border-radius(250px);
    }
    &__item{
      display: inline-block;
      width: 50%;
      float: left;
      border-left: 1px solid $border-grey;
      padding: 0;
      border-top: 0px;
      &:first-child{
        border: 0;
        padding: 0;
        .block-social-alpha__item-icon{
          left: 0;
        }
        .block-social-alpha__item-body{
          padding-left: rem-calc(80px);
        }
      }
    }
    &__item-icon{
      left: 30px;
      max-width: none;
    }
    &__item-body{
      padding-left: rem-calc(120px);
      padding-right: rem-calc(20px);
    }
  }
}


/*********************************************************
        Block Social Beta
**********************************************************/

.block-social-beta{
  padding-bottom: rem-calc(50px);
  &__title{
    @include font-size(40px);
    @include line-height(42px);
    margin: rem-calc(0 0 70px);
    text-align: center;
  }
  &__wrapper{
    @include grid-row-nest();
    @include clearfix();
  }
  &__item{
    @include grid-column(12);
    position: relative;
    margin-bottom: 30px;
  }
  &__item-icon{
    max-width: 30px;
    position: absolute;
    left: 50%;
    top: 0;
    @include transform(translate(-50%, -50%));
    z-index: 1;
  }
  &__item-image{
    width: 100%;
  }
  &__item-body{
    position: absolute;
    width: 100%;
    left: 0;
    top: 50%;
    @include transform(translate(0, -50%));
    text-align: center;
  }
  &__item-title{
    @include font-size(18px);
    @include line-height(22px);
    margin-bottom: rem-calc(4px);
  }
  &__item-content{
    @include font-size(12px);
    @include line-height(14px);
    margin: rem-calc(0 0 10px);
    p{
      margin: 0;
    }
  }
  .link__alpha-secondary{
    padding: rem-calc(8px 30px);
    @include font-size(14px);
  }
}

/***********************************
  "Block Social - Beta"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/

@include breakpoint(medium){
  .block-social-beta{
    &__wrapper{
      @include grid-row();
    }
    &__item{
      @include grid-column(6);
      margin-bottom: 0;
    }
    &__item-icon {
      max-width: 45px;
    }
    .link__alpha-secondary{
      padding: rem-calc(13px 38px);
      @include font-size(16px);
    }
  }
}

@include breakpoint(large){
  .block-social-beta{
    &__item-icon{
      max-width: 100%;
    }
    &__item-title{
      @include font-size(30px);
      @include line-height(34px);
    }
    &__item-content {
      @include font-size(16px);
      @include line-height(20px);
    }
  }
}

/*********************************************************
        Facebook feed
**********************************************************/
.news-section__sidebar .facebook-home-feed{
  display: block;
  float: none;
  margin-bottom: 30px;
}

@include breakpoint(medium){
  .news-section__sidebar .facebook-home-feed{
    @include grid-column(6);
    margin-bottom: 0;
  }
}

@include breakpoint(large) {
  .news-section__sidebar .facebook-home-feed{
    display: block;
    width: 100%;
    float: none;
    padding: 0;
    margin-bottom: 30px;
  }
}