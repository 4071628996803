.google-search{
  &__searchform{
    position: relative;
    @include grid-column-row(0);
    @include margin(20px 0);

    .widget-filter__search-input-icon{
      right: 15px;
    }
  }
}