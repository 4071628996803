.block{
  &__instances-unit{

    padding:rem-calc(20px);
    a{

    }
    li {
      padding: rem-calc(4px 0);
      list-style:none;
      &:before{
        content:'\e906';
        @extend [data-theme-sprite];
        @include inline-block();
        position:relative;
        left:-14px;
      }
    }
  }
}

.content-block{
  &__text{
    li{
      &:before{
        content:'';
        @include inline-block();
        width:6px;
        height:6px;
        border-radius: 50%;
        position:relative;
        left:-14px;
      }
    }
  }
}


/*****************************************************************
                        Block Video
*****************************************************************/
.block-video{
  &__content {
    margin: 0 15px;
    @include clearfix();
  }
}

/*****************************************************************
                        Block Inventory Listing
*****************************************************************/
.block-search-truck{
  position: relative;
  display: block;
  width: 100%;
  height: 100px;

  &__name{
    padding: 15px;
  }
  &__image{
    position: absolute;
    bottom: 0;
    right: 15px;
  }
}

.block-truck-options{
  display: none;
  &__list{
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  &__item{
    display: block;
  }
  &__picto{
    display: table-cell;
    vertical-align: middle;
    width: 20%;

    @include font-size(25px);
    line-height: 25px;
    text-align: center;
  }
  &__name{
    display: table-cell;
    vertical-align: middle;
    width: 70%;
    padding-left: 15px;
  }
  &__checkbox{
    &:checked+label, &:not(:checked)+label{
      padding-left: 0;
      padding-top: 30px;
      @include font-size(13px);
      line-height: 17px;
    }
    &:checked+label::before, &:not(:checked)+label::before{
      position: absolute;
      right: 0;
      top: 50%!important;
      left: initial!important;
      @include transform(translateY(-50%));
      background: transparent;
    }
    &:checked+label::after, &:not(:checked)+label::after{
      right: 5px;
      top: 10px!important;
      left: initial!important;
      @include transform(rotate(45deg));
      background: transparent;
    }
  }
  &__cta-wrapper{
    text-align: center;
    padding: 15px 20px;
  }
  &__cta{
    width: 100%;
  }
}


/************************************************
                  Social Pictos
************************************************/
.social-block{
  &__link{
    @include inline-block();
    margin-right: 10px;
    &:hover{
      opacity: 0.8;
    }
    &:last-child{
      margin-right: 0;
    }
  }
  &__picto{
    @include font-size(30px);
    line-height: 30px;
    &--facebook{color: map-get($social-palette, facebook);}
    &--instagram{color: map-get($social-palette, instagram);}
    &--twitter{color: map-get($social-palette, twitter);}
    &--google{color: map-get($social-palette, google);}
    &--youtube{color: map-get($social-palette, youtube);}
  }
}

/*****************************************************************
                       SEO Content
*****************************************************************/

.readmore{
  overflow:hidden;
  max-height: 44px;
  @include transition(max-height 0.5s);
  &--uncollapsed{
    max-height: 1000px;
  }
}

/*****************************************************************
                       Inventory Block
*****************************************************************/
.nav-block{
  position: relative;
  @include padding(20px 0 10px);
  border-top: 1px solid $border-grey;
  &:first-child{
    border-top: 0px;
    padding-top: 0px;
  }
  &__navigation-title{
    padding: rem-calc(0 10px 0 0);
    .title-quaternary{
      @include font-size(14px);
      @include line-height(16px);
    }
  }
  &__navigation-list{
    list-style-type: none;
    margin-left: 0;
    @include font-size(14px);
    @include line-height(16px);
  }
  &__navigation-element{
    font-family: $body-font-family;
    @include font-size(14px);
  }
  &__navigation-list-sub{
    padding: 0;
    margin: 0;
    .nav-block__navigation-anchor{
      padding: 10px 20px 0;
    }
  }
  &__navigation-list-sub .nav-block__navigation-list-sub{
    .nav-block__navigation-anchor{
      padding: 10px 20px 0;
    }
  }
  &__navigation-anchor{
    display: table; 
    width: 100%;
    padding: rem-calc(10px 20px 0 0);
    text-transform: none;
    &:hover,&:focus,&:active{
    }
  }
  &__navigation-anchor-value{
    display: table-cell;
    vertical-align: middle;
    width: 90%;
  }
  &__navigation-anchor-count{
    display: table-cell;
    vertical-align: middle;
    width: 10%;
    text-align: right;
  }

  &__navigation-image, &__navigation-name{
    @include inline-block();
  }
  &__navigation-name{
    float: none;
    margin-left: 20px;
  }
  &__navigation-element--selected{
  }
}

/**********************************************
      Loader Catalog Vehicles Filters
**********************************************/
.loader{
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.85);
  z-index: 10;
  &__spinner{
    @include horizontal-center;
    bottom: calc(100% - 200px);
    width: 100px;
    &::before{
      content: '';
      display: block;
      padding-top: 100%;
    }
  }
}

.circular{
  position: absolute;
  animation: rotate 2s linear infinite;
  transform-origin: center center;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path{
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate{
  100%{
    transform: rotate(360deg);
  }
}

@keyframes dash{
  0%{
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50%{
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100%{
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

/*****************************************************************
                       Catalog Block
*****************************************************************/
.catalog-block{
  padding-bottom: 15px;
  max-width: 233px;
  min-height: 240px;
  overflow: hidden;
  margin: 0 auto;
  &__wrapper{
    position: relative;
    @include grid-column(12);
    min-width: 240px;
    text-align: center;
    margin-bottom: 20px;
    &:last-child:not(:first-child){
      float: left;
    }
    &--hidden{
      display: none;
    }
    .showroom-rebate__wrapper{
      left: 15%;
      img{
        max-width: 40px;
        float: left;
        z-index: 2;
      }
    }
    .showroom-rebate__content{
      position: relative;
      z-index: 1;
      text-align: left;
      width: 150px;
      font-family: $title-font-family;
      font-weight: 700;
      border-radius: 5px;
      padding-top: 5px;
      margin-left: 40px;
    }
    .showroom-rebate__text{
      @include font-size(10px);
      line-height: 10px;
      text-transform: uppercase;
      padding-left: 5px;
      display: block;
    }
    .showroom-rebate__price{
      @include font-size(13px);
      line-height: 17px;
      padding-left: 5px;
      display: block;
    }
  }
  &__image{
    position: relative;
    padding-top: 20px;
    margin: 0 auto;
    width: 100%;
    max-width: 282px;
  }
  &__rebate{
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 10px;
    text-align: center;
    padding: 40px 20px 20px;
    &::before{
      content: '';
      position: absolute;
      left: -52px;
      top: -50px;
      width: 260px;
      height: 120px;
      transform: rotate(-32deg);
    }
  }
  &__spec{
    padding: 0px;
    text-align: left;
    margin: 0 0 10px;
  }
  &__name{
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(16px);
    line-height: 18px;
    padding: 0 0 10px;
  }
  .showroom-price{
    &__wrapper{
      padding: 10px 0;
      border-top: 1px solid $light-gray;
    }
    &__price-label--starting-at{
      display: block;
    }
    &__price--regular{
      @include font-size(20px);
    }
  }
  &__price{
    margin-bottom: 10px;
    height:30px;
  }
  &--hidden {
    display: none;
  }
  .link__alpha{
    padding-left: 15px;
    padding-right: 15px;
  }
  &__link{
    width: 100%;
    min-width: 180px;
  }
}


/**************************************
      Showroom
**************************************/


.showroom-price{
  &__wrapper{

  }
}

/**************************************
      Separator
**************************************/
.block__separator{
  display: block;
  position: relative;
  width: 100%;
  border-top: $code-border;
}

/**************************************
              search
**************************************/
.search__btn{
  position: absolute;
  @include vertical-center();
  right: 10px;
}
/**************************************
              more photo
**************************************/
.more-photo__button{
  &-text{
    @include vertical-center();
    left: 0;
    right: 0;
    @include font-size(12px);
    span{
      @include font-size(18px);
    }
  }
  &--more{
    height: auto;
    width: 80px;
    display: inline-block;
    vertical-align: top;
    background-color: #fff;
    text-align: center;
    position: relative;
  }
  &-icon{
    display: block;
    @include font-size(15px);
  }
}

/**************************************
              Videos
**************************************/
.video__button{
  &-text{
    @include vertical-center();
    left: 0;
    right: 0;
  }
  &--more{
    height: 42px;
    width: 80px;
    display: inline-block;
    vertical-align: top;
    background-color: #fff;
    text-align: center;
    position: relative;
  }
  &-icon{
    display: block;
    @include font-size(15px);
    position: relative;
    height: 28px;
    &:before{
      @include absolute-center();
      content:'';
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: #000;
    }
    &:after{
      content:'';
      @include absolute-center();
      @include arrow-right( 8px, 16px, #fff);
    }
  }
}

/**************************************
              more photo
**************************************/
.price__detail{
  &-wrapper{

  }
  &-price-label{
    font-weight: bold;
    @include font-size(16px);
    display: inline;
    padding-right: 5px;
  }
  &-price--regular{
    display: block;
    font-weight: bold;
    @include font-size(26px);
    line-height: 18px;
    display: inline;
    vertical-align: middle;
  }
  &-price-legend{
    @include font-size(9px);
    float: left;
  }
  &-price--old{
    float: right;
    color: $dark-gray;
    position: relative;
    text-decoration: line-through;
  }
  &-rebate {
    &__icon{
      @include font-size(25px);
    }
    &__icon,&__text,&__price{
      display: inline-block;
      vertical-align: middle;
    }
    &-wrapper{
      @include inline-block();
      font-family: $title-font-family;
      font-weight: 700;
      line-height: 20px;
    }
    &-text{
      display: block;
    }
    &-price{
      float: right;
      display: block;
      @include font-size(20px);
    }
  }
}

/**************************************
              CONSUMPTION
**************************************/
/**************************************
              CONSUMPTION
**************************************/
.vehicle{
  &__consumptions{
    @include grid-column-row(0);
    margin: 20px 0;
  }
  &__consumptions-block{
    @include inline-block();
    margin-left: 20px;
  }
  &__consumptions-block-item{
    @include inline-block();
    &:nth-child(2){
      margin-left: 40px;
      padding-left: 20px;
    }
  }
  &__consumptions-icon{
    @include inline-block(top);
    @include font-size(40px);
    line-height: 40px;
  }
  &__consumptions-text{
    margin-bottom: 15px;
  }
  &__consumptions-value{
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(18px);
    line-height: 18px;
    text-transform: uppercase;
    span{
      @include font-size(12px);
      font-family: $body-font-family;
    }
  }
  &__consumptions-label{
    margin-bottom: 10px;
  }
  &__details-specs-title{
    text-transform: uppercase;
  }
}


/*************************************
    Blox Sidebar
**************************************/
.sidebar-big-box{
  position: relative;
  @include padding(20px);
  &__title{}
  &__subtitle{}
  &__cta{}
}

/*************************************
    Big Block
**************************************/
.big-block{
  margin: 0 auto;
  @include padding(5px 0);
  &__wrapper{
    position: relative;
    @include grid-column-row(0);
  }
  &__logo{
    position: absolute;
    top: -65px;
    &--left{
      left: 40px;
    }
    &--center{
      @include horizontal-center();
    }
  }
  &__top-section{
    display: block;
    padding: 40px 0;
    min-height: 290px;

    &--newsletter-listing-promotion{
      min-height: inherit;
    }
  }
  &__bottom-section{
    display: block;
    height: auto;
    text-align: center;
  }

}

/*****************************************************************
       Homepage - Rapid Search
*****************************************************************/
.rapid-search{
  position: relative;
  @include grid-column-row(0);
  @include border-radius(10px);
  &__block{
    @include grid-column(8);
  }
  &__title{
    @include font-size(30px);
    line-height: 30px;
    text-align: left;
  }
  &__subtitle{
    display: block;
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(60px);
    line-height: 60px;
  }
  &__content{
    @include grid-column-row(0);
    margin: rem-calc(20px 0);
  }
  &__filters{
    @include grid-column-size(8);
  }
  &__cta{
    @include grid-column-row(0);
    text-align: left;
  }
}

/*****************************************************************
        Sidebar - Custom Block (picto + Text + CTA)
*****************************************************************/
.custom-block-sidebar{
  @include inline-block();
  position: relative;
  width: 100%;
  min-height: 150px;
  &__top-section{
    display: block;
    padding: 20px;
  }
  &__image{
    display: table-cell;
    vertical-align: middle;
    float: left;
    padding-right: 15px;
    &--picto{
      @include font-size(65px);
      line-height: 65px;
      float: none;
    }
  }
  &__text{
    display: table-cell;
    vertical-align: middle;
  }
  &__bottom-section{
    display: block;
    padding: 10px 0;
    background-color: rgba(255,255,255,0.1);
    text-align: center;
  }
}


/***********************************
    Business hours
***********************************/
.business-hours{
  &__department-name{
    margin:0;
  }
  &__department-phone{
    font-family: $title-font-family;
    font-weight: 700;
    margin-bottom: 15px;
  }
  &__main-wrapper{
    @include inline-block();
    margin-bottom:rem-calc(15px);
    margin-top: 15px;
  }
  &__opening-day{
    padding:0 40px 0 0;
  }
  &__opening-wrapper{
    margin:0;
    text-align:left;
  }
  &__opening-title{
    font-family: $title-font-family;
    font-weight: 700;
    margin-bottom: 10px;
  }
}

/**************************
  Bloc Hours - Departments
**************************/
.block-hours{
  @include grid-column();

  &__main-wrapper{
    @include grid-column(12, 0);
  }
  &__opening-wrapper--inner-container{

  }
  &__opening-hours{
    text-align: right;
  }
  &__opening-wrapper td{
    @include font-size(12px);
    @include line-height(24px);
    border: 0;
  }
  &__department-phone,
  &__department-name{
    @include font-size(14px);
    font-weight: 700;
    text-align: left;
  }
  &__department-name-icon{
    font-size: 1.5rem;
  }
  &__department-name-text{
    margin-bottom: 5px;
  }
  &__department-phone {
    width: 100%;
    padding-top: rem-calc(14px);
    border-top: 1px solid $light-gray;
  }
  &__department-phone-text{
      padding: 5px 0;
  }
}


/***********************************************************
           Breakpoints :
            - small: 0,               //Mobile
            - medium: 768px,          //Tablet
            - large: 1024px,          //Desktop
            - xlarge: 1200px,
            - xxlarge: 1920px,
************************************************************/

//@include breakpoint(medium){
//  .catalog-block__wrapper{
//    @include grid-column(6);
//    @include grid-column-end;
//  }
//  .block-hours{
//    &__main-wrapper{
//      @include grid-column(6);
//      @include grid-column-end;
//    }
//  }
//}
//
//@include breakpoint(tablet){
//  .block-hours{
//    &__main-wrapper{
//      @include grid-column(4);
//      @include grid-column-end;
//    }
//  }
//}
//
//@include breakpoint(large){
//  .catalog-block__wrapper{
//    @include grid-column(4);
//    @include grid-column-end;
//  }
//}