/**********************************************
      Pagination (Listing Inventaire)
**********************************************/
.pagination{

  align-content: flex-end;
  align-items: center;
  display:flex;
  flex-direction: row;
  justify-content: flex-end;
  .pagination{

    &__item{
      margin-right: rem-calc(15px);
      text-align: center;
      a{
        padding:0;
      }
    }
    &__page-arrows{
      border-radius: 50%;
      cursor: pointer;
      height: 40px;
      line-height: 42px;
      transition: all 0.2s;
      width: 20px;
      background-color: transparent;
      color: $light-gray;
      &--disabled{
        cursor: not-allowed;
        pointer-events: none;
      }
    }
    &__page-arrows-text{
      color: inherit;
      @include font-size(20px);
      @include line-height(44px);
    }
    &__page-button-text{
      @include font-size(16px);
      font-family: $title-font-family;
      font-weight: 700;
    }
  }
}
