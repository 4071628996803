/*********************************************************
        Block News Alpha
**********************************************************/
.block-news-alpha{
  clear: both;
  text-align: center;
  &__header{
    margin: rem-calc(0 0 35px);
  }
  &__title{
    @include font-size(42px);
    @include line-height(44px);
    margin: rem-calc(0 0 15px);
  }
  &__links .block-news-alpha__cta{
    @include font-size(14px);
  }
  &__main{
    text-align: center;
    .bx-wrapper{
      margin: 0 auto;
    }
  }
  &__article-btn{
    display: block;
    position: relative;
    &:hover .block-news-alpha__article-overlay{
      opacity: 1;
    }
  }
  &__article-picture{
    width: 100%;
  }
  &__article-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(255,255,255,0.5);
    @include transition(all 0.3s ease);
    opacity: 0;
  }
  &__article-title{
    position: absolute;
    width: 100%;
    padding: rem-calc(0 10px);
    left: 0;
    top: 50%;
    @include transform(translate(0, -50%));
    @include font-size(16px);
    @include line-height(20px);
  }
  &__slider-controls{
    margin: rem-calc(20px 0 0);
    text-align: center;
  }
  &__slider-controls-wrapper{
    display: inline-block;
    @include clearfix();
  }
  &__slider-prev,
  &__slider-next{
    display: inline-block;
    width: 35px;
    height: 35px;
    float: left;
    @include font-size(30px);
    @include line-height(32px);
    margin-left: rem-calc(12px);
    @include transition(all 0.3s ease);
    opacity: 0.7;
    &:hover{
      opacity: 1;
    }
  }
  &__slider-prev{
    margin-left: 0;
    margin-right: rem-calc(12px);
  }
  &__slider-pager{
    display: inline-block;
    float: left;
    .bx-pager-item{
      display: inline-block;
      float: left;
    }
    .bx-pager-link{
      display: block;
      margin: rem-calc(10px 8px);
      width: 12px;
      height: 12px;
      border: 2px solid;
      overflow: hidden;
      text-indent: -3000px;
      @include border-radius(100%);
      background: transparent;
    }
  }
}

/***********************************
  "Block News Preview Alpha"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/

@include breakpoint(medium) {
  .block-news-alpha{
    &__title{
      @include font-size(50px);
      @include line-height(52px);
    }
  }
}


/*********************************************************
        News Preview Beta
**********************************************************/
.news-preview-beta{
  margin-bottom: 40px;
  .title__quaternary{
    margin: rem-calc(3px 0 4px);
  }
  .wysiwyg{
    @include font-size(12px);
    @include line-height(16px);
  }
  &__article-item{
    margin-bottom: 30px;
    &--first{
      .news-preview-beta__picture-container{
        height: 380px;
      }
    }
    iframe{
      width: 100%;
    }
  }
  &__picture-container{
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    height: 220px;
    margin-bottom: rem-calc(5px);
    &:hover{
      opacity: 0.5;
    }
  }
}

/***********************************
  "Block News Preview Beta"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/

@include breakpoint(medium){
  .news-preview-beta{
    @include grid-column-row();
    &__article-item{
      @include grid-column(6);
      margin-bottom: 0;
      &--first {
        margin-bottom: 30px;
        @include grid-column(12);
      }
    }
  }
}

@include breakpoint(large){
  .news-preview-beta{
    margin-bottom: 0;
  }
}