.review-listing{
  &__header{
    height:514px;
    padding-top:rem-calc(107px);
    text-align:center;

    &-container h1{
      padding: 0;
    }

    .picto-play{
      margin: 30px auto;
      top: initial;
      &:hover,&:focus,&:active{
        cursor:pointer;
      }

      &:before{
        @include absolute-center;
        @include css-triangle(8px,$black,right);
      }
    }
  }

  &__main{
    @include grid-row-nest();
    &__content,
    &__sidebar{
      @include grid-column(12);
    }
    &__sidebar nav{
      margin-top: rem-calc(20px);
    }
  }

  &__title .review__title{
    margin: 0;
  }

  &__review-item{
    @include padding(30px 0);
    border-bottom:1px solid $border-color;
  }

  &__body-container{
    @include inline-block;
    padding-left:rem-calc(12px);
    vertical-align: top;

    &--full-size{
      width:100%;
    }
  }

  &__picture-container{
    @include inline-block;
    vertical-align: top;
    padding-left:rem-calc(12px);
    margin-bottom: rem-calc(10px);
  }
  &__author{
    @include font-size(13px);
  }
  &__preview p{
    margin: 0;
  }
}

/***********************************************************
           Breakpoints :
            - small: 0,               //Mobile
            - medium: 640px,
            - tablet: 768px           //Tablet
            - large: 1024px,          //Desktop
            - xlarge: 1200px,
            - xxlarge: 1920px,
************************************************************/

@include breakpoint(medium) {
  .review-listing__main{
    &__content,
    &__sidebar{
      @include grid-column(6);
      &--full{
        @include grid-column(12);
      }
    }
  }
}
