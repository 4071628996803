@import url('https://fonts.googleapis.com/css?family=Lato|Raleway:300,400,700');


@font-face {
  font-family: 'icomoon';
  src:url('/css/fonts/icomoon.eot?');
  src:url('/css/fonts/icomoon.eot?#iefix') format('embedded-opentype'),
  url('/css/fonts/icomoon.woff2') format('woff2'),
  url('/css/fonts/icomoon.woff?') format('woff'),
  url('/css/fonts/icomoon.ttf?') format('ttf'),
  url('/css/fonts/icomoon.svg?#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;  
}
