/***********************************
      Catalog Slider Generic
************************************/
.catalog-slider{
  .link__regular:hover{
    text-decoration: none;
  }
  &__title-wrapper{}
  &__title{
    @include font-size(65px);
    line-height: 65px;
  }
  &__subtitle{
    @include font-size(36px);
    line-height: 40px;
    margin-bottom: 20px;
  }
  &__ctas{
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  &__cta-item{
    @include inline-block;
    margin-right: 20px;
    &:last-child{
      margin-right: 0;
    }
  }
}

[data-vehicle-info]{
  opacity: 0;
  pointer-events: none;
  cursor: not-allowed;
}

/***********************************
        Catalog Slider Alpha
************************************/
.catalog-slider-alpha{
  padding: 40px 0;
  &__slider-wrapper{
    max-width: 570px!important;
    margin: 0 auto;
    .bx-viewport{
      overflow: visible!important;
    }
  }
  &__slider-block{
    padding: 0 35px;
    opacity: 0.3;
    &.active{
      opacity: 1;
    }
    &.active [data-vehicle-info]{
      opacity: 1;
      pointer-events: initial;
      cursor: pointer;
      @include transition(all 1.5s ease-in);
    }
  }
  &__controls{
    @include show-for(large);
    position: relative;
    width: 500px;
    top: -205px;
    left: 50%;
    @include transform(translateX(-50%));
  }
  &__control-left, &__control-right{
    position: absolute;
    width: 40px;
    height: 40px;
    &:hover{
      opacity: 0.2;
    }
  }
  &__control-left{
    left: 0;
  }
  &__control-right{
    right: 0;
  }
}

/***********************************
        Catalog Slider Beta
************************************/
.catalog-slider-beta{
  padding: 40px 0;
  &__slider-wrapper{
    margin: 0 auto;
  }
  &__slider-block{
    margin: 0 auto;
    &.active [data-vehicle-info]{
      opacity: 1;
      pointer-events: initial;
      cursor: pointer;
      @include transition(all 1.5s ease-in);
    }
  }
  &__controls{
    position: relative;
    text-align: center;
  }
  &__control-left, &__control-right{
    @include inline-block();
    width: 20px;
    height: 20px;
    &:hover{
      opacity: 0.2;
    }
  }
  &__slider-pager-wrapper,
  &__slider-pager{
    @include inline-block();
  }
  &__slider-pager-item{
    &.active{
      opacity: 0.5;
    }
  }
}


/***********************************
        Catalog Slider Charlie
************************************/
.catalog-slider-charlie{
  padding: 40px 0;
  &__slider-wrapper{
    max-width: 760px!important;
    margin: 0 auto;
    .bx-viewport{
      overflow: visible!important;
    }
  }
  &__slider-block{
    margin: 0 35px;
    opacity: 0.3;
    &.active{
      opacity: 1;
    }
    &.active [data-vehicle-info]{
      opacity: 1;
      pointer-events: initial;
      cursor: pointer;
      @include transition(all 1.5s ease-in);
    }
  }
  &__controls{
    position: relative;
    text-align: center;
  }
  &__slider-pager-wrapper,
  &__slider-pager{
    @include inline-block();
  }
  &__slider-pager-item{
    &::after{
      content: '';
      display: block;
      border-bottom: 3px solid;
      @include transform(scaleX(0));
      @include transition(transform 250ms ease-in-out);
    }
    &.active::after{
      @include transform(scaleX(1));
    }
  }
  &__slider-pager-value{
    display: block;
    margin-bottom: 5px;
    &.active {
      opacity: 0.5;
    }
  }
}

/***********************************
        Catalog Slider Delta
************************************/
.catalog-slider-delta{
  max-width: $global-width;
  margin: 0 auto;
  padding: 40px 0;
  overflow: hidden;
  &__slider-wrapper{
    margin: 0 auto;
    .bx-viewport{
      overflow: visible!important;
    }
  }
  &__slider-block{
    padding: 0;
    opacity: 0.3;
    margin: 0;
    @include padding(0 100px 0 0);
    position: relative;
    min-height: 1px;
    >div{
      position: relative;
      left: 50%;
      top: 85px;
      width: 100%;
      @include transform(translate(-50%, 0));
      @include transition(all 0.5s ease);
      z-index: 1;
    }
    &:not(.active) p[class*="__vehicle-name"]{
      @include font-size(16px);
      @include line-height(16px);
    }
    &.active{
      opacity: 1;
      @include padding(0 50px);
      >div{
        width: 600px;
        top: -32px;
      }
      +.catalog-slider-delta__slider-block{
        @include padding(0 0 0 100px);
      }
    }
    &.active [data-vehicle-info]{
      opacity: 1;
      pointer-events: initial;
      cursor: pointer;
      @include transition(all 1.5s ease-in);
    }
  }
  &__controls{
    position: relative;
    width: 500px;
    top: -268px;
    left: 50%;
    @include transform(translateX(-50%));
  }
  &__control-left, &__control-right{
    position: absolute;
    width: 40px;
    height: 40px;
    &:hover{
      opacity: 0.2;
    }
  }
  &__control-left{
    left: 0;
  }
  &__control-right{
    right: 0;
  }
}


