/***********************************************
      Header Version Mobile
************************************************/
.header-mobile{
  @include grid-column-row();
  @include hide-for(large);
  position: fixed;
  top: 0;
  left: 0;
  padding: 8px 20px;
  min-height: 90px;
  @include box-shadow(0px 8px 30px 0px rgba(0,0,0,0.3));
  transition: 0.6s top ease-in-out;
  z-index: 10;
  &--visible {
    top: 0;
    transition: 0.4s top ease-in-out;
  }
  &--hidden {
    top: -90px;
  }
  &--noborder{
    border-bottom: 0;
  }
  &__button{
    @include grid-column(1);
  }
  &__logo{
    @include grid-column(7);
    @include vertical-center();
  }
  &__logo-image, &__logo-name{
    @include inline-block(middle);
  }
  &__logo-name{
    @include font-size(28px);
    line-height: 28px;
    margin-left: 30px;
  }
  &__phone{
    position: absolute;
    top: 32px;
    right: 60px;
    @include font-size(28px);
    @include line-height(24px);
    .snippet-phone__department-name--header{
      display: block;
    }
    .snippet-phone__department-wrapper--header{
        @include vertical-center;
        margin: 0;
    }
  }
}

/***********************************************
      Header Menu Version Mobile
************************************************/
.menu-mobile {
  display: block;
  margin-top: 50px;
  padding: 0 0 50px;
  background-color: transparent !important;
  a{
    padding: 0;
  }
  &__address{
    @include font-size(14px);
    line-height: 16px;
    margin: 0;
    padding: 0 20px;
  }
  &__phone{
    padding: 0 20px;
  }
  &__logo-image{
    @include inline-block();
    height: 50px;
    padding: 0 20px;
    margin-bottom: 10px;
  }
  &__social{
    display: block;
    padding: 0 20px;
    margin: 10px 0;
  }
  .social-block__link{
    @include inline-block();
  }
  &__phone{
    padding: 0 20px;
    .snippet-phone__department-phone--menu{
      display: inline;
    }
  }
  &__cta{
    padding: 0 20px;
    margin-bottom: 20px;
  }
  &__cta-anchor{
    width: 100%;
    padding: 0 8px 0 0;
    text-align: left;
  }
  &__cta-icon {
    @include inline-block(middle);
    margin-right: 8px;
    padding: 8px 12px;
  }
  .header{
    &__nav-list-element{
      display: block;
      border-bottom: 1px solid;
      text-align: left;
      padding: 0;
      &:last-of-type{
        margin-bottom: 20px;
      }
    }
    &__nav-list-anchor{
      position: relative;
      padding: 15px 20px;
      border-right: none;
    }
    &__nav-submenu{
      padding-top: 0;
      margin-left: 20px;
      box-shadow: none;
      background: transparent !important;
      &:before{
        display: none;
      }
    }
    &__compare--mobile{
      @include padding(15px 20px);
    }
  }
}

/*********************************************
  Modifier Default Accordion Menu Arrow
*********************************************/
.is-accordion-submenu-parent>a::after{
  content: '+';
  display: block;
  @include vertical-center;
  right: 1rem;
}

.is-accordion-submenu-parent[aria-expanded=true]>a::after{
  content: '-';
  display: block;
  @include vertical-center;
  right: 1rem;
}

/***********************************************
      Header Version Desktop
************************************************/
.header-desktop{
  height: 140px;
  @include show-for(large);
  @include box-shadow(0px 8px 30px 0px rgba(0,0,0,0.3));
  @include grid-col();
}

.header{
  position:relative;
  .page-wrapper{
    padding: 0;
    position: static;
    overflow: visible;
  }
  &__menu{
    @include font-size(14px);
    line-height: 22px;
    font-weight: 700;
  }
  &__topbar{
    @include grid-row(12, 'collapse', $global-width, true , 0);
    text-align: right;
    padding: 0 30px;
    margin: 10px 0;
    &-compare-label{
      margin: 0 rem-calc(5px) 0 rem-calc(10px);
    }
  }
  &__middlebar{
    @include grid-row(12, 'collapse', $global-width, true, 0);
    padding-top: rem-calc(20px);
    position: relative;
    z-index: 6;
    .float-right{
      text-align: right;
    }
  }
  &__middlebar-right-element{
    @include inline-block();
    text-align: right;
    margin: rem-calc(20px) 0 0;
    &:first-of-type{
      padding-right: rem-calc(8px);
      margin-bottom: 10px;
    }
  }
  &__organization-promo{
    @include show-for(tablet);
    position: absolute;
    left: 50%;
    top: 0;
    @include transform(translate(-50%, 0));
  }
  &__phone{
    @include font-size(14px);
    line-height: 18px;
    font-family: $title-font-family;
    font-weight: 700;
    margin-top: rem-calc(5px);
    margin-bottom: rem-calc(8px);
    .snippet-phone__department-wrapper--header{
      margin-left: rem-calc(20px);
      &:first-child{
        margin-left: 0;
      }
    }
  }
  &__address{
    @include font-size(12px);
    line-height: 15px;
    .snippet-address__address{
      display: initial;
    }
    .snippet-address__link{
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  &__menu-cta{
    position: relative;
    right: 10px;
  }
  &__menu-cta-link {
    padding: 0 12px 0 0;
  }
  &__menu-cta-icon {
    @include inline-block(middle);
    margin-right: 12px;
    padding: 8px 12px;
    img {
      height: 20px;
    }
  }
  &__compare{
    position: relative;
    padding: 0 20px;
    &:hover{
      cursor: pointer;
    }
    &--mobile{
      padding: 0 30px;
    }
  }
  &__compare-icon,
  &__compare-text,
  &__compare-value{
    @include font-size(14px);
    line-height: 14px;
  }
  &__link-map,
  &__lang{
    text-decoration: underline;

    &:hover{
      text-decoration: none;
    }
  }
  &__mainmenu{
    .page-wrapper {
      display: table;
      margin: 0 auto;
      padding: 20px 0;
    }
    &--noborder{
      border-bottom: 0;
    }
  }
  &__logo {
    @include grid-col();
  }
  &__logo-name{
    @include font-size(35px);
    line-height: 35px;
    margin-left: 40px;
  }
  &__nav{
    position: static;
    display: table-cell;
    vertical-align: bottom;
    z-index: 5;
    @include grid-column(12,0);
    @include grid-column-position(center);
    max-width: $global-width;
    .header__nav-list .header__nav-list-element .header__nav-list-anchor{
      @include padding(0 10px);
      &:after{
        @include hide-for(medium);
      }
    }
    .header__nav-list .header__nav-list-element .header__nav-submenu__catalog-list-vehicle-item{
      padding: 0;
      background-color: transparent;
    }
  }
  &__nav-list{
    display: table;
    width: 100%;
    margin: 0;
    position: static;
    float: right;
  }
  &__nav-list-element{
    display: table-cell;
    text-align: right;
    background-color: transparent;
    &:last-child{
      position: relative;
      background-color: transparent;
      cursor: pointer;
    }
    &--full-width{
      position: static !important;

      .header__nav-submenu{
        overflow: hidden;
        text-align: left;
        margin-left: 0;
        left: 0;
        z-index: 11;
        width: 100%;
        height: auto;
      }
    }
  }
  &__nav-list-anchor{
    @include box-sizing();
    text-transform: uppercase;
  }
  &__nav-submenu{
    overflow: hidden;
    text-align: left;
    margin: 0;
    left: 0;
    top: calc(100% + 2px) !important;
    border: none;
    display: none;
    @include box-shadow(0 3px 6px 0 rgba(0,0,0,.15));
    @include font-size(14px);
    line-height: 22px;
    z-index: 10;
    padding-top: rem-calc(10px);
    &:before{
      content: "";
      display: block;
      width: 100%;
      height: 10px;
      position: absolute;
      left: 0;
      top: -10px;
      @include box-shadow(0px 0px 15px 0px rgba(0,0,0,0.7));
    }
    &__catalog-list-vehicle{
      margin: 0 auto;
      @include padding(0 0 35px);
      @include clearfix();
      .page-wrapper{
        @include padding(0 25px);
      }
      .header__nav-submenu__catalog-list-vehicle{
        padding: 0;
        &:before{
          display: none;
        }
      }
    }
    &__catalog-list-vehicle-col{
      //@include grid-column(1 of 4);
      //margin-bottom: rem-calc(10px);
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width:100%;
      &:last-child:not(:first-child) {
        float: left;
        margin-bottom: rem-calc(10px);
      }
      &:last-child {
        .header__nav-submenu__catalog-list-vehicle-item{
          border-bottom: none;
        }
      }
    }
    &__catalog-list-vehicle-item{
      @include clearfix();
      height: 47px;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      width:24%;
      margin-right:rem-calc(10px);
    }

    &__catalog-list-vehicle-name{
      @include font-size(15px);
      @include grid-column(5 of 8);
      margin-top: 14px;
    }

    &__catalog-list-vehicle-image{
      @include grid-column(3 of 8);
      padding-left: 0 !important;
      height: 100%;
    }

    &__catalog-infos{
      @include grid-column(3 of 8);
    }

    &__catalog-hub{
      @include inline-block();
      margin-left: 20px;
      &:first-child{
        margin-left: 0;
      }
    }
    &__catalog-hub-wrapper{
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 35px;
      padding: 0;
      text-align: left;
    }
    &__catalog-hub-container{
      @extend .page-wrapper;
      padding: 0 rem-calc(35px) !important;
    }
    .header__nav-submenu__catalog-hub .header__nav-submenu__catalog-hub-type{
      @include padding(11px 0);
      text-decoration: underline;
      &:hover{
        text-decoration: none;
      }
    }
  }
  &__nav-submenu-title{
    text-transform: uppercase;
    margin: 50px 0 20px;
  }
  &__nav-submenu-element{
    display: block;
    min-width: 200px;
    width: 100%;
    @include box-sizing();
    padding: 0;
    white-space: nowrap;
  }
  &__nav-submenu-anchor{
    display: block;
    position: relative;
    padding: 10px 20px!important;
  }
  .sticky{
    z-index: 999;
    @include box-shadow(0 5px 15px -5px rgba(0,0,0,0.25));
  }
}

/***********************************************************
           Breakpoints :
            - small: 0,               //Mobile
            - medium: 640px,
            - tablet: 768px           //Tablet
            - large: 1024px,          //Desktop
            - xlarge: 1200px,
            - xxlarge: 1920px,
************************************************************/
