.history{
  &__section{
    @include grid-row();
    padding-top:30px;
    padding-bottom:30px;
    &__description{
      @include grid-column(8);
    }
    &__img{
      @include grid-column(4);
    }
  }
}