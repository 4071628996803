/* Variables Pages */
$duration:                0.2s;

/*************************************
   Menu - button (hamburger)
**************************************/

.menu-icon{
  position: absolute;
  @include vertical-center;
  height: auto;
  &::after{
    display: none;
  }
  span{
    @include vertical-center;
    @include horizontal-center;
    display: block;
    width: 30px;
    height: 4px;
    border-radius: 4px;
    @include transform(translate(-50%,-50%));
    &::before, &::after{
      width: 30px;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      height: 4px;
      border-radius: 4px;
      @include transform(translateY(-8px));
      transition: transform $duration;
    }
    &::after{
      @include transform(translateY(8px));
    }
  }
  &:hover{
    span::before{
      @include transform(translateY(-8px));
    }
    span::after{
      @include transform(translateY(8px));
    }
  }
}

/*************************************
   Menu - button (hamburger) -> Cross
**************************************/
.menu-icon-cross{
  transition: transform $duration;
  z-index: 10;
  span::before, span::after{
    @include transform-origin(50% 50%);
  }
  span{
    transition: background $duration, transform $duration;
  }
  &.is-opened{
    @include transform(rotate(180deg));
    span{
      background: transparent;
    }
    span::before{
      @include transform(translateY(0) rotate(45deg));
    }
    span::after{
      @include transform(translateY(0) rotate(-45deg));
    }
  }
}

.page-mobile__button{
  @include hide-for(large);
  .menu-icon{
    width: 30px;
    height: 30px;
    top: 46px;
    right: 20px;
    z-index: 11;
    transition: left $offcanvas-transition-length ease;

    &.is-opened{
      position: fixed;
      //right: map-get($offcanvas-sizes, small) + 10px;
      right: 20px;
      top: 30px;
      //span::before,
      //span::after{
      //  background-color: $white;
      //}
    }
  }
}


/***********************************
              Base
************************************/
a {
  text-decoration: none;
  color: inherit;
  &:hover, &:focus, &:active{
    color:inherit;
  }
}



.link{
  /***********************************
            Link Alpha
  ************************************/
  &__alpha{
    text-transform: uppercase;
    @include button-base();
    @include padding(15px 30px);
    @include font-size(14px);
    @include line-height(16px);
    font-weight: bold;
    transition: 0.2s ease;
    &-secondary{
      @include padding(13px 30px);
      border: 2px solid;
      background: transparent;
    }
    &-tertiary{
      @include padding(13px 30px);
      border: 2px solid;
      background: transparent;
    }
  }



  /***********************************
          Link Beta
  ************************************/
  &__beta{
    text-transform: uppercase;
    @include button-base();
    @include padding(10px 30px);
    @include font-size(14px);
    @include line-height(16px);
    font-weight: bold;
    &-secondary{
      border: 2px solid;
      @include padding(8px 28px);
      &:hover{
        border: 0;
        @include padding(10px 30px);
      }
    }
  }

  /***********************************
          Link Charlie
  ************************************/
  &__charlie{
    position: relative;
    @include button-base();
    @include padding(10px 18px 10px 38px);
    @include font-size(16px);
    @include line-height(16px);
    &-picto{
      position: absolute;
      left: 12px;
      top: 50%;
      @include transform(translate(0, -50%));
      @include font-size(20px);
    }
  }

  /***********************************
          Link Echo
  ************************************/
  &__echo{
    @include button-base();
    @include padding(15px 80px 15px 30px);
    @include font-size(20px);
    @include line-height(20px);
    position: relative;
    &-picto{
      position: absolute;
      right: 3px;
      top: 3px;
      @include font-size(16px);
      @include line-height(44px);
      width: 44px;
      height: 44px;
      text-align: center;
      @include border-radius(100%);
      overflow: hidden;
      &:before,
      &:after{
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 2px;
        top: 0;
        @include transition(all 0.4s ease);
      }
      &:after{
        @extend [data-theme-sprite];
        content: "\e914";
        left: -100%;
        @include line-height(44px);
      }
    }
    &:hover .link__echo-picto{
      &:before{
        left: 100%;
      }
      &:after{
        left: 2px;
      }
    }
    &-secondary, &-tertiary{
      border: 2px solid;
      @include padding(13px 28px);
      &:hover{
        border: 0;
        @include padding(15px 30px);
      }
    }
  }

  /***********************************
        Link Gamma
************************************/
  &__gamma{
    @include button-base();
    @include padding(14px 30px);
    @include font-size(15px);
    @include line-height(15px);
    font-weight:400;
    @include box-shadow(0 1px 3px 0 $border-color);
    text-decoration: none !important;
  }

  /***********************************
         Link Ghost
  ************************************/
  &__ghost{
    @include button-base();
    border: 2px solid;
    @include padding(12px 28px);
    @include font-size(15px);
    @include line-height(15px);
    text-decoration: none !important;
  }

  /***********************************
          Link Delta
  ************************************/
  &__delta{
    text-align: center;
    width: 140px;
    display: inline-block;
    &-picto{
      display: block;
      position: relative;
      @include font-size(58px);
      width: 100%;
      height: 90px;
      @include border-radius(5px);
      &:before, img{
        @include absolute-center;
      }
    }
    &-text{
      @include font-size(14px);
      line-height: 20px;
      padding: 10px 0;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
    }
    &-arrow{
      @include font-size(12px);
      border-radius: 50%;
      width: 40px;
      height: 40px;
      border:1px solid;
      position: relative;
      display: block;
      margin: 0 auto;
      &:before{
        @include absolute-center;
      }
    }
    &:hover .link__delta-picto:after {
      opacity: 1;
      bottom: -30%;
      left: -30%;
      transition-property: left, bottom, opacity;
      transition-duration: 0.7s, 0.7s, 0.15s;
      transition-timing-function: ease;
    }
  }

  /***********************************
          Link Regular
  ************************************/
  &__regular{
    text-decoration: underline;
    &:hover, &:focus, &:active{
      text-decoration: none;
    }
    &-primary{
      @include font-size(14px);
      line-height: 14px;
    }
    &-secondary{
      font-family: $title-font-family;
      font-weight: 700;
      @include font-size(12px);
      line-height: 14px;
      text-decoration: none;
    }
    &-terciary{
      font-family: $body-font-family;
      @include font-size(14px);
      line-height: 14px;
      text-decoration: underline;
    }
  }

  /***********************************
          Link Social
  ************************************/
  &__social{
    @include inline-block(top);
    margin-right:rem-calc(15px);

    @include font-size(12px);
    @include line-height(24px);

    &:last-child{
      margin-right:rem-calc(0)
    }

    &:hover,&:focus,&:active{

      .link__social-text{
        text-decoration: none;
      }
    }
  }

  &__social-text{
    text-decoration:underline;
  }
  &__social-picto{
    font-size: 14px;
    margin-right: rem-calc(10px);
  }

  /**************************************
      Link BxSlider Primary
  **************************************/
  &__slider{
    position: relative;
    width: 60px;
    height: 60px;
    transition: all 0.2s;
    cursor: pointer;
    &--disabled{
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5;
    }
  }
  &__slider-arrows-text{
    @include absolute-center;
    @include font-size(20px);
    line-height: 20px;
    color: inherit;
  }
}
