
/******************************************************
      Page PRINT - Used, New, Catalog
*******************************************************/

.print-container {
  margin: rem-calc(20px);
  width: 930px;
  height:1170px;
  color: #999;
  .content-top {
    width: 100%;
    float: left;
    margin-bottom: rem-calc(15px);
    position: relative;
    z-index: 2;
  }
  .content-middle {
    width: 450px;
    float: left;
  }
  .content-right {
    width: 435px;
    max-height: 895px;
    float: left;
    @include margin(5px 0 10px 10px);
  }
  .header {
    margin-bottom: rem-calc(20px);
    img {
      float: left;
      max-width: 400px;
      max-height: 100px;
    }
  }
  .info {
    @include margin(10px 20px 0 0);
    float: right;
    width: 450px;
    @include font-size(16px);
    @include line-height(18px);
    text-align: right;
  }
  .name {
    color: #000;
    font-size: 170%;
    line-height: 26px;
  }
  .price {
    color:#f00;
  }
  .logo {
    float:left;
  }
  .name-wrapper {
    margin-left: rem-calc(10px);
  }
  .photos {
    img {
      margin: rem-calc(3px);
      border: 1px #ccc solid;
    }
  }
  .description-wrapper {
    margin-left: rem-calc(5px);
    .legendTitle {
      font-family: $title-font-family;
      font-weight: 700;
      margin-top: rem-calc(20px);
      width: 100%;
      @include font-size(16px);
      @include line-height(16px);
      text-transform: uppercase;
    }
    .legend {
      @include margin(2px 0 5px);
      @include font-size(14px);
      @include line-height(16px);
      img {
        width: 40px;
        height: 20px;
        margin: rem-calc(5px);
        border: 1px #CCC solid;
      }
    }
  }
  .legend-bold {
    font-weight: bold;
  }
  .content-bottom {
    margin-top: rem-calc(10px);
    width: 100%;
  }
  .small-letters {
    @include inline-block();
    text-align: left;
    @include font-size(10px);
  }
  .showroom-rebate__wrapper{
    @include font-size(14px);
    position: static;
  }
  .showroom-price__price--regular {
    @include inline-block();
    @include font-size(30px);
    @include margin(7px 0 15px);
  }
  .showroom-price__price--old {
    @include font-size(18px);
    text-decoration: line-through;
  }
}