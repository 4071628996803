.page-financing{
  &__main-content{
    @include grid-row();
  }

  &__block{
    @include inline-block;
    vertical-align: top;

    &:first-child{
      width:290px;
      padding-right: 50px;
    }

    &:last-child{
      padding-left:50px;

      border-left:1px solid $medium-gray;
    }

  }
}


/***********************************************************
           Breakpoints :
            - small: 0,               //Mobile
            - medium: 640px,
            - tablet: 768px           //Tablet
            - large: 1024px,          //Desktop
            - xlarge: 1200px,
            - xxlarge: 1920px,
************************************************************/

@include breakpoint(medium){

}