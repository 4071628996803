/***********************************
        Home Inventory Delta
************************************/
.home-inventory-delta{
  max-width: 375px;
  @include box-shadow(0px 2px 15px 4px rgba(0,0,0,0.2));
  &__wrapper{
    @include padding(10px);
  }
  &__title{
    @include font-size(24px);
    @include line-height(26px);
    margin: rem-calc(0 0 15px);
  }
  &__list{
    display: table;
    width: 100%;
  }
  &__item{
    @include clearfix();
    display: table-row;
    margin: rem-calc(0 0 10px);
  }
  &__item-image{
    display: table-cell;
    width: 33%;
    @include padding(0 0 10px);
    vertical-align: top;
  }
  &__item-specs{
    display: table-cell;
    width: 67%;
    @include padding(0 0 10px 10px);
    vertical-align: middle;
  }
  &__item-name{
    @include font-size(16px);
    @include line-height(20px);
    &:hover{
      text-decoration: underline;
    }
  }
  &__item-price-current{
    @include font-size(16px);
    @include line-height(20px);
  }
  &__item-km{
    @include font-size(16px);
    @include line-height(20px);
  }
}

/***********************************
  "Home Inventory - Delta"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/
@include breakpoint(medium){
  .home-inventory-delta__wrapper{
    @include padding(26px 40px 20px);
  }
}