.sitemap{
  &__block{
    @include grid-column-row();
    margin-bottom: 40px;
  }
  &__block-nexted{
    @include grid-row-nest();
  }
  &__block-content{
    @include grid-column(4);
    @include grid-column-end;
  }
  &__block-content-2-column{
    @include grid-column(6);
    @include grid-column-end;
  }
  &__block-content-list{
    margin:0;
  }
  &__block-content-list-element{
    display:block;
    padding: 5px 0;
    &:hover{
      text-decoration: underline;
    }
  }
  &__block-title, &__block-subtitle{
    @include inline-block();
    @include grid-column-row();
    margin-bottom: 10px;
  }
  &__block-subtitle{
    margin: 20px 0;
  }
  &__block-content-subtitle{
    margin:0;
    padding:15px 0 0;
  }
}
