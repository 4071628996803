.page-404{
  &__main-content{
 //   border:1px solid $light-gray;
    padding:23px;
    text-align: center;
    h1{
      text-align: center;
    }
  }
  &__link-content{
    margin:10px 0;
  }
  /*&__picto{
    border-radius: 50%;
    background-color: rgb(151, 184, 67);
    width: 66px;
    height: 66px;
    z-index: 134;
  }*/
}
