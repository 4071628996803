/***********************************
    Inventory Preview Alpha
************************************/
.inventory-preview-alpha{
  position: relative;
  @include border-top-radius(5px);

  &__wrapper{
    margin-bottom: 20px;
  }
  &__preview-image {
    position: relative;
    @include border-top-radius(5px);
    overflow: hidden;
  }
  &__preview-clearance {
    position: absolute;
    left: 10px;
    top: 10px;
    @include border-radius(5px);
    z-index: 1;
  }
  &__preview-clearance-text{
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(12px);
    text-transform: uppercase;
    line-height: 20px;
    margin-left: 10px;
    margin-right: 20px;
  }
  &__preview-biweekly-wrapper{
    @include inline-block(bottom);
  }
  &__preview-biweekly-price{
    @include inline-block(bottom);
    @include font-size(20px);
    line-height: 22px;
  }
  &__preview-biweekly-label,
  &__preview-price-separator{
    @include inline-block(bottom);
    @include font-size(12px);
    line-height: 14px;
  }
  &__preview-price-wrapper{
    @include inline-block(bottom);
  }
  &__preview-price-label{
    font-family: $body-font-family;
    display: block;
    font-weight: normal;
  }
  &__preview-price {
    margin-bottom: 10px;
    padding: 10px;
    border-bottom: 1px solid $border-grey;
  }
  &__preview-price-current {
    @include inline-block(bottom);
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(13px);
    line-height: 15px;
  }
  &__preview-price-strike {
    @include inline-block(bottom);
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(10px);
    line-height: 12px;
    text-decoration: line-through;
  }
  &__preview-specs {
    @include padding(15px 0);
    text-align: center;
  }
  &__preview-name{
    display: block;
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(16px);
    line-height: 18px;
    margin-bottom: 5px;
    padding: 0 10px;
    max-height: 35px;
    overflow: hidden;
  }
  &__preview-transmission, &__preview-km{
    @include font-size(12px);
    line-height: 20px;
    padding: 5px 0;
    display: inline-block;
    .icon{
      padding-right: 5px;
    }
  }
  &__preview-transmission{
    margin-right: 15px;
    .icon{
      font-size: 14px;
    }
  }
  &__preview-km{
    .icon{
      position: relative;
    }
  }
  &__preview-pictos {
    margin-top: 8px;
    img{
      margin: 0 auto;
      display: inline-block;
      vertical-align: middle;
      &.picto__certified{
        padding-right: 5px;
      }
    }
  }
  &__preview-actions{
    text-align: center;
    margin: 10px 0;
  }
}

/***********************************
    Inventory Preview Beta
************************************/
.home-inventory-beta{
  @include grid-column(12);
  float: none;
}

.inventory-preview-beta{
  display: table;
  width: 100%;
  &__wrapper{
    margin-bottom: rem-calc(20px);
  }
  &__preview-image,
  &__preview-specs{
    display: block;
    padding: rem-calc(10px);
    vertical-align: top;
  }
  &__preview-image{
    width: auto;
  }
  &__preview-name{
    display: block;
    @include font-size(20px);
    @include line-height(22px);
    text-transform: uppercase;
    padding-top: rem-calc(5px);
    margin-bottom: rem-calc(10px);
  }
  &__preview-infos{
    @include clearfix();
    @include font-size(13px);
    @include line-height(15px);
    margin-bottom: rem-calc(10px);
  }
  &__preview-transmission,
  &__preview-km{
    display: inline;
  }
  &__preview-price{
    @include clearfix();
    margin-bottom: rem-calc(15px);
  }
  &__preview-biweekly-wrapper,
  &__preview-price-separator,
  &__preview-price-wrapper,
  &__preview-price-current,
  &__preview-price-strike{
    display: inline;
  }
  &__preview-biweekly-price{
    @include font-size(20px);
    @include line-height(22px);
  }
  &__preview-biweekly-label{
    @include font-size(12px);
    @include line-height(14px);
  }
  &__preview-price-separator,
  &__preview-price-current,
  &__preview-price-strike{
    @include font-size(13px);
    @include line-height(15px);
  }
  &__preview-actions .link__alpha{
    display: block;
  }
}

/***********************************
  "Inventory Preview - Beta"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/
@include breakpoint(medium){
  .inventory-preview-beta{
    &__preview-image,
    &__preview-specs{
      display: table-cell;
    }
    &__preview-image{
      width: 256px;
    }
    &__preview-actions .link__alpha{
      display: inline-block;
    }
  }
}

@include breakpoint(large){
  .home-inventory-beta{
    @include grid-column(8);
  }
}

/***********************************
    Inventory Preview Charlie
************************************/
.inventory-preview-charlie{
  position: relative;
  &__wrapper{
    margin-bottom: rem-calc(20px);
  }
  &__preview-price{
    @include clearfix();
  }
  &__preview-biweekly-wrapper{
    padding: rem-calc(10px 0 10px 10px);
  }
  &__preview-biweekly-aslowas,
  &__preview-biweekly-label{
    display: block;
    @include font-size(12px);
    @include line-height(14px);
  }
  &__preview-biweekly-price{
    @include font-size(20px);
    @include line-height(22px);
  }
  &__preview-biweekly-label{
    display: inline;
  }
  &__preview-price-wrapper{
    padding: rem-calc(8px 0);
    @include font-size(12px);
    @include line-height(14px);
    text-align: center;
  }
  &__preview-name{
    display: block;
    padding: rem-calc(15px 10px);
    @include font-size(16px);
    @include line-height(18px);
    word-wrap: break-word;
  }
  &__preview-infos{
    padding: rem-calc(0 10px 15px);
    @include font-size(13px);
    @include line-height(15px);
  }
  &__preview-transmission,
  &__preview-km{
    display: inline;
  }
  &__preview-actions{
    padding-bottom: 42px;
  }
  &__preview-actions .link__alpha{
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    @include border-radius(0);
    border: 0;
    border-top: 1px solid $border-grey;
    padding: rem-calc(13px 0);
    text-align: center;
  }
}

/***********************************
  "Inventory Preview - Charlie"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/
@include breakpoint(medium){
  .inventory-preview-charlie{
    &__preview-biweekly-wrapper{
      display: inline-block;
      float: left;
      width: 70%;
      padding: rem-calc(10px 0 10px 20px);
    }
    &__preview-price-wrapper{
      display: inline-block;
      float: left;
      width: 30%;
    }
    &__preview-name{
      padding: rem-calc(15px 20px);
    }
    &__preview-infos{
      padding: rem-calc(0 20px 15px);
    }
  }
}


/***********************************
    Inventory Preview Echo
************************************/
.inventory-preview-echo{
  position: relative;

  &__wrapper{
    margin-bottom: 20px;
  }
  &__preview-image {
    position: relative;
    border: 1px solid $border-grey;
    border-bottom: 0;
    overflow: hidden;
  }
  &__preview-price-label{
    font-family: $body-font-family;
    display: inline;
    font-weight: normal;
  }
  &__preview-price {
    margin: 10px 0 20px;
  }
  &__preview-price-current {
    @include inline-block(bottom);
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(24px);
    line-height: 22px;
  }
  &__preview-price-strike {
    @include inline-block(bottom);
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(12px);
    line-height: 22px;
    text-decoration: line-through;
  }
  &__preview-specs {
    @include padding(15px);
    text-align: left;
    border: 1px solid $border-grey;
    border-bottom: 0;
    .block__separator{
      border-color: $border-grey;
    }
  }
  &__preview-name{
    display: block;
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(16px);
    line-height: 18px;
    margin-bottom: 7px;
    height: 40px;
    overflow: hidden;
  }
  &__preview-tagline{
    @include font-size(11px);
    line-height: 14px;
    margin-bottom: 10px;
    padding: 5px 0;
  }
  &__preview-transmission, &__preview-km{
    @include font-size(12px);
    line-height: 20px;
    padding: 5px 0;
    display: inline-block;
    .icon{
      padding-right: 5px;
    }
  }
  &__preview-transmission{
    margin-right: 15px;
    .icon{
      font-size: 14px;
    }
  }
  &__preview-km{
    .icon{
      position: relative;
    }
  }
  &__preview-pictos {
    margin-top: 8px;
    img{
      margin: 0 auto;
      display: inline-block;
      vertical-align: middle;
      &.picto__certified{
        padding-right: 5px;
      }
    }
  }
}

/***********************************
    Inventory Preview Fox
************************************/
.inventory-preview-fox{
  position: relative;

  &__wrapper{
    margin-bottom: 20px;
  }
  &__preview-image {
    position: relative;
    border: 1px solid $border-grey;
    border-bottom: 0;
    overflow: hidden;
  }
  &__preview-clearance {
    position: absolute;
    left: 10px;
    top: 10px;
    @include border-radius(5px);
    z-index: 1;
  }
  &__preview-clearance-text{
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(12px);
    text-transform: uppercase;
    line-height: 20px;
    margin-left: 10px;
    margin-right: 20px;
  }
  &__preview-price-label{
    font-family: $body-font-family;
    display: block;
    font-weight: normal;
  }
  &__preview-price {
    margin: 10px 0;
  }
  &__preview-price-current {
    @include inline-block(bottom);
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(24px);
    line-height: 22px;
  }
  &__preview-price-strike {
    @include inline-block(bottom);
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(12px);
    line-height: 22px;
    text-decoration: line-through;
  }
  &__preview-price-value{
    position: relative;
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      top: 7px;
      right: 0;
      @include transform(rotate(10deg));
    }
  }
  &__preview-specs {
    @include padding(15px);
    text-align: center;
    border: 1px solid $border-grey;
    border-bottom: 0;
    .block__separator{
      border-color: $border-grey;
    }
  }
  &__preview-name{
    display: block;
    font-family: $title-font-family;
    font-weight: 700;
    @include font-size(16px);
    line-height: 18px;
    text-transform: uppercase;
    margin-bottom: 7px;
    height: 40px;
    overflow: hidden;
  }
  &__preview-tagline{
    @include font-size(11px);
    line-height: 14px;
    margin-bottom: 10px;
    padding: 5px 0;
  }
  &__preview-transmission, &__preview-km{
    @include font-size(12px);
    line-height: 20px;
    padding: 5px 0;
    display: inline-block;
    .icon{
      padding-right: 5px;
    }
  }
  &__preview-transmission{
    margin-right: 15px;
    .icon{
      font-size: 14px;
    }
  }
  &__preview-km{
    .icon{
      position: relative;
    }
  }
  &__preview-pictos {
    margin-top: 8px;
    img{
      margin: 0 auto;
      display: inline-block;
      vertical-align: middle;
      &.picto__certified{
        padding-right: 5px;
      }
    }
  }
  &__preview-actions{
    text-align: center;
    @include padding(0 15px 20px);
    border: 1px solid $border-grey;
    border-top: 0;
  }
  .link__alpha{
    padding: 13px 15px;
  }
}

/***********************************
  "Inventory Preview - Fox"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/
@include breakpoint(medium) {
  .inventory-preview-fox{
    .link__alpha{
      padding: 13px 38px;
    }
  }
}