.listing-employee{
  &__wrapper{
    @include grid-column-row();
  }
  &__department{
    @include grid-column(12,0);
    margin-bottom: 40px;
  }
  &__department-title{
    @include grid-column(12,0);
  }
  &__department-employee{
    @include grid-row-nest();
  }
  &__department-employee-item{
    @include grid-column(12);
    @include grid-column-end;
  }
  &__department-employee-wrapper{
    position: relative;
    margin-bottom: 20px;
    min-height:275px;
  }
  &__department-employee-image-container{
    overflow: hidden;
  }
  &__department-employee-image{
    margin: 0 auto;
  }
  &__department-employee-description{
    padding: 20px;
    &--full{
      @include absolute-center;
    }
  }
  &__department-employee-name{
    margin-bottom: 0;
  }
  &__department-employee-job{
    margin-bottom: 10px;
  }
  &__department-employee-email{
    @include font-size(14px);
    line-height: 16px;
    [data-theme-sprite]{
      @include font-size(12px);
    }
  }
  &__department-employee-phone-container{
    margin: 5px 0 0;
    .link__regular{
      text-decoration: none;
    }
  }
  &__department-select{
    @include show-for-only(small);
    @include grid-column-row();
  }
}

.employee__ninjabox-info_bio{
  margin-top: 3px;
}

.ninjabox__team{
  @include grid-row-nest();
  @include clearfix();
  .employee-info{
    @include grid-column(9);
    text-align: left;
  }
  .employee-info__wrapper{
    padding: rem-calc(20px);
  }
  .employee-name{
    margin: 0;
  }
  .employee-job{
    margin: 0 0 10px;
  }
  .employee-bio{
    @include font-size(14px);
    margin: 0 0 10px;
  }
  .bio-video iframe{
    max-width: 100%;
  }
  .employee-email,
  .employee-phone{
    display: inline-block;
  }
  .employee-email{
    margin-right: 10px;
  }
  .employee-img{
    @include show-for(medium);
    @include grid-column(3);
  }
}


/***********************************************************
           Breakpoints :
            - small: 0,               //Mobile
            - medium: 640px,          //Medium
            - tablet: 768px,          //Tablet
            - large: 1024px,          //Desktop
            - xlarge: 1200px,
            - xxlarge: 1920px,
************************************************************/

@include breakpoint(medium){
  .listing-employee{
    &__department-employee-item{
      @include grid-column(6);
      @include grid-column-end;
    }
  }
}

@include breakpoint(tablet){
  .listing-employee{
    &__department-employee-item{
      @include grid-column(4);
      @include grid-column-end;
    }
  }
  .ninjabox__team{
    .employee-info__wrapper{
      padding: rem-calc(30px);
    }
  }

}