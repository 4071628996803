.wrapper-static .page-wrapper{
  position: static;
}

/********************************************
          Self Promotion Section
********************************************/
.self-promotion-section {
  @include grid-row();
  max-width: none;
  &__image {
    padding: 30px 80px;
    img {
      margin: 0 auto;
    }
  }
  &__info {
    padding: 20px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  &__title.title__primary {
    @include font-size(25px);
    line-height: 27px;
    font-weight: 700;
    text-transform: none;
    padding: 0;
    margin: 0 0 15px;
  }
  &__content {
    margin-bottom: 10px;
  }
  &__ctas {
    .link__alpha {
      display: block;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
    [data-theme-sprite] {
      margin-right: 8px;
    }
  }
}

@include breakpoint(medium) {
  .self-promotion-section {
    display: table;
    width: 100%;
    &__image, &__info {
      display: table-cell;
      float: none !important;
      vertical-align: middle;
    }
    &__image {
      @include grid-col(5);
      img {
        margin-right: 10px;
      }
    }
    &__info {
      @include grid-col(7, 0);
      padding: 80px 20px;
    }
    &__info-wrapper {
      display: table-cell;
      vertical-align: middle;
    }
    &__ctas {
      .link__alpha {
        @include inline-block(top);
        &:not(:last-child) {
          margin-bottom: 10px;
          margin-right: 15px;
        }
      }
    }
  }
}

@include breakpoint(large) {
  .self-promotion-section {
    &__info {
      padding: 135px 30px 90px;
    }

    &__title.title__primary {
      max-width: 550px;
      @include font-size(35px);
      line-height: 37px;
    }
    &__content {
      @include font-size(20px);
      line-height: 22px;
      max-width: 370px;
      p:last-child {
        @include font-size(15px);
      }
    }
  }
}

@include breakpoint(xlarge) {
  .self-promotion-section {
    &__ctas {
      .link__alpha {
        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }
  }
}

/********************************************
       Rapid Search Section
********************************************/


/********************************************
       Catalog Section
********************************************/
.catalog-section{
  padding: 60px 0;
  &__wrapper{
    padding-top: 0;
    padding-bottom: 0;
  }
}

/********************************************
       Service Section
********************************************/
.service-section{
  position: relative;
  &__wrapper{
    padding: 0;
    position: static;
  }
}

/******************************************
      News && Social (FB) Section
******************************************/
.news-section{
  &__wrapper{
    @include grid-column-row();
  }
  &__title{
    @include grid-column(10);
    @include grid-column-position(center);
    text-align: center;
    @include font-size(44px);
    line-height: 46px;
  }
  &__content{
    @include grid-column(12);
  }
  &__sidebar{
    @include grid-column(12);
    text-align: center;
  }
}

@include breakpoint(large) {
  .social-section {
    .page-wrapper {
      padding: 100px 0;
    }
  }
}


/**************************************
      About Section
**************************************/
.about-section{
  position: relative;
  padding-bottom: 67.5px; // Offsets .block-ctas-alpha__wrapper's position top of -67.5px
  &__wrapper{
    padding: 0;
    position: static;
  }
  .page-wrapper{
    padding-left: 0;
    padding-right: 0;
  }
}

/***********************************
  "About Section"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/

@include breakpoint(medium){
  .about-section .page-wrapper{
    padding-left: rem-calc(10px);
    padding-right: rem-calc(10px);
  }
}

@include breakpoint(large){
  .about-section{
    &__wrapper{
      padding: 40px 0;
    }
  }
}

/**************************************
      Map Section
**************************************/
.map-section{
  &__google-map{
    width: 100%;
    text-align: center;
    overflow: hidden;
    position: relative;
    #mapContainer{
      width: 100%!important;
    }
  }
  &__anchor{
    display: block;
    &:hover{
      opacity: 0.5;
    }
  }
  &__image{
    margin: 0 auto;
  }
}

/***********************************************************
           Breakpoints :
            - small: 0,               //Mobile
            - medium: 768px,          //Tablet
            - large: 1024px,          //Desktop
            - xlarge: 1200px,
            - xxlarge: 1920px,
************************************************************/

@include breakpoint(large){
  .about-section{
    &__wrapper {
      padding: 80px 0;
    }
    &__content{
      @include grid-col(10,40);
      @include grid-column-position(center);
    }
  }
  .news-section{
    &__content{
      @include grid-column(8);
    }
    &__sidebar{
      @include grid-column(4);
    }
  }
}

@include breakpoint(xxlarge){
  .about-section{
    &__content{
      @include grid-col(12,0);
      @include grid-column-position(center);
      padding: 15px 0 60px;
    }
  }
}