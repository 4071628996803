.service-section .page-wrapper{
  overflow: visible;
  @include clearfix();
}

/*********************************************************
        Block Service Alpha
**********************************************************/
.block-service-alpha{
  @include grid-column-row();
  position: relative;
  padding: 60px 15px;
  &__bg-image{
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  &__wrapper{
    @include grid-column(12);
    position: relative;
    z-index: 2;
  }
  &__title{
    @include font-size(34px);
    line-height: 36px;
  }
  &__infos{
    @include inline-block;
    width: 100%;
    margin: 30px 0;
    ul{
      margin-left: 0;
    }
    li{
      position: relative;
      padding-left: 28px;
      list-style-type: none;
      &:before{
        @extend [data-theme-sprite];
        content: "\e906";
        position: absolute;
        left: 0;
        top: 4px;
      }
    }
  }
  &__cta-wrapper{
    display: block;
    width: 100%;
    text-align: center;
  }
  &__cta{
    display: block;
    padding: 15px 20px;
    margin-bottom: 20px;
    margin-right: 0;
  }
  &__picture{
    max-width: 250px;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    opacity: 0.3;
  }
}

/***********************************
  "Block Service - Alpha"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/

@include breakpoint(medium){
  .block-service-alpha{
    padding: 60px;
    &__title{
      @include font-size(44px);
      line-height: 46px;
    }
    &__cta-wrapper{
      text-align: left;
    }
    &__cta{
      display: inline-block;
      padding: 15px 40px;
      margin-bottom: 0;
      margin-right: 20px;
    }
    &__picture{
      opacity: 1;
    }
  }
}

@include breakpoint(xlarge){
  .block-service-alpha{
    @include grid-column(60%);
    position: inherit;
    &__wrapper{
      @include grid-column(70%);
      @include grid-column-end();
    }
    &__bg-image{
      left: -40%;
    }
    &__picture{
      max-width: none;
      right: 40%;
    }
  }
}


/*********************************************************
        Block Service Beta
**********************************************************/

.block-service-beta{
  min-height: 347px;
  background-size: cover;
  background-repeat: no-repeat;
  @include transform(translate(0, -50%));
  position: relative;
  margin: 0;
  &+div{
    margin-top: -100px;
  }
  &__wrapper{
    position: absolute;
    width: 100%;
    left: 0;
    top: 50%;
    @include transform(translate(0, -50%));
    text-align: center;
    padding: rem-calc(0 20px);
  }
  &__title{
    @include font-size(40px);
    @include line-height(40px);
    margin-bottom: rem-calc(20px);
    span{
      display: block;
      @include font-size(34px);
    }
  }
  &__picto{
    position: absolute;
    left: 50%;
    top: 0;
    @include transform(translate(-50%, -50%));
    max-width: 65px;
  }
  &__cta{
    display: block;
    padding: 15px 20px;
  }
}

/***********************************
  "Block Service - Beta"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/
@include breakpoint(medium){
  .block-service-beta{
    margin: rem-calc(0 20px);
    &__wrapper{
      padding: rem-calc(0);
    }
    &__title{
      @include font-size(60px);
      @include line-height(60px);
      span{
        @include font-size(44px);
      }
    }
    &__picto{
      max-width: none;
    }
    &__cta{
      display: inline-block;
      padding: 15px 40px;
    }
  }
}

@include breakpoint(large){
  .block-service-beta{
    @include border-radius(10px);
    @include box-shadow(0px 0px 15px 0px rgba(0,0,0,0.5));
  }
}