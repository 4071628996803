/*********************************************************
        Rapid-Search Alpha
**********************************************************/
.rapid-search-alpha{
  position: relative;
  padding: 60px 0;
  .link__regular:hover{
    text-decoration: none;
  }
  [data-widget-dropdown-button-text]{
    top: -5px;
    &.text-updated{
      top: 0;
    }
  }
  &__wrapper{
    @include grid-column-row();
    @include grid-column-position(center);
    text-align: center;
  }
  &__logo{
    @include show-for(large);
    @include horizontal-center;
    bottom: calc(100% - 40px);
  }
  &__title-wrapper{
    margin: 0 0 15px;
  }
  &__title{
    @include font-size(30px);
    line-height: 32px;
  }
  &__subtitle{
    @include font-size(40px);
    line-height: 40px;
  }
  &__filters{
    margin: 0 10px;
    &--margin-bottom{
      margin-bottom: 25px;
    }
  }
  &__filters-item{
    @include font-size(34px);
    line-height: 40px;
    opacity: 0.5;

    &:hover, &.active{
      opacity: 1;
      cursor: pointer;
    }
    &:first-of-type{
      margin-bottom: 30px;
      margin-right: 0;
    }
  }
  &__item{
    width: 100%;
    margin: 0 auto 15px;
  }
  &__cta{
    display: block;
    margin: 20px 0;
    padding: 15px 55px 15px 15px;
    @include font-size(18px);
  }
  &__ctas-wrapper{
    margin: 10px 0;
  }
  &__ctas-item{
    margin-right: 25px;
    &:last-child{
      margin-right: 0;
    }
  }
}

/***********************************
  "Rapid Search Alpha"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/

@include breakpoint(medium){
  .rapid-search-alpha{
    &__filters{
      margin: 0;
    }
    &__filters-item{
      @include font-size(40px);
      &:first-of-type{
        margin-bottom: 0;
        margin-right: 40px;
      }
    }
    &__item{
      width: 25%;
      max-width: 180px;
      margin: 0 20px 0 0;
    }
    &__cta{
      display: inline-block;
      margin: 20px 0;
      padding: 15px 80px 15px 30px;
      @include font-size(20px);
    }
  }
}

@include breakpoint(large){
  .rapid-search-alpha{
    &__title-wrapper{
      margin: 15px 0;
    }
    &__filters-item{
      @include font-size(65px);
      line-height: 45px;
    }
    &__cta{
      margin:10px 0;
    }
  }
}

/********************************************************************
        Rapid-Search Beta
*********************************************************************/
.rapid-search-beta{
  position: relative;
  padding: 40px 0 60px;
  .link__regular:hover{
    text-decoration: none;
  }
  [data-widget-dropdown-button-text]{
    top: -5px;
    &.text-updated{
      top: 0;
    }
  }
  &__wrapper{
    @include grid-column-row();
    @include grid-column-position(center);
    text-align: center;
  }
  &__content{
    margin: 25px 0;
  }
  &__logo{
    @include show-for(large);
    @include horizontal-center;
    bottom: calc(100% - 40px);
  }
  &__title-wrapper{
    margin: 0 0 15px;
  }
  &__title{
    @include font-size(30px);
    line-height: 32px;
  }
  &__subtitle{
    @include font-size(40px);
    line-height: 40px;
  }
  &__filters{
    margin: 0 10px;
    &--margin-bottom{
      margin-bottom: 25px;
    }
  }
  &__filters-item{
    @include font-size(40px);
    line-height: 40px;
    opacity: 0.5;

    &:hover, &.active{
      opacity: 1;
      cursor: pointer;
    }
    &:first-of-type{
      margin-right: 40px;
    }
  }
  &__item{
    width: 100%;
    margin-bottom: 15px;
  }
  &__cta{
    display: block;
    margin: 20px 0;
  }
  &__ctas{
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 20px 0;
  }
  &__ctas-item{
    margin-right: 25px;
    &:last-child{
      margin-right: 0;
    }
  }
}

/***********************************
  "Rapid Search Beta"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/

@include breakpoint(medium){
  .rapid-search-beta{
    &__filters{
      margin: 0;
    }
    &__item{
      width: 25%;
      max-width: 180px;
      margin-bottom: 0;
      margin-right: 20px;
    }
    &__cta{
      display: inline-block;
    }
  }
}

@include breakpoint(large){
  .rapid-search-beta{
    &__title-wrapper{
      margin: 15px 0;
    }
    &__filters-item{
      @include font-size(65px);
      line-height: 45px;
    }
    &__cta{
      margin:10px 0;
    }
  }
}

/*********************************************************************
        Rapid-Search Charlie
*********************************************************************/
.rapid-search-charlie{
  position: relative;
  @include grid-column-row(0);
  padding: 20px 15px;
  .link__regular:hover{
    text-decoration: none;
  }
  [data-widget-dropdown-button-text]{
    top: -5px;
    &.text-updated{
      top: 0;
    }
  }
  &__wrapper{
    margin: 0 5px;
    text-align: center;
  }
  &__logo{
    @include show-for(large);
    @include horizontal-center;
    bottom: calc(100% - 40px);
  }
  &__title-wrapper{
    margin: 0 0 15px;
  }
  &__title{
    @include font-size(30px);
    line-height: 32px;
  }
  &__subtitle{
    @include font-size(40px);
    line-height: 40px;
  }
  &__item{
    margin-bottom: 10px;
  }
  &__cta{
    display: block;
    margin:10px 0;
  }
}

/***********************************
  "Rapid Search Charlie"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/

@include breakpoint(medium){
  .rapid-search-charlie{
    &__wrapper{
      @include grid-column(8);
      @include grid-column-position(center);
    }
    &__cta{
      display: inline-block;
    }
  }
}

@include breakpoint(large){
  .rapid-search-charlie{
    @include grid-column-size(4);
    padding: 40px 30px 30px;
    &__wrapper{
      @include grid-column(12,0);
    }
    &__title-wrapper{
      margin: 15px 0;
    }
  }
}


/********************************************************************
        Rapid-Search Delta
*********************************************************************/
.rapid-search-delta{
  position: relative;
  padding: rem-calc(35px 0);
  background-size: cover !important;
  .link__regular:hover{
    text-decoration: none;
  }
  [data-widget-dropdown-button-text]{
    top: -5px;
    &.text-updated{
      top: 0;
    }
  }
  &__wrapper{
    @include grid-column-row();
    @include grid-column-position(center);
    @include clearfix();
    text-align: center;
  }
  &__header:after{
    content: "";
    display: block;
    clear: both;
  }
  &__logo-wrapper{
    display: inline-block;
    float: none;
    max-width: 70px;
    margin-right: rem-calc(0);
    position: absolute;
    left: 50%;
    top: 0;
    @include transform(translate(-50%, -50%));
  }
  &__header-content{
    display: inline-block;
    float: none;
    text-align: center;
    max-width: none;
  }
  &__content{
    clear: both;
    margin: 15px 10px 0;
  }
  &__title-wrapper{
    margin: 0 0 10px;
  }
  &__title{
    @include font-size(36px);
    line-height: 36px;
  }
  &__subtitle{
    @include font-size(40px);
    line-height: 40px;
  }
  &__filters{
    display: inline-block;
    &:not(.active){
      display: none;
    }
    &--margin-bottom{
      margin-bottom: 25px;
    }
  }
  &__filters-item{
    @include font-size(40px);
    line-height: 40px;
    opacity: 0.5;

    &:hover, &.active{
      opacity: 1;
      cursor: pointer;
    }
    &:first-of-type{
      margin-right: 40px;
    }
  }
  &__item{
    width: 100%;
    margin-right: 0;
    @include border-top-radius(0);
    .widget-dropdown__button{
      @include border-top-radius(0);
      padding: rem-calc(14px 10px 5px);
    }
    &:first-child{
      @include border-top-radius(5px);
      @include border-bottom-radius(0);
      .widget-dropdown__button{
        @include border-top-radius(5px);
        @include border-bottom-radius(0);
      }
    }
  }
  &__cta{
    display: block;
    margin: 20px 0 0;
    padding: rem-calc(15px 30px);
  }
  &__ctas{
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 20px 0;
  }
  &__ctas-item{
    margin-right: 25px;
    &:last-child{
      margin-right: 0;
    }
  }
}

/***********************************
  "Rapid Search Delta"

  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/

@include breakpoint(medium){
  .rapid-search-delta{
    &__content{
      margin: 15px 0 0;
    }
    &__item{
      width: auto;
      min-width: 170px;
      float: left;
      @include border-left-radius(0);
      @include border-right-radius(5px);
      .widget-dropdown__button{
        @include border-left-radius(0);
        @include border-right-radius(5px);
      }
      &:first-child{
        @include border-left-radius(5px);
        @include border-right-radius(0);
        .widget-dropdown__button{
          @include border-left-radius(5px);
          @include border-right-radius(0);
        }
      }
    }
    &__cta{
      display: inline-block;
      float: left;
      margin: 0 0 0 20px;
    }
  }
}

@include breakpoint(large){
  .rapid-search-delta{
    &__header{
      display: inline-block;
      float: left;
      width: 40%;
      &:after{
        clear: none;
      }
    }
    &__logo-wrapper{
      float: left;
      margin-right: rem-calc(35px);
      position: static;
      @include transform(translate(0, 0));
    }
    &__header-content{
      float: left;
      text-align: left;
      max-width: 295px;
    }
    &__content{
      display: inline-block;
      float: right;
      width: 60%;
      clear: none;
    }
    &__filters-item{
      @include font-size(65px);
      line-height: 45px;
    }
    &__cta{
      margin: 0 0 0 20px;
    }
  }
}
