.promotion-preview{
  &__wrapper{
    @include inline-block;
    margin-bottom:20px;
  }
  &--shadow{
    @include default-box-shadow;
  }
  &__description{
    padding:10px;
    @include clearfix();
  }
  &__title{
    @include grid-col(100%,0);
    @include font-size(16px);
  }
  .link__regular{
    @include font-size(16px);
    &:hover{
      text-decoration: none;
    }
  }
}

.listing-promotion{
  .widget-tabs{
    padding-top: 0;
  }
  &__listing-wrapper{
    @include grid-layout(1, '.promotion-preview__wrapper',20);
  }
  &__slider-wrapper{
    .promotion-preview__wrapper{
      padding: 10px;
    }
  }
  &__fb-banner-wrapper{
    padding: 148px 0 40px;
    text-align: center;
  }
  &__seo{
    .big-block{
      margin: -150px 0 0;
      padding: 0 0 70px;
    }
    .big-block__top-section{
      min-height: 180px;
    }
    .widget-newsletter__subscribe{
      float: left;
    }
  }
  &__form{
    background-size: cover;
  }
  &__text-seo{
    @include grid-column-position(center);
    padding: 10px;
    margin: 10px;
  }
  &__html-seo{
    @include columns-divider(1,rem-calc(50px), transparent, none,0);
    .title{
      @include font-size(23px);
      @include line-height(27px);
    }
  }
}


/***********************************************************
           Breakpoints :
            - small: 0,               //Mobile
            - medium: 640px,
            - tablet: 768px           //Tablet
            - large: 1024px,          //Desktop
            - xlarge: 1200px,
            - xxlarge: 1920px,
************************************************************/

@include breakpoint(medium){
  .listing-promotion {
    &__listing-wrapper {
      @include grid-layout(2, '.promotion-preview__wrapper', 20);
    }
    &__text-seo{
      padding: 40px;
      margin: 40px;
    }
  }
}

@include breakpoint(tablet){
  .listing-promotion {
    &__listing-wrapper {
      @include grid-layout(3, '.promotion-preview__wrapper', 20);
    }
    &__html-seo{
      @include columns-divider(2,rem-calc(50px), transparent, none,0);
    }
  }
}