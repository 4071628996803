.page-gallery{
  .title__primary{
    margin-bottom: 25px;
  }
}

.gallery-media-preview{
  @include grid-column(12);
  min-width: 240px;
  margin-bottom: 20px;
}

.inventory-vehicle-gallery-popup{
  &__gallery-bxslider{
    .slick-list{
      height: 100%!important;
    }
  }
}
/**************************************************************************************
        breakpoint(medium)
***************************************************************************************/
@include breakpoint(medium) {
  .gallery-media-preview{
    @include grid-column(6);
  }
}

@include breakpoint(tablet) {
  .gallery-media-preview {
    @include grid-column(6);
    @include grid-column-end;
  }
}

@include breakpoint(large) {
  .gallery-media-preview {
      @include grid-column(4);
      @include grid-column-end;
  }
}

@include breakpoint(xlarge){
  .gallery-media-preview{
    @include grid-column(4);
    @include grid-column-end;
  }
}

