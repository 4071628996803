/**********************************************
    Promo Header Slide (Homepage)
***********************************************/
.promo-header-slide{
  position: relative;
  clear: both;
  &__list{
    margin: 0;
    padding: 0;
    list-style: none;
  }
  &__list-item-anchor{
    display: block;
    overflow: hidden;
  }
  &__list-item-image{
    position: relative;
    left: 50%;
    @include transform(translateX(-50%));
    max-width: none !important;
    height: 350px !important;
    width: auto !important;
  }
  &__controls{
    @include show-for(xlarge);
    @include absolute-center;
    max-width: $global-width;
    width: 100%;
  }
  &__control-picto{
    @include font-size(20px);
    line-height: 20px;
  }
  &__control-left, &__control-right{
    @include vertical-center;
    width: 40px;
    height: 40px;
    @include border-radius(50%);
    @include box-shadow(0 3px 6px 0 rgba(68, 68, 68, 0.3));
  }
  &__control-left{
    left: 0;
  }
  &__control-right{
    right: 0;
  }
  &__pagers{
    @include horizontal-center;
    bottom: 40px;
    text-align: center;
  }
  &__pager-item{
    @include inline-block();
    width: 11px;
    height: 11px;
    @include border-radius(50%);
    margin-right: 20px;
    &:last-child{
      margin-right: 0;
    }
    &.active{
      width: 24px;
      height: 24px;
    }
  }
}

/***********************************
  Breakpoints:
  - medium: 768px(tablet)
  - large: 1024px
  - xlarge: 1200px
  - xxlarge: 1920px
************************************/

@include breakpoint(tablet){
  .promo-header-slide{
    &__list-item-image{
      height: auto !important;
    }
    .bx-wrapper{
      .bx-viewport{
        height: auto !important;
      }
    }
  }
}

@include breakpoint(large) {
  .promo-header-slide__list-item-image {
    max-width: 100% !important;
    height: auto !important;
  }
  .promo-header-slide {
    .bx-wrapper {
      .bx-viewport {
        max-height: 1125px !important;
      }
    }
  }
}