/*****************************************************************
            Style des images brisées
*****************************************************************/
img {
  display: block;
  position: relative;
  height: auto;
  text-align: center;

  &::before {
    content: " ";
    display: block;
    position: absolute;
    top: -10px;
    left: 0;
    height: calc(100% + 10px);

    background-color: rgb(230, 230, 230);
    border-radius: 5px;
  }

  &::after {
    content: "\e922  " attr(alt);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-family: icomoon;
    color: rgb(100, 100, 100);
    background:$white;
  }
}

[data-theme-style="bodyPrimary_bgColor"]{
  img::after{
    background-color:$light-gray;
  }
}

[data-theme-style="bodySecondary_bgColor"]{
  img::after{
    background-color:$white;
  }
}

/*****************************************************************
           rendre le lien fonctionnel si
           le svg se trouve dans une balise a
*****************************************************************/
//object{
//  pointer-events: none;
//}
