.bx-wrapper {
  background: none;
  padding: 0;
  margin: 0;
  border: 0;
  box-shadow: none;
  margin: 0 auto;
  img{
    display: inline-block;
  }
  &__big-controls{
    width: 100%;
    font-size: 35px;
    text-align: center;
  }
  &__big-controls-wrapper{
    @include absolute-center;
    width: 1200px;
  }
  &__big-prev,&__big-next{
    @include vertical-center();
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    color:$white;
    opacity: 0.8;
    position: absolute;
    background-color: rgba(0,0,0,0.2);
    padding: 15px;
    @include border-radius(50%);
    width: 65px;
    height: 65px;
    &:hover{
      opacity: 1;
    }
  }
  &__big-prev{
    left: 20px;
  }
  &__big-next {
    right: 20px;
  }
  &__small-controls{
    text-align: center;
    margin-top: 10px;
  }
  &__small-prev,&__small-next{
    border-radius: 50%;
    padding: 13px;
    width: 40px;
    height: 40px;
    transition: all 0.2s;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    &:hover{
      background-color: $black;
      color:$white;
    }
  }
  &__bullet-pager {
    display: inline-block;
    vertical-align: middle;
    margin:0 6px;
  }
  &__bullet-pager-item{
    width: 10px;
    height: 10px;
    @include border-radius(50%);
    display: inline-block;
    background: $white;
    margin: 0 7px;
    cursor: pointer;

    &.active,&:focus{
      width: 12px;
      height: 12px;
    }
  }
  .bx-pager {
    position: absolute;
    bottom: 10px;
    width: 100%;
  }
}
